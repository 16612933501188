import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { Api } from '../../services/api';
import { Ibge } from '../../services/Ibge';
import Header from '../../components/Header/buyHeader';
import Footer from '../../components/Footer';

import DataConfirmationSVG from '../../assets/img/data-confirmation.svg';
import { AuthContext } from '../../states/Auth';
import { useContext } from 'react';
// import { Container } from './styles';

function DataConfirmation() {
  const history = useHistory();
  // const [user, setUser] = useState({});
  const { user, setUser } = useContext(AuthContext);
  const [states, setStates] = useState([]);

  async function goToPayment() {
    const response = await await Api.put(
      user.exchanger_id + '/exchangers',
      user
    );

    history.push('/pagamento');
  }

  useEffect(() => {
    const getStates = async () => {
      const response = await Ibge.get('/localidades/estados');
      setStates(response.data);
    };
    // getUser();
    getStates();
  }, []);
  return (
    <>
      <Header />
      <section className="container">
        <div className="row my-3">
          <div className="col-12 text-center mb-2">
            <h1 className="titulos">Confirme seus dados</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6  my-3">
            <h4>
              Estamos quase lá, você só precisar conferir seus dados para
              concluirmos sua compra {':)'}
            </h4>
            <img
              className="img-fluid my-3 d-none d-md-block d-xl-block"
              src={DataConfirmationSVG}
              alt=""
            />
          </div>
          <div className="col-md-6  my-3">
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Email</label>
                  <input
                    required
                    type="email"
                    value={user.exchanger_email}
                    onChange={(e) =>
                      setUser({ ...user, exchanger_email: e.target.value })
                    }
                    className="form-control"
                    id="inputEmail"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>CPF</label>
                  <input
                    required
                    type="text"
                    value={user.exchanger_register_ext}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        exchanger_register_ext: e.target.value,
                      })
                    }
                    className="form-control"
                    id="inputCPF"
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Endereço</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="inputAddress"
                  value={user.address}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      address: e.target.value,
                    })
                  }
                  placeholder="Av. Paulista, 2224"
                />
              </div>
              <div className="form-row">
                <div className="form-group col-8">
                  <label>Complemento</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="inputComplements"
                    placeholder="Complementos"
                    value={user.complements}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        complements: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="form-group col-4">
                  <label>Numero</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="inputNumber"
                    placeholder="Digite um numero"
                    value={user.number}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        number: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Bairro</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="inputDistrict"
                  placeholder="Digite seu bairro"
                  value={user.district}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      district: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Cidade</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="inputCity"
                    value={user.city}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        city: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group col-md-3 ">
                  <label>Estado</label>
                  <select
                    required
                    id="inputState"
                    className="form-control"
                    value={user.state ?? 'DEFAULT'}
                    onChange={(e) =>
                      setUser({ ...user, state: e.target.value })
                    }
                  >
                    <option value="DEFAULT">Escolha...</option>
                    {states.map((state, index) => (
                      <option key={index} value={state.sigla}>
                        {state.nome}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-3">
                  <label>CEP</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="inputZip"
                    value={user.zipcode}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        zipcode: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <Link to={'/comprar-troquetas'} className="btn btn-secondary">
                  Rever minha compra
                </Link>
                <button
                  onClick={goToPayment}
                  type="button"
                  className="btn btn-success"
                >
                  Escolher Formas de Pagamento
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer className="fixed-bottom" />
    </>
  );
}

export default DataConfirmation;
