import React, { useContext, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import Header from '../../components/Header/buyHeader';
import Footer from '../../components/Footer';
import loadingGif from '../../assets/img/loading.gif';
import { Link, useHistory } from 'react-router-dom';
import { Api } from '../../services/paymentApi';
import { CartContext } from '../../states/Cart';
import { AuthContext } from '../../states/Auth';
import validate from '../../services/validationPayment';
import { toast, ToastContainer } from 'react-toastify';
import './styles.css';
import { useEffect } from 'react';

function Payment() {
  const history = useHistory();
  const [paymentProgress, setPaymentProgress] = useState(false);
  const { cart, closeCart } = useContext(CartContext);
  const { user } = useContext(AuthContext);

  async function paymentCredit() {
    try {
      setPaymentProgress(true);
      const paymentDetails = {
        amount: cart.value,
        type_product: 1,
        price: cart.value + '00',
        quantity_credits: cart.qtd,
        customer_id: user.exchanger_id,
        type_payment: 1,
      };
      const response = await Api.post('/payment/generate', paymentDetails);
      closeCart();
      setPaymentProgress(false);
      return (window.location.href = response.data);
    } catch (err) {
      setPaymentProgress(false);
      return toast.error(
        '😅 ops... parece que tivemos um problema com o servidor, tente novamente em alguns minutos ou contacte o suporte!',
        {
          position: 'bottom-right',
        }
      );
    }
  }

  async function paymentBillet() {
    try {
      setPaymentProgress(true);
      const paymentDetails = {
        amount: cart.value,
        type_product: 1,
        price: cart.value + '00',
        quantity_credits: cart.qtd,
        customer_id: user.exchanger_id,
        type_payment: 2,
      };
      const response = await Api.post('/payment/generate', paymentDetails);
      const transactionId = response.data.split('boleto=').pop();
      closeCart();
      setPaymentProgress(false);
      history.push(
        `/success?transactionId=${transactionId}&typePayment=boleto`
      );
    } catch (err) {
      console.log(err.response.data);
      setPaymentProgress(false);

      return toast.error(validate(err.response.data.details.errors.pop()), {
        position: 'bottom-right',
      });
    }
  }
  useEffect(() => {
    if (!cart.value) {
      history.push('/comprar-troquetas');
    }
  }, [cart.value, history]);
  return (
    <>
      <Header />
      <ToastContainer />
      <section className="container">
        <div className="row my-3">
          <div className="col-12 text-center mb-2">
            <h1 className="titulos">Pagamento</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6  my-3">
            <h4>Resumo do Pedido</h4>
            <table className="table mt-4">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Item</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>{cart.qtd} Troquetas</td>
                  <td>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(cart.value)}
                  </td>
                </tr>
              </tbody>
            </table>
            <section className="infos ">
              <h4 className="mt-4">Você poderá usar suas troquetas para:</h4>
              <ul className="list-group mt-4">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <b>Item</b>
                  <b>Troquetas</b>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  1 Match
                  <span className="badge badge-primary badge-pill">1</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  1 Histórico Veicular
                  <span className="badge badge-primary badge-pill">5</span>
                </li>
              </ul>
              <div className="actions mt-4">
                <Link to={'/confirmacao-dados'} className="btn btn-secondary">
                  Rever meus dados
                </Link>
              </div>
            </section>
          </div>
          <div id="options" className="col-md-6  my-3">
            <div className="col-12 text-center mb-5">
              <h4>Opções</h4>
              <small>
                Nossos pagamentos são processados pela <b>Pay&Go</b> e
                <b> DigitalBanks</b>. Você será redirecionado para um ambiente
                seguro para realizar sua compra!
              </small>
            </div>
            <div className="col-12 d-flex justify-content-between  flex-md-nowrap flex-lg-nowrap flex-sm-wrap flex-wrap">
              <div
                className="card text-white bg-primary m-2"
                onClick={paymentCredit}
              >
                <div className="card-header">Pay&Go</div>
                <div className="card-body">
                  <h5 className="card-title">Cartão de Crédito</h5>
                  <p className="card-text">
                    Pagamento com facilidade e rapidez! Com aprovação no mesmo
                    dia, após processado o pagamento sua troquetas serão
                    creditadas.
                  </p>
                </div>
              </div>
              <div
                className="card text-white bg-success m-2"
                onClick={paymentBillet}
              >
                <div className="card-header">DigitalBanks</div>
                <div className="card-body">
                  <h5 className="card-title">Boleto</h5>
                  <p className="card-text">
                    Pagamento via boletos podem demorar até 2 dias úteis para
                    serem processados. Após aprovado suas troquetas são
                    creditadas.
                  </p>
                </div>
              </div>
            </div>
            {paymentProgress ? (
              <div className="col-12">
                <h4 className="titulos text-rigth">
                  Estamos finalizando sua compra! Aguarde
                  <img className="w-25" src={loadingGif} />
                </h4>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </section>
      <Footer className="fixed-bottom" />
    </>
  );
}

export default Payment;
