import React, {Component} from "react";
import "./productbox.css";

class ProductBox extends Component{

    constructor(props){
        super(props);
        this.state = {
            list: {},
            product: [],
            search: [],
            optionals: [],
            optionals_desc: {},
            others_info_desc: {},
            id: '',
            basket: '',
            product_details: {},
            search_details: {},
            optionals_details: {},
            product_info: {},
            product_price: '',
            product_match_value: [],
            varAUTMTC: '',
            varARCOND: '',
            varDESTRA: '',
            varDIRECA: '',
            placa: ''
        };
    }

    componentDidMount(){
        
        let url_produto = `https://apiconcessionaria.pensetroque.com.br/dealership_product/${this.props.productId}`;
        const config = {
            method: "GET",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          }
        fetch(url_produto, config)
        .then((r)=> r.json())
        .then((json)=>{
            console.log("produtos -----", json)
            if(json.result !== null){
                this.setState({
                    list: json.result
                })
            
                json.result.edited_attributes = JSON.parse(json.result.edited_attributes).main_list_attributes
                this.setState({
                    product_details: json.result.edited_attributes
                })
            
                json.result.search_attributes = JSON.parse(json.result.search_attributes)
                this.setState({
                    search_details: json.result.search_attributes
                })
            
                if(json.result.identification_info){
                    json.result.identification_info = JSON.parse(json.result.identification_info).placa
                    this.setState({
                        product_info: json.result.identification_info
                    })
                }
            
                if(this.state.product_details.optionals_desc){
                    this.setState({
                        optionals_desc: this.state.product_details.optionals_desc
                    })
                } else {
                    this.setState({
                        optionals_desc: ['sem opcionais']
                    })
                }
                if(this.state.product_details.others_info_desc){
                    this.setState({
                        others_info_desc: this.state.product_details.others_info_desc
                    })
                }
            
            
            
            
            
                if(json.result.product_price){
                    this.setState({
                        product_price: json.result.product_price
                    })
                }
                if(json.result.product_match_value){
                    this.setState({
                        product_match_value: (json.result.product_match_value)
                    })
                }
                this.setOptionalDetails()
            
                alert('erro no preço e match')
            }else {
                this.setState({
                    product: ["---problemas---"]
                })
            }

            
        }).catch(erro=>console.log("erros ProductBox",erro))    
      }

    //   setOptionalDetails(){
    //       let itensColunas = ["ARCOND","DIRECA","DESTRA", "AUTMTC"]
         
    //       for (let atributo of itensColunas) {
    //         if(this.state.search_details[atributo]){
    //             if(atributo === "AUTMTC"){
    //                 this.findShifterAuto()
    //             }else {
    //                 let chave = 'var'+atributo;
    //                 this.setState({[chave]:this.state.product_details.optionals[atributo]})
    //             }
               
    //           }
    //       }
    //   } 

    //   findShifterAuto(){
    //       for (let i = 1; i <= 6; i++) {
    //           let cambio = this.state.product_details.optionals["AUTMT"+i]
             
    //           if( cambio !== undefined){
                
    //             this.setState({
    //                 varAUTMTC: cambio
    //             })
    //           }  
    //       }
    //   }
    
    render(){
        console.log("json.result", this.state.list)
        console.log("infraclass id", this.state.list.infraclass_id)
        console.log("optionals desc ", this.state.product_details.optionals_desc)
        console.log("search atributes", this.state.search_details)
        let list = this.state.optionals_desc;
        let optionalsList = Object.keys(list).map(i => list[i]);
        let listOther = this.state.others_info_desc;
        let otherInfoList = Object.keys(listOther).map(i => listOther[i]);
        
        return (        
            <div className="top" style={{ width: "100%" }}>
                <div className={"container-fluid head-table-2"+this.props.collorbox}>                       
                    <div className="row">                        
                        <div className="info-line-1 col-md-12">{this.state.product_details.class_desc}</div>
                    </div>
                    <div className="row">                           
                        <div className="info-line-2 col-md-12">{this.state.product_details.subclass_desc} {this.state.product_details.infraclass_desc}</div>
                    </div>

                    <div className="row border-line-3 thirdLineSettings">                           
                        <div className="info-line-3 col-md-3 border-right-line">
                            {this.state.product_details.shifter_desc}
                        </div>
                        <div className="info-line-3 col-md-3 border-right-line">
                            {this.state.product_details.manufactured_year}/{this.state.product_details.subinfraclass_desc}
                        </div>
                        {this.state.list.property === 1 ?
                            <div className="info-line-3 col-md-3 border-right-line">
                            {this.state.product_details.color_vehicle_desc}
                        </div>
                        :
                        <div className="info-line-3 col-md-3 border-right-line">
                            {this.state.product_details.color_vehicle}
                        </div>
                        }
                        
                        <div className="info-line-3 col-md-3 border-right-line">
                            {this.state.list.property === 1 ?
                                <React.Fragment>
                                    {this.state.list.identification_info}
                                </React.Fragment>
                                : 
                                <React.Fragment>
                                    {this.state.product_details.num_final_plate}
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    {this.state.list.property === 1 ?
                        <div className="row border-line-4">
                            <div className="info-line-4 col-md-12 border-right-line">preço do veículo<br/><span className="info-line-5">R$ {this.state.list.product_price}</span></div>
                        </div>
                        :
                        <div className="row border-line-4">
                            <div className="info-line-4 col-md-6 border-right-line">preço do veículo<br/><span className="info-line-5">R$ {this.state.list.product_price}</span></div>
                            {this.state.list.product_match_value !== null ?
                                <div className="info-line-4 col-md-6">valor para match<br/><span className="info-line-5">R$ {this.state.list.product_match_value}</span></div>
                                :
                                <div className="info-line-4 col-md-6">valor para match<br/><span className="info-line-5">qualquer valor</span></div>
                            }
                        </div>
                    }

                    

                    <div className="row ">
                        <div className="info-line-4 col-md-12">opcionais</div>
                    </div>
                    
                    <ul className="row border-line-7 overflowSettings">
                        {optionalsList.map((item, index)=>{
                            return(
                                <div className="info-line-7 col-md-6">
                                    {item}
                                </div>
                            )
                        })} 
                    </ul>

                    <div className="row ">
                        <div className="info-line-4 col-md-12">outras informações</div>
                    </div>
                    
                    <ul className="row border-line-7 overflowSettings">
                        {otherInfoList.map((item, index)=>{
                            return(
                                <div className="info-line-7 col-md-6">
                                    {item}
                                </div>
                            )
                        })} 
                    </ul>
                    
                    <div className="row">
                        <div className="info-line-4 col-md-12">localização</div>
                    </div>
                    
                    <div className="row border-line-9 mb-4">
                        <div className="info-line-7 col-md-6">
                            {this.state.product_details.state_desc}
                        </div>
                        <div className="info-line-7 col-md-6">
                            {this.state.product_details.city_desc}
                        </div>
                    </div>
                </div>    
            </div>
            
        );
    }
}

export default ProductBox;