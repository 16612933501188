import React, { Component } from 'react';
import ConstructionImg from '../../assets/img/pagina-em-construcao.jpg'
import Logo from '../../assets/img/logo_pense2.png';



class Construction extends Component { 
    render(){
        document.title = 'Página em construção'
        return(
            <div>
                <div className="header sticky-top shadow-lg d-md-flex justify-content-center">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <div className="mb-2 d-md-flex justify-content-center  mr-md-5">
                            <img className="imgSet mr-5 mr-md-0 p-2" src={Logo} alt="logo PenseTroque"/>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse nav" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                <a className="nav-link linkSet ml-3 mr-3" href="/">Home<span className="sr-only">(current)</span></a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/blog">Blog</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/team">Equipe</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/faq">FAQ</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/partners">Parceiros</a>
                                <a className="nav-link btnLogin btn  ml-3 mr-3 mb-3 mb-md-0" href='/login'>Login</a>
                            </div>
                        </div>
                    </nav>
                </div>
                <div>
                    <img src={ConstructionImg} width="100%" alt="Página em Construção"/>
                </div>
            </div>
        )
    }
}

export default Construction;