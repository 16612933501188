import React, { Component } from 'react';
import './menu.css';
import Logo from '../../assets/img/logo_pense.png';
import ModalApp from '../ModalApp'

class Menu extends Component {
    constructor() {
        super();
        this.state = {
            list: [],
        }
    }

    componentDidMount(){
        let exchangerId = localStorage.getItem('exchangerId');
        let url = `https://api.pensetroque.com.br/${exchangerId}/exchangers`
        const config = {
            method: "GET",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
        }
        fetch(url, config)
        .then((r)=> r.json())
        .then((json)=>{
            if(json !== null){
                
                this.setState({
                    list: json
                })
            } else {
                console.log("deu erro no consumo da api")
            }
        }) 
    }

    signOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('user');
        localStorage.removeItem('productId')
        window.location.href = "/";
    }
    render(){
        let name = this.state.list.exchanger_name
        return(
            <div className="bgYellowMenu menuSettings overflow-hidden">
                <div className="logoDisplay">
                    <img className="logoSettings" src={Logo} alt="Logo PenseTroque Menu"/>
                </div>
                <div className="row">
                    <div className="col-md-6 d-flex justify-content-end">
                        <p className="exchangerName mt-4">{name}</p>
                        <p className="editProfile mt-1" onClick={this.signOut}>Sair</p>
                    </div>
                    <div className="col-md-6 d-flex justify-content-center menuDisplay">
                        <div className="coins mt-3 " data-toggle="modal" data-target="#exampleModal">
                            <p className="coinsSettings d-flex justify-content-center">
                            {this.state.list.exchanger_credits === null ?
                                <React.Fragment>
                                    0
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {this.state.list.exchanger_credits}
                                </React.Fragment>
                            }
                                
                            </p>
                        </div>
                        <div className="publish mt-3 d-flex justify-content-center" data-toggle="modal" data-target="#exampleModal">
                            <i className="fas fa-upload publishSettings"></i>
                        </div>
                        <div className="filters mt-3 d-flex justify-content-center" data-toggle="modal" data-target="#exampleModal">
                            <i className="fas fa-filter filtersSettings"></i>
                        </div>
                        <div className="menu mt-3 d-flex justify-content-center" data-toggle="modal" data-target="#exampleModal">
                            <i className="fas fa-ellipsis-v menuIconSettings"></i>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-center">
                        <div className="mt-3 d-flex justify-content-center " data-toggle="modal" data-target="#exampleModal">
                            <p className="seeCoins d-flex text-center">
                               Troquetas 
                            </p>
                        </div>
                        <div className=" mt-3 d-flex justify-content-center" data-toggle="modal" data-target="#exampleModal">
                            <p className="accessPublish d-flex text-center">
                               Publicar 
                            </p>
                        </div>
                        <div className=" mt-3 d-flex justify-content-center" data-toggle="modal" data-target="#exampleModal">
                            <p className="seeFilters d-flex text-center">
                               Filtros 
                            </p>
                        </div>
                        <div className=" mt-3 d-flex justify-content-center" data-toggle="modal" data-target="#exampleModal">
                            <p className="seeMenu d-flex text-center">
                               Menu 
                            </p>
                        </div>
                    </div>
                    <ModalApp/>
                </div>
                <hr className="menuBreak"/>
            </div>
        );
    }
}

export default Menu;
