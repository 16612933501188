import React, { Component } from 'react';
import Logo from '../../assets/img/logo_pense2.png';
import './partners.css';
import { Link } from 'react-router-dom';
import Dekra from '../../assets/img/Dekra.jpg';
import Yuhuu from '../../assets/img/Yuhuu.png';
import Itavema from '../../assets/img/itavema.png';
import VilaVelha from '../../assets/img/VilaVelha.png';

class Partners extends Component { 
    
    render(){
        document.title = 'Parceiros PenseTroque'
        return(
            <div>
                <div className="header sticky-top shadow-lg d-md-flex justify-content-center">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <div className="mb-2 d-md-flex justify-content-center  mr-md-5">
                            <img className="imgSet mr-5 mr-md-0 p-2" src={Logo} alt="logo PenseTroque"/>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse nav" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                <a className="nav-link linkSet ml-3 mr-3" href="/">Home<span className="sr-only">(current)</span></a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/blog">Blog</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/team">Equipe</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/faq">FAQ</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/partners">Parceiros</a>
                                <a className="nav-link btnLogin btn  ml-3 mr-3 mb-3 mb-md-0" href='/login'>Login</a>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="partners">
                    <div className="d-flex justify-content-center mt-5 mb-5">
                        <h1 className="partnerTitle">Nossos Parceiros</h1>
                    </div>
                    <div className="container">
                        <div className="row shadow p-3">
                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                <img src={Dekra} width="100%" alt="Dekra"/>
                            </div>
                            <div className="col-md-9 d-flex align-items-center partnerDesc">
                                Faça a troca dos veículos com segurança em uma loja DEKRA. Além disso, você pode consultar o histórico do seu veículo ou do veículo pretendido.
                            </div>
                        </div>
                    </div>
                    <div className="container mt-4">
                        <div className="row shadow p-3">
                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                <img src={VilaVelha} width="100%" alt="Vila Velha"/>
                            </div>
                            <div className="col-md-9 d-flex align-items-center partnerDesc">
                            Com a Vila Velha Seguros, você realiza sua troca e sai com seu veículo segurado, escolhendo a melhor proposta junto às principais seguradoras.
                            </div>
                        </div>
                    </div>
                    <div className="container mt-4">
                        <div className="row shadow p-3">
                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                <img src={Yuhuu} width="100%" alt="Yuhuu"/>
                            </div>
                            <div className="col-md-9 d-flex align-items-center partnerDesc">
                            Yuhuu! é uma forma de parcelar compras e viabilizar transações entre consumidores pessoa física que possuem a necessidade de adquirir bens ou serviços de forma facilitada.
                            </div>
                        </div>
                    </div>
                    <div className="container mt-4 mb-5">
                        <div className="row shadow p-3">
                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                <img src={Itavema} width="100%" alt="Itavema"/>
                            </div>
                            <div className="col-md-9 d-flex align-items-center partnerDesc">
                            Grupo pioneiro que aderiu ao projeto publicando o portfolio de diversas concessionárias na plataforma PenseTroque. 
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <div className="col-12 footerBg">
                        <div className="container">
                            <div className="row">
                                <div className="col-4 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Sobre Nós</p>
                                    <p className="footerText">Pensou, deu match, trocou!</p>
                                    <Link to='/contact' className="redirectButton d-flex justify-content-center align-items-center"><p className="m-0">Entre em contato</p></Link>
                                </div>
                                <div className="col-2"></div>
                                <div className="col-3 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Navegação</p>
                                    <a href="/" className="footerText">Home</a>
                                    <a href='/blog' className="footerText">Blog</a>
                                    <a href='/team' className="footerText">Equipe</a>
                                    <a href='/faq' className="footerText">FAQ</a>
                                    <a href='/partners' className="footerText">Parceiros</a>
                                    <a href='/login' className="footerText">Login</a>
                                </div>
                                <div className="col-3 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Links úteis</p>
                                    <a href='/termos-de-uso' className="footerText">Termos de Uso</a>
                                    <a href='/privacidade' className="footerText">Privacidade</a>
                                    <a href='/contact' className="footerText">Contato</a>
                                </div>
                            </div>
                            <hr className="lineBorder d-none d-md-flex"/>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center mt-5 mt-md-0 mb-3">
                                    <div>
                                        <a href="https://www.facebook.com/pensetroqueveiculos"><i className="fab fa-facebook socialIcon mr-4"></i></a>
                                        <a href="https://www.instagram.com/pensetroque/"><i className="fab fa-instagram socialIcon mr-4"></i></a>
                                        <a href="https://www.linkedin.com/company/pensetroque-veiculos/"><i className="fab fa-linkedin socialIcon mr-4"></i></a>
                                        <a href="https://www.youtube.com/channel/UClCxpZNW7SzAOBwAmTTUnKg"><i className="fab fa-youtube socialIcon"></i></a>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center">
                                    <p className="copyText">&copy; 2020 - PenseTroque</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Partners;