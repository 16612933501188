import React, { Component } from 'react';
import Header from '../../components/Header'
import './register.css';
import TextField from '@material-ui/core/TextField';
import Info from '../../components/Info';
import { Link } from 'react-router-dom';
import firebase from 'firebase';
import QRCode from '../../assets/img/googleplay.png'

class Register extends Component {
  
    constructor(props) {
        super(props)
        this.onSignUpHandler = this.onSignUpHandler.bind(this)
        this.state = {
            message : this.props.location.state?this.props.location.state.message: '',
            name: '',
            username: '',
            email: '',
            phone: '',
            password: '',
            password2: '',
            error: false,
            errorMessage: '',
            emailVerification: false
        };
    }

    componentDidMount(){
      window.scrollTo(0, 0)
    }
  
    myChangeHandlerName = (event) => {
      this.setState({
        name: event.target.value
      })
    }

    myChangeHandlerEmail = (event) => {
      this.setState({
        email: event.target.value 
      })
    }

    myChangeHandlerPhone = (event) => {
      this.setState({
        phone: event.target.value
      })
    }

    myChangeHandlerUsername = (event) => {
      this.setState({
        username: event.target.value
      })
    }

    myChangeHandlerPassword = (event) => {
      this.setState({
        password: event.target.value
      })
    }

    myChangeHandlerPassword2 = (event) => {
      this.setState({
        password2: event.target.value
      })
    }

    onSignUpHandler = async () => {
        if (this.state.password === this.state.password2) {
          try {
            let currentUser = await firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password);
            await firebase.auth().currentUser.sendEmailVerification();
            alert ('Cadastro realizado com sucesso. Agora, confirme seu e-mail e efetue seu login.');
            window.location.reload();
          } catch (error) {
            this.setState({
              error: true,
              errorMessage: "Erro ao cadastrar. Tente novamente"
            });
          }
        } else {
          this.setState({
            error: true,
            errorMessage: "Senhas não são iguais"
          });
        }
        
    };


    render(){
      document.title = 'PenseTroque'
        return(
            <div className="overflow-hidden">
                <Header/>
                    {
                        this.state.message !== ''? (
                            <div className="alert alert-danger col-12 alertSettings mb-0 text-center" role="alert">
                                {this.state.message}
                            </div> 
                        ) : ''
                    }
                <div className="row ">
                    <div className="col-md-6 blueBgRegister bgDivSettings p-5 ">
                        <div className="row">
                            <div className="col-8 mt-5 ml-5">
                                <p className="questionRegister m-0">Ainda não é cadastrado no PenseTroque?</p>
                                <p className="guideRegister m-0">Basta preencher os dados abaixo e boas trocas! </p>
                                <div className="registerInputs">

                                    <TextField
                                    id="standard-basic" 
                                    type="text" 
                                    className="nameInput mt-3" 
                                    name="name"
                                    label="NOME" 
                                    onChange={this.myChangeHandlerName}
                                    />

                                    <TextField 
                                    id="standard-basic" 
                                    type="email" 
                                    className="mailInput mt-3" 
                                    label="E-MAIL"
                                    name="email"
                                    onChange={this.myChangeHandlerEmail} 
                                    />

                                    <TextField 
                                    id="standard-basic" 
                                    type="text" 
                                    className="whatsInput mt-3" 
                                    label="WHATSAPP"
                                    name="phone" 
                                    onChange={this.myChangeHandlerPhone}
                                    />

                                    <TextField 
                                    id="standard-basic" 
                                    type="text" 
                                    className="usernameInput mt-3" 
                                    label="USUÁRIO"
                                    name="user"
                                    onChange = {this.myChangeHandlerUsername}
                                    />

                                    <TextField 
                                    id="standard-basic" 
                                    type="password" 
                                    className="userPassInput mt-2" 
                                    label="SENHA"
                                    name="password"
                                    onChange = {this.myChangeHandlerPassword}
                                    />

                                    <TextField 
                                    id="standard-basic" 
                                    type="password" 
                                    className="userPassInput mt-2" 
                                    label="CONFIRME SUA SENHA"
                                    name="password2"
                                    onChange={this.myChangeHandlerPassword2} 
                                    />

                                </div>
                              <div onClick={this.onSignUpHandler}>
                              <button className="submitRegister mt-5" >cadastrar</button>
                              </div>
                              <p className="questionRegister m-0 mt-5">Baixe nosso App! </p>
                              <p className="guideRegister m-0">Para fazer seu cadastro completo e o cadastro do seu veículo, use o QR Code abaixo e baixe o nosso aplicativo. </p>
                              <div className="row">
                                  <div className="col-md-12 text-center mt-5">
                                      <img src={QRCode} width="100%" alt="QR Code para acessar loja"/>
                                  </div>
                              </div> 
                              <div className="row">
                                  <div className="col-md-12 text-center mt-3">
                                  <p className="guideRegister m-0 mt-5 mb-3">Ou instale o app clicando no link abaixo.</p>
                                  <i className="material-icons">
                                    <a href="https://play.google.com/store/apps/details?id=com.pensetroqueapp" className="materialIconColor">
                                      android
                                    </a>
                                  </i>
                                  </div>
                              </div> 
                              
                            </div>
                        </div>
                    </div>
                    <Info/>
                </div>
            </div>
        );
    }
}

export default Register;