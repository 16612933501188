import React, { Component } from 'react';
import Logo from '../../assets/img/logo_pense2.png';
import './blog.css';
import { Link } from 'react-router-dom';
import ImageBlog from '../../assets/img/blog_post1.jpg';
import StartupOle from '../../assets/img/startup_ole.png';
import AmchamArena from '../../assets/img/amcham_arena.png';
import Autoesporte from '../../assets/img/autoesporte.jpg';
import VendeTroca from '../../assets/img/vendetroca3.png';

class Blog extends Component { 
    render(){
        document.title = 'Blog PenseTroque'
        return(
            <div>
                
                <div className="header sticky-top shadow-lg d-md-flex justify-content-center">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <div className="mb-2 d-md-flex justify-content-center  mr-md-5">
                            <img className="imgSet mr-5 mr-md-0 p-2" src={Logo} alt="logo PenseTroque"/>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse nav" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                <a className="nav-link linkSet ml-3 mr-3" href="/">Home<span className="sr-only">(current)</span></a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/blog">Blog</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/team">Equipe</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/faq">FAQ</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/partners">Parceiros</a>
                                <a className="nav-link btnLogin btn  ml-3 mr-3 mb-3 mb-md-0" href='/login'>Login</a>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="container mt-5 mb-5">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center  mt-5 mb-5">
                            <h1 className="blogTitle">Blog</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <a href="/blogpost"><img src={ImageBlog} className="card-img-top" alt="..."/></a> 
                                <div className="card-body">
                                    <h5 className="card-title cardTitleSet">Na pandemia, 'Troca' volta com tudo no comércio de carros!</h5>
                                    <p className="card-text cardtextSet">Plataforma de venda verificou aumento na procura por troca de veículos, uma boa estratégia para quem quer fazer um dinheiro extra para reforçar as finanças com o valor da diferença entre os preços dos veículos.</p>
                                    <a href="/blogpost" className="btnRead p-2">Ler matéria</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <a href="https://www.linkedin.com/feed/update/urn:li:activity:6785991911309144064" target="_blank"><img src={StartupOle} className="card-img-top" alt="..."/></a> 
                                <div className="card-body">
                                    <h5 className="card-title cardTitleSet">PenseTroque no Startup Olé!</h5>
                                    <p className="card-text cardtextSet">A PenseTroque está concorrendo na competição Startup Olé, curta e compartilhe!</p>
                                    <a href="https://www.linkedin.com/feed/update/urn:li:activity:6785991911309144064" className="btnRead p-2" target="_blank">Veja a publicação</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <a href="https://www.linkedin.com/feed/update/urn:li:activity:6711989227548229633" target="_blank"><img src={AmchamArena} className="card-img-top" alt="..."/></a>
                                <div className="card-body">
                                    <h5 className="card-title cardTitleSet">PenseTroque na Amcham Arena!</h5>
                                    <p className="card-text cardtextSet">A PenseTroque está na rodada de Networking da Amcham Arena, curta e compartilhe!</p>
                                    <a href="https://www.linkedin.com/feed/update/urn:li:activity:6711989227548229633" className="btnRead p-2" target="_blank">Veja a publicação</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <a href="https://globoplay.globo.com/v/9445689/?s=0s" target="_blank"><img src={Autoesporte} className="card-img-top" alt="..."/></a>
                                <div className="card-body">
                                    <h5 className="card-title cardTitleSet">Seu carro usado pode valer muito!</h5>
                                    <p className="card-text cardtextSet">Matéria do Auto Esporte, mostra o quão valioso pode ser seu carro usado.</p>
                                    <a href="https://globoplay.globo.com/v/9445689/?s=0s" className="btnRead p-2" target="_blank">Veja a matéria</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <a href="https://www.linkedin.com/pulse/o-trocar-ao-inv%C3%A9s-do-comprar-um-caminho-para-economia-ricardo/" target="_blank"><img src={VendeTroca} className="card-img-top" alt="..."/></a>
                                <div className="card-body">
                                    <h5 className="card-title cardTitleSet">O “Trocar” ao invés do “Comprar”, um caminho para Economia colaborativa.</h5>
                                    <p className="card-text cardtextSet">Publicação do CEO da PenseTroque, Ricardo Marques Simões Ferreira, mostra o novo processo da "Troca Inteligente".</p>
                                    <a href="https://www.linkedin.com/pulse/o-trocar-ao-inv%C3%A9s-do-comprar-um-caminho-para-economia-ricardo/" className="btnRead p-2" target="_blank">Veja a publicação</a>
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-md-4 mt-3">
                            <div className="card" >
                                <a href="#"><img src={ImageBlog} className="card-img-top" alt="..."/></a>
                                <div className="card-body">
                                    <h5 className="card-title cardTitleSet">Na pandemia, 'Troca' volta com tudo no comércio de carros!</h5>
                                    <p className="card-text cardtextSet">Plataforma de venda verificou aumento na procura por troca de veículos, uma boa estratégia para quem quer fazer um dinheiro extra para reforçar as finanças com o valor da diferença entre os preços dos veículos.</p>
                                    <a href="#" className="btnRead p-2">Ler matéria</a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <footer>
                    <div className="col-12 footerBg">
                        <div className="container">
                            <div className="row">
                                <div className="col-4 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Sobre Nós</p>
                                    <p className="footerText">Pensou, deu match, trocou!</p>
                                    <Link to='/contact' className="redirectButton d-flex justify-content-center align-items-center"><p className="m-0">Entre em contato</p></Link>
                                </div>
                                <div className="col-2"></div>
                                <div className="col-3 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Navegação</p>
                                    <a href="/" className="footerText">Home</a>
                                    <a href='/blog' className="footerText">Blog</a>
                                    <a href='/team' className="footerText">Equipe</a>
                                    <a href='/faq' className="footerText">FAQ</a>
                                    <a href='/partners' className="footerText">Parceiros</a>
                                    <a href='/login' className="footerText">Login</a>
                                </div>
                                <div className="col-3 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Links úteis</p>
                                    <a href='/termos-de-uso' className="footerText">Termos de Uso</a>
                                    <a href='/privacidade' className="footerText">Privacidade</a>
                                    <a href='/contact' className="footerText">Contato</a>
                                </div>
                            </div>
                            <hr className="lineBorder d-none d-md-flex"/>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center mt-5 mt-md-0 mb-3">
                                    <div>
                                        <a href="https://www.facebook.com/pensetroqueveiculos"><i className="fab fa-facebook socialIcon mr-4"></i></a>
                                        <a href="https://www.instagram.com/pensetroque/"><i className="fab fa-instagram socialIcon mr-4"></i></a>
                                        <a href="https://www.linkedin.com/company/pensetroque-veiculos/"><i className="fab fa-linkedin socialIcon mr-4"></i></a>
                                        <a href="https://www.youtube.com/channel/UClCxpZNW7SzAOBwAmTTUnKg"><i className="fab fa-youtube socialIcon"></i></a>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center">
                                    <p className="copyText">&copy; 2020 - PenseTroque</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Blog;