import Axios from 'axios';

export const Api = Axios.create({
  baseURL: 'https://api.pensetroque.com.br',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const BASE_URL = 'https://api.pensetroque.com.br';
