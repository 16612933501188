import React, { Component } from 'react';
import './vehiclecard.css';
import Carousel from '../Carousel';
import { Link } from 'react-router-dom';

class VehicleCard extends Component {
    
    constructor(props) {
        super(props);
            this.state = {
            id: '',
            findKey: '',
            list: [],
            exchanger: {}
            
        }
    }

    componentDidMount(){
        this.getDataExchange()      
    }

    getDataExchange(){
        let basketId = localStorage.getItem("basketId")
        let url = `https://api.pensetroque.com.br/${basketId}/negotiations`;
        const config = {
            method: "GET",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
        }
        fetch(url, config)
        .then((r)=> r.json())
        .then((json)=>{
            this.setState({
                list: json
            })
            console.log("aqui a lista do vehicle card ----", this.state.list)
        }).catch(erro=>console.log("erros ProductBox",erro))
    }

    render(){
        let productId = localStorage.getItem('productId')
        return(
                <div className="container">
                    <div className="row">
                    {this.state.list.length === 0 ?
                        <React.Fragment>
                        <div className="row">
                            <div className="col-md-12 bgColorNone">
                                <h1 className="ml-2 mt-5 fontNone">
                                    Você não tem nenhuma oferta. 
                                </h1>
                                <p className="infoNone ml-2">
                                Instale nosso App no seu celular para visualizar outros carros e iniciar uma negociação! <br/>
                                Disponível na App Store e na Google Play!
                                </p>
                            </div>
                        </div>  
                        </React.Fragment>
                        :
                        <React.Fragment>
                        {this.state.list.map((item, index)=>{
                            return(
                                
                                <div className="col-md-4" key={index}>
                                    <div className="card mb-5 mt-5 ml-3 cardSettings shadow p-0 mb-5 bg-white rounded">
                                        <div className="card-body p-0">
                                            <Carousel productId={item.product_id} carouselSize="carouselSmall"/>
                                            <div className="firstLine d-flex mt-2 mb-2">
                                                <div className="col-6">
                                                    <p className="carBrand">{item.class_desc}</p>
                                                    <p className="carModel">{item.subclass_desc}</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="carStatus">Status: <span className="carStatusSpan">{item.negotiation_status}</span></p>
                                                </div>
                                            </div>
                                            <div className="secondLine d-flex border-top border-bottom mt-2 mb-2">
                                                <div className="col-6 border-right">
                                                    <p className="fipeTitle">Valor</p>
                                                    <p className="fipeValue">R${item.product_price}</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="investTitle">Match</p>
                                                    <p className="investValue">R$ {item.product_match_value}</p>
                                                </div>
                                            </div>
                                            <div className="thirdLine d-flex mt-2 mb-2 border-bottom">
                                                <p className="salesman">Trocador<span className="salesmanName">{item.exchanger_name}</span></p>
                                            </div>
                                            <div className="thirdLine d-flex mt-2 mb-2">
                                                <p className="salesman">expira em<span className="salesmanName">{item.date_limit.substring(8, 10)}/{item.date_limit.substring(5,7)}/{item.date_limit.substring(0, 4)}</span></p>
                                            </div>
                                            {item.negotiation_status === 'new' ?
                                            <React.Fragment>
                                                {item.from === 'autor' ?
                                                    <Link to={`/matchdetails/${item.product_id}/${productId}/${item.exchanger_id}/${item.last_negotiation_event_id}/${item.exchanger_basket_id}`} className="linkDetails mt-2 mb-2">
                                                    <div className="detailsButton d-flex align-items-center justify-content-center">
                                                        <p className="seeDetailsButton align-middle">nova solicitação</p>
                                                    </div>
                                                    </Link>
                                                :
                                                    <Link to={`/waitingdetails/${item.product_id}/${productId}`} className="linkDetails mt-2 mb-2">
                                                    <div className="detailsButton d-flex align-items-center justify-content-center">
                                                        <p className="seeDetailsButton align-middle">aguardando resposta</p>
                                                    </div>
                                                    </Link>
                                                }
                                            </React.Fragment>
                                                
                                                :
                                                <Link to={`/contactdetails/${item.product_id}/${productId}/${item.exchanger_id}`} className="linkDetails mt-2 mb-2">
                                                <div className="detailsButton d-flex align-items-center justify-content-center">
                                                    <p className="seeDetailsButton align-middle">em negociação</p>
                                                </div>
                                                </Link>
                                            }
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                                
                            );
                        })}
                        </React.Fragment> 
                    }
                        
                    </div>
                </div>
        );
    }
}

export default VehicleCard;