import React from 'react';
import Routes from './routes';

import CartContext from './states/Cart';
import AuthContext from './states/Auth';

function App() {
  return (
    <div className="App">
      <AuthContext>
        <CartContext>
          <Routes />
        </CartContext>
      </AuthContext>
    </div>
  );
}

export default App;
