import React, { useEffect, useState, useContext } from 'react';

import Header from '../../components/Header/buyHeader';
import Footer from '../../components/Footer';
import LoadingOrder from '../../components/LoadingOrder';
import OrderListImg from '../../assets/img/order_list.png';
import { ToastContainer, toast } from 'react-toastify';
import { Api } from '../../services/paymentApi';
import { AuthContext } from '../../states/Auth';
import './style.css';

function OrderList() {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { user, setUser } = useContext(AuthContext);

  useEffect(() => {
    const getOrderList = async () => {
      try {
        const response = await Api.get(
          `/order/list/exchange/${user.exchanger_id}`
        );
        setOrders(response.data);
        setTimeout(() => setIsLoading(false), 2000);
      } catch (e) {
        console.error(e);
        setError(true);
        setIsLoading(false);
        toast.error(
          'Ops 😥 Houve um erro ao tentar carregar suas compras, recarregue a página ou nos mande uma mensagem!',
          { position: 'bottom-right', autoClose: 5000 }
        );
      }
    };
    getOrderList();
  }, [isLoading]);
  return (
    <>
      <Header />
      <ToastContainer />
      <section className="container">
        <div className="row">
          <div className="col-12 my-5 text-center">
            <h1>Minhas Compras</h1>
          </div>
          <div className="col-md-6 d-none d-md-block d-xl-block">
            <img className="img-fluid" src={OrderListImg} alt="Order List" />
          </div>
          <div id="order-list" className="col-md-6 overflow-auto">
            {isLoading ? (
              <>
                <LoadingOrder />
                <LoadingOrder />
                <LoadingOrder />
              </>
            ) : orders.length > 0 ? (
              orders.map((order, index) => {
                return (
                  <div className="card mb-3" key={index}>
                    <div className="card-header d-flex justify-content-between">
                      <div>Realizada em: {order.createdAt}</div>
                      <div>Status: {order.payment_status_name}</div>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">{`${order.quantity} ${order.purchase_product_name}`}</h5>
                      <p className="card-text">
                        Compra realizada com: {order.payment_method_name}
                      </p>
                    </div>
                    <div className="card-footer d-flex justify-content-between align-items-center">
                      {order.payment_method_name === 'Boleto' &&
                      order.payment_status_name == 'TRANSACAO INICIADA' ? (
                        <a
                          className="btn btn-primary"
                          href={`https://api.digitalbankstecnologia.com.br/api/public/print/boleto?boleto=${order.transaction_id}`}
                        >
                          Link do Boleto
                        </a>
                      ) : (
                        ''
                      )}
                      <span>
                        {' '}
                        Total:
                        {' ' +
                          new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(
                            String(order.amount_paid).slice(
                              0,
                              String(order.amount_paid).length - 2
                            )
                          )}
                      </span>
                    </div>
                  </div>
                );
              })
            ) : !error ? (
              <h2>Você ainda não fez compras!</h2>
            ) : (
              <div className="d-flex justify-content-center">
                <a href="/minhas-compras" className="btn btn-primary">
                  Recarregar
                </a>
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default OrderList;
