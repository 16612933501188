import React, { Component } from 'react';
import Logo from '../../assets/img/logo_pense2.png';
import './team.css';
import { Link } from 'react-router-dom';

class Team extends Component { 
    render(){
        document.title = 'Equipe PenseTroque'
        return(
            <div>
                
                <div className="header sticky-top shadow-lg d-md-flex justify-content-center">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <div className="mb-2 d-md-flex justify-content-center  mr-md-5">
                            <img className="imgSet mr-5 mr-md-0 p-2" src={Logo} alt="logo PenseTroque"/>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse nav" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                <a className="nav-link linkSet ml-3 mr-3" href="/">Home<span className="sr-only">(current)</span></a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/blog">Blog</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/team">Equipe</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/faq">FAQ</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/partners">Parceiros</a>
                                <a className="nav-link btnLogin btn  ml-3 mr-3 mb-3 mb-md-0" href='/login'>Login</a>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="team">
                    <div className="d-flex justify-content-center mt-5 mb-5">
                        <h1 className="teamTitle">Nossa Equipe</h1>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-2 ml-5 p-3">
                                                <i className="fas fa-user-tie ceoIcon"></i>
                                            </div>
                                            <div className="col-md-8 d-flex flex-column p-3">
                                                <p className="m-0 ceoName">Ricardo Simões</p>
                                                <p className="mt-0 ceoSubtitle">CEO Idealizador e Fundador</p>
                                                <p className="ceoDesc">40 anos de experiência em tecnologia da informação e negócios co-relacionados</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card mb-4 teamCard">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-2 ml-2 p-3">
                                                <i className="fas fa-user-friends teamIcon"></i>
                                            </div>
                                            <div className="col-md-8 d-flex flex-column p-3">
                                                <p className="m-0 teamMateTitle">Co-Fundador</p>
                                                <p className="mt-0 teamDesc">40 anos de experiencia no mercado automotivo, provendo soluções de tecnologia para a cadeia automotiva de auto-peças</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card mb-4 teamCard">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-2 ml-2 p-3">
                                            <i className="fas fa-handshake teamIcon"></i>
                                            </div>
                                            <div className="col-md-8 d-flex flex-column p-3">
                                                <p className="m-0 teamMateTitle">Gestor de Operações</p>
                                                <p className="mt-0 teamDesc">20 anos de experiência, faz parte da equipe desde o início do projeto</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card mb-4 teamCard">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-2 ml-2 p-3">
                                                <i className="fas fa-briefcase teamIcon"></i>
                                            </div>
                                            <div className="col-md-8 d-flex flex-column p-3">
                                                <p className="m-0 teamMateTitle">Gestor Comercial</p>
                                                <p className="mt-0 teamDesc">20 anos de experiência na área comercial, sendo 8 anos no setor de franquias</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-2 ml-2 p-3">
                                                <i className="fas fa-comment-dollar teamIcon"></i>
                                            </div>
                                            <div className="col-md-8 d-flex flex-column p-3">
                                                <p className="m-0 teamMateTitle">Gestor Financeiro</p>
                                                <p className="mt-0 teamDesc">10 anos de experiência na área financeira e de administração de empresas</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card mb-4">
                                    <div className="card-body mb-4">
                                        <div className="row">
                                            <div className="col-md-2 ml-2 p-3">
                                                <i className="fas fa-bullseye teamIcon"></i>
                                            </div>
                                            <div className="col-md-8 d-flex flex-column p-3">
                                                <p className="m-0 teamMateTitle">Marketing</p>
                                                <p className="mt-0 teamDesc">2 Profissionais - 2 e 3 anos de experiência em marketing digital e inteligência competitiva</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-2 ml-2 p-3">
                                                <i className="fas fa-code teamIcon"></i>
                                            </div>
                                            <div className="col-md-8 d-flex flex-column p-3">
                                                <p className="m-0 teamMateTitle">2 Programadores</p>
                                                <p className="mt-0 teamDesc">1 e 2 anos de experiência em desenvolvimento de software, estão no projeto desde o seu início</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <div className="col-12 footerBg">
                        <div className="container">
                            <div className="row">
                                <div className="col-4 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Sobre Nós</p>
                                    <p className="footerText">Pensou, deu match, trocou!</p>
                                    <Link to='/contact' className="redirectButton d-flex justify-content-center align-items-center"><p className="m-0">Entre em contato</p></Link>
                                </div>
                                <div className="col-2"></div>
                                <div className="col-3 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Navegação</p>
                                    <a href="/" className="footerText">Home</a>
                                    <a href='/blog' className="footerText">Blog</a>
                                    <a href='/team' className="footerText">Equipe</a>
                                    <a href='/faq' className="footerText">FAQ</a>
                                    <a href='/partners' className="footerText">Parceiros</a>
                                    <a href='/login' className="footerText">Login</a>
                                </div>
                                <div className="col-3 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Links úteis</p>
                                    <a href='/termos-de-uso' className="footerText">Termos de Uso</a>
                                    <a href='/privacidade' className="footerText">Privacidade</a>
                                    <a href='/contact' className="footerText">Contato</a>
                                </div>
                            </div>
                            <hr className="lineBorder d-none d-md-flex"/>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center mt-5 mt-md-0 mb-3">
                                    <div>
                                        <a href="https://www.facebook.com/pensetroqueveiculos"><i className="fab fa-facebook socialIcon mr-4"></i></a>
                                        <a href="https://www.instagram.com/pensetroque/"><i className="fab fa-instagram socialIcon mr-4"></i></a>
                                        <a href="https://www.linkedin.com/company/pensetroque-veiculos/"><i className="fab fa-linkedin socialIcon mr-4"></i></a>
                                        <a href="https://www.youtube.com/channel/UClCxpZNW7SzAOBwAmTTUnKg"><i className="fab fa-youtube socialIcon"></i></a>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center">
                                    <p className="copyText">&copy; 2020 - PenseTroque</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Team;