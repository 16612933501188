import React, { Component } from 'react';
import Logo from '../../assets/img/logo_pense2.png';
import './contact.css';
import { Link } from 'react-router-dom';



class Contact extends Component { 
    render(){
        return(
            <div>
                <div className="header sticky-top shadow-lg d-md-flex justify-content-center">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <div className="mb-2 d-md-flex justify-content-center  mr-md-5">
                            <img className="imgSet mr-5 mr-md-0 p-2" src={Logo} alt="logo PenseTroque"/>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse nav" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                <a className="nav-link linkSet ml-3 mr-3" href="/">Home<span className="sr-only">(current)</span></a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/blog">Blog</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/faq">FAQ</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/partners">Parceiros</a>
                                <a className="nav-link btnLogin btn  ml-3 mr-3 mb-3 mb-md-0" href='/login'>Login</a>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="container mt-5 mb-5">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center contactPageTitle">
                            Entre em Contato
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 d-flex flex-column aligm-items-center mt-5 mb-5">
                            <div className="card shadow">
                                <div className="card-body">
                                    <h5 className="card-title">Email comercial</h5>
                                    <h6 className="card-subtitle mb-2 text-muted">Para parcerias e interesses comerciais.</h6>
                                    <a href="mailto:contato@pensetroque.com.br" className="card-link">Enviar email para o comercial.</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 d-flex flex-column aligm-items-center mt-5 mb-5">
                        <div className="card shadow">
                                <div className="card-body">
                                    <h5 className="card-title">Email de suporte</h5>
                                    <h6 className="card-subtitle mb-2 text-muted">Para suporte técnico e problemas com o site.</h6>
                                    <a href="mailto:suporte@pensetroque.com.br" className="card-link">Enviar email para o suporte.</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <div className="col-12 footerBg">
                        <div className="container">
                            <div className="row">
                                <div className="col-4 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Sobre Nós</p>
                                    <p className="footerText">Pensou, deu match, trocou!</p>
                                    <Link to='/contact' className="redirectButton d-flex justify-content-center align-items-center"><p className="m-0">Entre em contato</p></Link>
                                </div>
                                <div className="col-2"></div>
                                <div className="col-3 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Navegação</p>
                                    <a href="/" className="footerText">Home</a>
                                    <a href='/blog' className="footerText">Blog</a>
                                    <a href='/faq' className="footerText">FAQ</a>
                                    <a href='/partners' className="footerText">Parceiros</a>
                                    <a href='/login' className="footerText">Login</a>
                                </div>
                                <div className="col-3 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Links úteis</p>
                                    <a href='/termos-de-uso' className="footerText">Termos de Uso</a>
                                    <a href='/privacidade' className="footerText">Privacidade</a>
                                    <a href='/contact' className="footerText">Contato</a>
                                </div>
                            </div>
                            <hr className="lineBorder d-none d-md-flex"/>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center mt-5 mt-md-0 mb-3">
                                    <div>
                                        <a href="https://www.facebook.com/pensetroqueveiculos"><i className="fab fa-facebook socialIcon mr-3"></i></a>
                                        <a href="https://www.instagram.com/pensetroque/"><i className="fab fa-instagram socialIcon mr-3"></i></a>
                                        <a href="https://www.linkedin.com/company/pensetroque-veiculos/"><i className="fab fa-linkedin socialIcon mr-3"></i></a>
                                        <a href="https://www.youtube.com/channel/UClCxpZNW7SzAOBwAmTTUnKg"><i className="fab fa-youtube socialIcon"></i></a>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center">
                                    <p className="copyText">&copy; 2020 - PenseTroque</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Contact;