import React from 'react';
import { useEffect, useState } from 'react';

// import { Container } from './styles';

function Footer() {
  const [height, setHeight] = useState('');
  useEffect(() => {
    if (document.body.scrollHeight <= window.innerHeight) {
      setHeight('fixed-bottom');
    }
  }, []);
  return (
    <footer className={'page-footer font-small bg-secondary mt-5 ' + height}>
      <div className="footer-copyright text-center py-3 text-white">
        2020 PenseTroque®
      </div>
    </footer>
  );
}

export default Footer;
