import React, { Component } from 'react';
import './info.css';

class Info extends Component {
    render(){
        return(
            <div className="col-md-6 p-5">
                        <div className="row">
                                <div className="col-md-8 mt-5 ml-5">
                                    <p className="whatIsRegister m-0 mb-3">O que é o PenseTroque?</p>
                                    <iframe className="homeVideo" src="https://www.youtube.com/embed/B1QCxWH_MZ4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <p className="whatIsText mt-4">
                                        O PenseTroque é uma plataforma de trocas que tem como principal objetivo tornar a experiência de troca tão prazerosa quanto uma experiência de compra, mas como?
                                    </p>
                                    <p className="mt-3 whatIsContinue">Colocando frente-a-frente somente os trocadores que tenham atendido de forma bilateral seus objetivos de troca,  considerando os aspectos financeiros do negócio, e isto ocorre através do "Deu Match", que é o resultado da aplicação do algoritmo às ofertas de veículos, promovendo o casamento perfeito do encontro das pretensões de ambos os trocadores.
                                    </p>
                                    <p className="howItWorks">
                                        Como funciona o PenseTroque?
                                    </p>
                                    <p className="howItWorksText mb-5">
                                    1. Cadastre seu veiculo <br/>
                                    2. Informe se deseja "troca com troco", troca por veiculo de maior valor ou ainda troca por veiculo de mesmo valor <br/>
                                    3. Informe qual é o valor que você quer no bolso ou o valor que você deseja pagar na troca por veiculo de maior valor <br/>
                                    4. Veja os "matches" e crie a sua lista de favoritos. <br/>
                                    5. Utilize créditos para visualizar as informações de contato e inicie as negociações. <br/><br/>
                                    Pronto! Simples não? Vamos começar?
                                    </p>
                                    <p className="searchFilters">
                                        E os filtros de busca?
                                    </p>
                                    <p className="searchFiltersText">Você ainda pode refinar os critérios de busca e encontrar exatamente o veiculo que você procura! <br/><br/> O sistema PenseTroque faz isto para você, 24 horas por dia, 365 dias por ano, até quando você não estiver navegando. Ao encontrar estes veículos, eles aparecerão na sua tela de "Sugestão de Matches".
                                    </p>
                                    <p className="goodExchanges">
                                        Boas trocas!
                                    </p>
                                </div>
                        </div>
                    </div>
        );
    }
}

export default Info;