import React, { Component } from 'react';
import './newpassword.css';
import TextField from '@material-ui/core/TextField';
import Info from '../../components/Info';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';

class NewPassword extends Component {

    componentDidMount(){
        window.scrollTo(0, 0)
    }

    render(){
        document.title = 'Esqueci minha senha'
        return(
            <div className="overflow-hidden">
                <Header/>
                <div className="row ">
                    <div className="col-6 blueBgRegister bgDivSettings p-5">
                        <div className="row">
                            <div className="col-8 mt-5 ml-5">
                                <p className="questionRegister m-0">Esqueceu sua senha?</p>
                                <p className="guideRegister mt-3">Crie e confirme sua nova senha e <span className="welcome">boas trocas!</span></p>
                                <div className="registerInputs">                                    
                                    <TextField id="standard-basic" className="mailInput mt-3" label="NOVA SENHA"/>
                                    <TextField id="standard-basic" className="whatsInput mt-3" label="CONFIRMAR NOVA SENHA"/>    
                                </div>
                                <Link to='/congratulations'><button type="submit" className="submitRegister mt-5">cadastrar</button></Link>
                            </div>
                        </div>
                    </div>
                    <Info/>
                </div>
            </div>
        )
    }
}

export default NewPassword;
