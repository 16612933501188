import React, { Component } from 'react';
import './header.css';
import Logo from '../../assets/img/logo_pense.png';
import TextField from '@material-ui/core/TextField';
import LoginButton from '../../components/LoginButton';
import { Link } from 'react-router-dom';
import firebase from 'firebase';
import GoogleSignin from 'react-google-login';
import { BASE_URL } from "../../services/api"

let signOut = async () => {
    try {
        firebase.auth().signOut();
    } catch (error) {
        console.error(error);
    }
};

let getUserExchangerId = async (email) => {
    await signOut();
    try {
        const url = `${BASE_URL}/login`;
        const config = {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email})
        }
        let api = await fetch(url, config);
        let response = await api.json();
        let exchangerId = response.exchanger_id;
        let basketId = response.exchanger_basket_id;
        let productId = response.product_id;
        localStorage.setItem('basketId', basketId);
        localStorage.setItem("productId", productId)
        localStorage.setItem("exchangerId", exchangerId)
        if(productId){
          window.location.href = '/matches';
        } else {
          window.location.href = '/registered';
        }
    } catch(err) {
      console.log(err);
    }
}

class Header extends Component {

    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            error: false,
            errorMessage: "",
            isLoading: false
        }
    }

    myChangeHandlerEmail = (event) => {
      this.setState({
        email: event.target.value 
      })
    }

    myChangeHandlerPassword = (event) => {
      this.setState({
        password: event.target.value
      })
    }

    emailLogin = async () => {
      if (this.state.email && this.state.password) {
        let email = this.state.email
        let password = this.state.password
        try {
            let currentUser = await firebase.auth().signInWithEmailAndPassword(email, password);
            if(firebase.auth().currentUser.emailVerified){
              let token = firebase.auth().currentUser.getIdToken();
              localStorage.setItem("token", token);
              getUserExchangerId(this.state.email);
            } else {
              alert('Email não verificado')
            }
          
        } catch (error) {
          this.setState({
            error: true,
            errorMessage: "Usuário ou senha incorreto(s)"
          });
          alert(this.state.errorMessage)
          window.location.reload()
        }
      } else {
        
          alert("Usuário ou senha incorretos")
      }
    };


    googleLogin = async () => {
      await this.googleSignOut();
      let provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider).then(function(result){
        var token = result.credential.accessToken;
        var user = result.user;
        if(user){
          let name = user.displayName;
          let email = user.email;
          localStorage.setItem("name", name);
          localStorage.setItem("email", email);
          localStorage.setItem("user", user);
          localStorage.setItem('token', token);
          getUserExchangerId(email);
        } else {
          alert('Problema no cadastro')
        }
      }).catch(function(error){
        alert('Erro')
      }).then(

      )
    }

    // googleLogin = async () => {
    //   let credential;
    //   try {
    //     await GoogleSignin.configure({
    //       webClientId: "84160177470-n2mehhqdudnn0n3h6s5t98v7drld4r2a.apps.googleusercontent.com",
    //       forceConsentPrompt: true
    //     });
    //     const data = await GoogleSignin.signIn();
    //     console.log(data)
    //     credential = firebase.auth.GoogleAuthProvider.credential(data.idToken, data.accessToken);
    //   } catch (e) {
    //     alert('Erro na credencial')
    //   }
    //   try {
    //     const firebaseUserCredential = await firebase.auth().signInWithCredential(credential);
    //     this.getUserExchangerId(firebaseUserCredential.user.email);
    //   } catch (e) {
    //     alert('erro no login com google')
    //     // this.props.uiShowError("Tente novamente");
    //     // this.props.uiStopLoading();
    //   }
    // };

    googleSignOut = async () => {
      try {
        await GoogleSignin.revokeAccess();
        await GoogleSignin.signOut();
      } catch (error) {}
    };

    // facebookLogin = async () => {
    //   this.setState({isLoading: true})

    //   let provider = new firebase.auth.FacebookAuthProvider();
    //   firebase.auth().signInWithPopup(provider).then(function(result){
    //     var token = result.credential.accessToken;
    //     var user = result.user;
    //   }).catch(function(error){
    //     alert('erro')
    //   })
    // }

    
    render(){
        const google = <i className="fab fa-google"></i>
        // const facebook = <i className="fab fa-facebook-f"></i>
        return(
            <div className="yellowBg headerSettings d-flex">
                <div className="logoDiv">
                    <img className="logoSettings" src={Logo} alt="Logo PenseTroque Menu"/>
                </div>
                <div className="container d-flex justify-content-end">
                    <div className="row inputsContainer">
                        <div className="col-md-6 loginInputs">
                            <TextField
                            id="standard-basic"
                            className="userInput"
                            label="EMAIL"
                            onChange = {this.myChangeHandlerEmail}
                            />
                        </div>
                        <div className="col-md-6 loginInputs">
                            <TextField
                            id="standard-basic"
                            type="password"
                            className="passwordInput"
                            label="SENHA"
                            onChange = {this.myChangeHandlerPassword}
                            />
                        </div>
                    </div>
                    <div className="row position-absolute rowLoginSettings d-flex justify-content-around">
                        <div className="col-md-3">
                            <div className="buttonSettings blueButtonBg d-flex justify-content-center" onClick={this.emailLogin}>
                                <p className="buttonContent text-center">Entrar</p>
                            </div>
                        </div>
                        <div className="col-md-3" onClick={this.googleLogin}>
                            <LoginButton iconMedia={google} bar="|" mediaName="google" />
                        </div>
                        {/* <div className="col-3" onClick={this.facebookLogin}>
                            <LoginButton iconMedia={facebook} bar="|" mediaName="facebook"/>
                        </div> */}
                        {/* <div className="col-3">
                            <Link to='/forgot'  className="forgotPassword">Esqueci minha senha</Link>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
