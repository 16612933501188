import React from 'react';
import { Api } from '../../services/paymentApi';

import './styles.css';

function LoadingOrder() {
  return (
    <div className="card skeleton-card my-3">
      <div className="skeleton-card-header card-header d-flex justify-content-between"></div>
      <div className="skeleton-card-body">
        <div className="skeleton-text"></div>
        <div className="skeleton-text"></div>
        <div className="skeleton-text"></div>
      </div>
      <div className="skeleton-card-footer"></div>
    </div>
  );
}

export default LoadingOrder;
