import React, { createContext, useState } from 'react';
import PaymentApi from '../../services/paymentApi';
export const CartContext = createContext({});

const Cart = (props) => {
  const [cart, setCart] = useState({
    userId: null,
    value: null,
    qtd: null,
    vaucher: null,
  });

  function closeCart() {
    setCart({
      userId: null,
      value: null,
      qtd: null,
      vaucher: null,
    });
  }
  return (
    <CartContext.Provider value={{ cart, setCart, closeCart }}>
      {props.children}
    </CartContext.Provider>
  );
};
export default Cart;
