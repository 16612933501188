import React, { Component } from 'react';
import Logo from '../../assets/img/logo_pense2.png';
import './home.css';
import Iphone from '../../assets/img/iphone_mockup.png';
import Android from '../../assets/img/android_mockup.png';
import AvailableGoogle from '../../assets/img/GOOGLEPLAYSTORE.png';
import AvailableApp from '../../assets/img/APPLESTORE.png';
import RicardoPhoto from '../../assets/img/RICARDO.jpg';
import { Link } from 'react-router-dom';
import QRCode from '../../assets/img/googleplay.png';
import Banner from '../../assets/img/bannerBg3.png';

class Home extends Component {
  render() {
    document.title = 'PenseTroque - Pensou, Deu Match, Trocou!';
    return (
      <div>
        <div className="header sticky-top shadow-lg d-md-flex justify-content-center">
          <nav className="navbar navbar-expand-lg navbar-light p-0">
            <div className="mb-2 d-md-flex justify-content-center  mr-md-5">
              <img
                className="imgSet mr-5 mr-md-0 p-2"
                src={Logo}
                alt="logo PenseTroque"
              />
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div
              className="collapse navbar-collapse nav"
              id="navbarNavAltMarkup"
            >
              <div className="navbar-nav">
                <a className="nav-link linkSet ml-3 mr-3" href="/">
                  Home<span className="sr-only">(current)</span>
                </a>
                <a className="nav-link linkSet ml-3 mr-3" href="/blog">
                  Blog
                </a>
                <a className="nav-link linkSet ml-3 mr-3" href="/team">
                  Equipe
                </a>
                <a className="nav-link linkSet ml-3 mr-3" href="/faq">
                  FAQ
                </a>
                <a className="nav-link linkSet ml-3 mr-3" href="/partners">
                  Parceiros
                </a>
                <a
                  className="nav-link linkSet ml-3 mr-3"
                  href="/comprar-troquetas"
                >
                  Comprar Troquetas
                </a>
                <a
                  className="nav-link btnLogin btn  ml-3 mr-3 mb-3 mb-md-0"
                  href="/login"
                >
                  Login
                </a>
              </div>
            </div>
          </nav>
        </div>
        <div className="causeBanner mb-5 d-flex justify-content-center ">
          <div className="container">
            <div className="row mt-5">
              <div className="col-12 col-md-12 causeTitle d-flex flex-column align-items-center">
                <h1 className="bannerTitle mt-5 mb-0 text-center w-75">
                  Plataforma Digital de Trocas e Negociações Inteligentes
                </h1>
                <h2 className="bannerSubTitle mt-md-0 mt-4 text-center">
                  Conheça o valioso poder do MATCH!
                </h2>
              </div>
            </div>
            <div className="row mt-md-5">
              <div className="col-12 col-md-12 causeTitle d-md-flex flex-column align-items-center">
                <h3 className="bannerText mt-5 mb-0 text-center">
                  Pensou, DEU MATCH, Trocou!
                </h3>
              </div>
            </div>
            {/* <div className="row mt-2">
              <div className="col-12 col-md-12 causeTitle d-md-flex flex-column align-items-center">
                <a
                  href="/comprar-troquetas"
                  className="callToButton text-center p-2"
                >
                  Comprar Troquetas
                </a>
              </div>
            </div> */}
          </div>
        </div>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="causeQuestion">Nossa Causa</div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 d-flex justify-content-center mt-5 mb-5">
              <p className="causeDesc text-center">
                Já pensou em trocar sua moto esportiva por um carro com apenas
                um MATCH?! <br />A PenseTroque nasceu com o objetivo de apoiar e
                viabilizar as negociações de forma inteligente. A plataforma
                proporciona uma nova experiência ao mercado das trocas de bens e
                serviços, onde a qualidade das propostas compõe a experiência
                ideal.
              </p>
            </div>
          </div>
        </div>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-12 col-md-6">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/B1QCxWH_MZ4"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-12 col-md-6">
              <h2 className="videoTitle">TROCAR COM A PENSETROQUE® É FÁCIL:</h2>
              <p className="videoDesc">
                1) FAÇA SEU CADASTRO
                <br />
                2) DEFINA AS PREFERÊNCIAS PARA A TROCA OU NEGOCIAÇÃO
                <br />
                3) ESCOLHA AS MELHORES OFERTAS
                <br />
                4) INICIE A NEGOCIAÇÃO
                <br />
                <br />
                Pensou, DEU MATCH, Trocou!
              </p>
            </div>
          </div>
        </div>
        <div className="reasonsDiv mt-5">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="reasonsTitle mt-5">
                <p>Por que escolher a PenseTroque?</p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 d-flex justify-content-center">
                <div className="d-flex flex-column mt-5 mb-5 align-items-center reasonCard p-4 shadow">
                  <i className="fas fa-cogs reasonsIcon"></i>
                  <p className="reasonTitle mt-2">Praticidade</p>
                  <p className="reasonDesc">
                    Através de nosso poderoso algoritmo poupamos seu tempo de
                    entrar em contato e buscar por usuários que aceitem sua
                    proposta.{' '}
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center">
                <div className="d-flex flex-column mt-5 mb-5 align-items-center reasonCard p-4 shadow">
                  <i className="fas fa-tachometer-alt reasonsIcon"></i>
                  <p className="reasonTitle mt-2">Objetividade</p>
                  <p className="reasonDesc">
                    A lógica do aplicativo faz com que na tela do usuário
                    apareça apenas opções de veículos que já estejam de acordo
                    com a oferta de troca ou negociação.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 d-flex justify-content-center">
                <div className="d-flex flex-column mt-3 mb-5 align-items-center reasonCard p-4 shadow">
                  <i className="fas fa-infinity reasonsIcon"></i>
                  <p className="reasonTitle mt-2">Ilimitado</p>
                  <p className="reasonDesc">
                    Você pode negociar quantas vezes quiser!
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center">
                <div className="d-flex flex-column mt-3 mb-5 align-items-center reasonCard p-4 shadow">
                  <i className="fas fa-wrench reasonsIcon"></i>
                  <p className="reasonTitle mt-2">Personalizável</p>
                  <p className="reasonDesc text-center">
                    Plataforma Private Label Multilanguage em nuvem (Azure)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="downloadApp" className="row d-none d-md-flex bannerBg">
          <img src={Banner} className="w-100 img-fluid" alt="Ciclo PenseTroque" useMap="#bannermap" />         
          <a className="linkButton" target="_blank" href="https://play.google.com/store/apps/details?id=com.pensetroqueapp&hl=pt" style={{left: '19%'}}></a>
          <a className="linkButton" target="_blank" href="https://apps.apple.com/br/app/pensetroque-ve%C3%ADculos/id1518904304" style={{left: '34%'}}></a>
        </div>
        <div id="downloadApp" className="row d-flex align-items-center flex-column d-md-none downloadDiv">
          <p className="downloadText mt-5">
            Baixe nosso aplicativo <br></br> e comece a trocar
          </p>
          <a href="https://play.google.com/store/apps/details?id=com.pensetroqueapp&hl=pt">
            <img src={AvailableGoogle} alt="Disponível no Google Play" />
          </a>
          <a href="https://apps.apple.com/br/app/pensetroque-ve%C3%ADculos/id1518904304">
            <img
              src={AvailableApp}
              className="ml-2 mb-5"
              alt="Disponível na App Store"
            />
          </a>
        </div>
<div className="container-fluid contactDiv">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="contactTitle mt-5">
                <p>Onde Estamos</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex flex-column align-items-center">
              <p className="m-0 companyTitle">Matriz PenseTroque</p>
              <p className="m-0">
                Av. Visconde de Guarapuava, 55 - Alto da Rua XV, Curitiba, PR.
              </p>
              <div className="container">
                <iframe
                  className="mt-3 mb-5"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.33976996802!2d-49.24489798533493!3d-25.426901838996486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce5b344193e69%3A0x19e663a7b18600d4!2sAv.%20Visc.%20de%20Guarapuava%2C%2055%20-%20Alto%20da%20XV%2C%20Curitiba%20-%20PR%2C%2080045-345!5e0!3m2!1spt-BR!2sbr!4v1628864333503!5m2!1spt-BR!2sbr" 
				  width="100%"
                  frameborder="0"
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex flex-column align-items-center">
              <p className="m-0 companyTitle">Filial São Paulo</p>
              <p className="m-0">
                R. Eng. Jorge Oliva, 161 - V. Mascote, São Paulo, SP.
              </p>
              <div className="container">
                <iframe
                  className="mt-3 mb-5"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3654.866384914964!2d-46.668516885381855!3d-23.644955670568905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5a834cff5e9b%3A0x45ceb104c8c4ff76!2sR.%20Eng.%20Jorge%20Oliva%2C%20161%20-%20Vila%20Mascote%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2004362-060!5e0!3m2!1spt-BR!2sbr!4v1628864617153!5m2!1spt-BR!2sbr"
                  width="100%"
                  frameborder="0"
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center mb-5">
              <Link
                to="/contact"
                className="redirectButton d-flex justify-content-center align-items-center"
              >
                <p className="m-0">Entre em contato</p>
              </Link>
            </div>
          </div>
        </div>
        <footer>
          <div className="col-12 footerBg">
            <div className="container">
              <div className="row">
                <div className="col-4 d-none d-md-flex flex-column mt-5">
                  <p className="footerSectionTitle">Sobre Nós</p>
                  <p className="footerText">Pensou, deu match, trocou!</p>
                  <Link
                    to="/contact"
                    className="redirectButton d-flex justify-content-center align-items-center"
                  >
                    <p className="m-0">Entre em contato</p>
                  </Link>
                </div>
                <div className="col-2"></div>
                <div className="col-3 d-none d-md-flex flex-column mt-5">
                  <p className="footerSectionTitle">Navegação</p>
                  <a href="/" className="footerText">
                    Home
                  </a>
                  <a href="/blog" className="footerText">
                    Blog
                  </a>
                  <a href="/team" className="footerText">
                    Equipe
                  </a>
                  <a href="/faq" className="footerText">
                    FAQ
                  </a>
                  <a href="/partners" className="footerText">
                    Parceiros
                  </a>
                  <a href="/login" className="footerText">
                    Login
                  </a>
                </div>
                <div className="col-3 d-none d-md-flex flex-column mt-5">
                  <p className="footerSectionTitle">Links úteis</p>
                  <a href="/termos-de-uso" className="footerText">
                    Termos de Uso
                  </a>
                  <a href="/privacidade" className="footerText">
                    Privacidade
                  </a>
                  <a href="/contact" className="footerText">
                    Contato
                  </a>
                </div>
              </div>
              <hr className="lineBorder d-none d-md-flex" />
              <div className="row">
                <div className="col-12 d-flex justify-content-center mt-5 mt-md-0 mb-3">
                  <div>
                    <a href="https://www.facebook.com/pensetroqueveiculos">
                      <i className="fab fa-facebook socialIcon mr-4"></i>
                    </a>
                    <a href="https://www.instagram.com/pensetroque/">
                      <i className="fab fa-instagram socialIcon mr-4"></i>
                    </a>
                    <a href="https://www.linkedin.com/company/pensetroque-veiculos/">
                      <i className="fab fa-linkedin socialIcon mr-4"></i>
                    </a>
                    <a href="https://www.youtube.com/channel/UClCxpZNW7SzAOBwAmTTUnKg">
                      <i className="fab fa-youtube socialIcon"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <p className="copyText">&copy; 2020 - PenseTroque</p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Home;
