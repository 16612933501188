import React, { Component } from 'react';
import './congratulations.css';
import Info from '../../components/Info';
import Header from '../../components/Header';

class Congratulations extends Component {

    componentDidMount(){
        window.scrollTo(0, 0)
    }

    render(){
        document.title = 'Senha alterada'
        return(
            <div className="overflow-hidden">
                <Header/>
                <div className="row ">
                    <div className="col-6 blueBgRegister bgDivSettings p-5">
                        <div className="row">
                            <div className="col-8 mt-5 ml-5">
                                <p className="questionRegister m-0">Parabéns!</p>
                                <p className="guideRegister mt-3">Sua senha foi alterada com sucesso, faça seu login e <span className="welcome">boas trocas!</span></p>
                            </div>
                        </div>
                    </div>
                    <Info/>
                </div>
            </div>
        )
    }
}

export default Congratulations;
