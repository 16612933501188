import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import RegisterSite from './pages/RegisterSite';
import Register from './pages/Register';
import Recover from './pages/Recover';
import ForgotPassword from './pages/ForgotPassword';
import Confirmation from './pages/Confirmation';
import Matches from './pages/Matches';
import Registered from './pages/Registered';
import MatchDetails from './pages/MatchDetails';
import ContactDetails from './pages/ContactDetails';
import SecurityCode from './pages/SecurityCode';
import NewPassword from './pages/NewPassword';
import Congratulations from './pages/Congratulations';
import PrivateRoute from './auth';
import Error from './pages/Error';
import WaitingDetails from './pages/WaitingDetails';
import Privacy from './pages/Privacy';
import TermsOfService from './pages/TermsOfService';
import Home from './pages/Home';
import LoginSite from './pages/LoginSite';
import Construction from './pages/Construction';
import Partners from './pages/Partners';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import Team from './pages/Team';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import BuyTroquetas from './pages/BuyTroquetas';
import DataConfirmation from './pages/DataConfirmation';
import Payment from './pages/Payment';
import PaymentSuccess from './pages/PaymentSuccess';
import OrderList from './pages/OrderList';

const Routes = () => {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/urlescondida" component={Home} />
          <Route path="/faq" component={FAQ} />
          <Route path="/blog" component={Blog} />
          <Route path="/blogpost" component={BlogPost} />
          <Route path="/partners" component={Partners} />
          <Route path="/contact" component={Contact} />
          <Route path="/team" component={Team} />
          <Route path="/login" component={LoginSite} />
          <Route path="/register" component={RegisterSite} />
          <Route path="/recover" component={Recover} />
          <Route path="/forgot" component={ForgotPassword} />
          <Route path="/confirmation" component={Confirmation} />
          <Route path="/construction" component={Construction} />
          <PrivateRoute path="/matches" component={Matches} />
          <PrivateRoute path="/registered" component={Registered} />
          <PrivateRoute
            path="/matchdetails/:id/:my/:ei/:lni/:ebi/"
            component={MatchDetails}
          />
          <PrivateRoute
            path="/contactdetails/:id/:my/:ei"
            component={ContactDetails}
          />
          <PrivateRoute
            path="/waitingdetails/:id/:my"
            component={WaitingDetails}
          />
          {/* id corresponde ao carro do outro trocador, e my corresponse do usuário que está acessando */}
          <Route path="/securitycode" component={SecurityCode} />
          <Route path="/newpassword" component={NewPassword} />
          <Route path="/congratulations" component={Congratulations} />
          <Route path="/privacidade" component={Privacy} />
          <Route path="/termos-de-uso" component={TermsOfService} />
          {/* rotas correspondente a sessão de pagamento */}
          <Route path="/comprar-troquetas" component={BuyTroquetas} />
          <PrivateRoute
            path="/confirmacao-dados"
            component={DataConfirmation}
          />
          <PrivateRoute path="/minhas-compras" component={OrderList} />
          <Route path="/pagamento" component={Payment} />
          <Route path="/success" component={PaymentSuccess} />
          <Route exact path="*" component={Error} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default Routes;
