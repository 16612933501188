import React, {Component} from "react";
import {Link} from 'react-router-dom';
import './error.css';
import Logo from '../../assets/img/logo_pense.png';


class Error extends Component{
    render(){
        document.title = 'Página não encontrada'
        return(
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center align-items-center mt-5">               
                            <p className="errorSettings">404</p>
                            <img src={Logo} width="180px" alt="Página não encontrada, 404"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <p className="errorMessage">
                                Página não encontrada
                            </p>
                        </div>
                    </div>
                    <div className="row d-flex flex-column justify-content-center align-items-center ">
                    <Link to='/matches' className="linkRedirectError align-middle">
                        <div className="col-12 buttonErrorBackground text-center">
                             voltar para a página principal
                        </div>
                    </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Error;