import React, { Component } from 'react';
import './waitingdetails.css';
import Menu from '../../components/Menu';
import ProductBox from '../../components/ProductBox';
import { Link } from 'react-router-dom';
import Carousel from '../../components/Carousel';


class WaitingDetails extends Component {

    constructor(props) {
        super(props);
        const { my } = this.props.match.params; 
        const { id } = this.props.match.params;             
        this.state = {
        productId: id,
        myId: my,
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0)
    }

    render(){
        document.title = 'Detalhes da Negociação'
        return(
            <div>
                <Menu/>
                <div className="row">
                    <div className="col-8 blueBgMatches titleSettings myVehicle">
                        <p className="detailsTitle mt-3">Detalhes do Veículo</p>
                    </div>
                    
                    <div className="goBack col-4 d-flex justify-content-left align-items-center myVehicle"><Link to='/matches' className="goBackLink"><i className="fas fa-caret-left iconGoBack"></i>Voltar</Link></div>
                </div>
                <div className="container">
                <div className="row justify-content-md-center">
                        <div className="col-md-5">
                            <div className="row">
                                <Carousel productId = {this.state.myId} carouselSize="carouselMedium"/>
                            </div>
                            <div className="row">                                
                                <ProductBox productId = {this.state.myId} collorbox ="-blue"/>
                            </div>
                        </div>
                        <div className="col-md-auto">
                            
                        </div>
                        <div className="col-md-5">
                            <div className="row">
                                <Carousel productId = {this.state.productId} carouselSize="carouselMedium"/>
                            </div>
                            <div className="row">
                                <ProductBox productId = {this.state.productId} collorbox ="-green"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 bgColorNone">
                            <h1 className="ml-2 mt-5 fontNone">
                                Aguardando resposta do trocador. 
                            </h1>
                            <p className="infoNone ml-2">
                            Instale nosso App no seu celular para visualizar mais carros e iniciar outras negociações! <br/>
                            Disponível na App Store e na Google Play!
                            </p>
                        </div>
                    </div>
                </div>
                

                </div>
        );
    }
}

export default WaitingDetails;