import React, { Component } from 'react';
import './contact.css';

class Contact extends Component {
    render(){
        return(
            <div className="col-6 p-5">
                <div className="col-8 mt-5 ml-5">
                    <p className="download mt-5">Baixe nosso aplicativo</p>
                    <p className="downloadDescription">
                        Com ele você pode configurar para receber notificações em tempo real quando alguém lhe enviar uma oferta de troca.
                    </p>

                    <p className="mt-3"><i className="fab fa-apple appleIcon"></i><i className="material-icons androidIcon">
                        android
                    </i></p>
                    <p className="contactUs mt-5">
                        Fale conosco
                    </p>
                    <p className="contact mt-2">Dúvidas, sugestões ou reclamações? <br/> Conheça nossas redes sociais ou entre contato pelo nosso WhatsApp.</p>
                    <div className="socialMedias mt-4">
                        <p className="linkedin"><i className="fab fa-linkedin-in mt-3 linkedinIcon"></i>@PenseTroque</p>
                        <p className="instagram"><i className="fab fa-instagram mt-3 instagramIcon"></i>/PenseTroque</p>
                        <p className="mail"><i className="fas fa-envelope mt-3 mailIcon"></i>contato@pensetroque.com.br</p>
                        <p className="whatsapp"><i className="fab fa-whatsapp mt-3 whatsIcon"></i>(11) 9 8077-5414</p>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default Contact;