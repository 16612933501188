import React, { Component } from 'react';
import Menu from '../../components/Menu';
import './matches.css';
import VehicleCard from '../../components/VehicleCard';
import Carousel from '../../components/Carousel';
import ProductBox from '../../components/ProductBox';


class Matches extends Component {
    constructor(){
        super();
        this.state = {
            product: {},
            id: ''
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0)
        let product_id = localStorage.getItem("productId");
        let url = `https://api.pensetroque.com.br/${product_id}/product`;
        const config = {
            method: "GET",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          }
        fetch(url, config)
        .then((r)=> r.json())
        .then((json)=>{
            if(json !== null){
                this.setState({
                    product: json,
                    id: json.product_id
                })
            } else {
                console.log("deu erro no consumo da api")
            }
        })
    }
    
    
    render(){
        document.title = 'Matches'
        let productId = localStorage.getItem('productId');
        return(
            <div>
                <Menu/>
                <div className="row">
                    <div className="col-12 blueBgMatches titleSettings myVehicle">
                        <p className="suggestionsTitle mt-3">Meu veículo</p>
                    </div>
                </div>
                <div className="container-fluid head-table-2">
                    <div className="row">
                        <div className="col-md-7">
                            <Carousel productId={productId} match="false" pathLink="#" carouselSize="carouselLarge"/>
                        </div>
                        <div className="col-md-5"> 
                            <ProductBox productId={productId} collorbox ="-blue"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 blueBgMatches titleSettings">
                        <p className="suggestionsTitle mt-3">Minhas ofertas para negociação</p>
                    </div>
                </div>
                <VehicleCard/>
            </div>
        );
    }
}

export default Matches;