import React, { Component } from 'react';
import './registersite.css';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo_pense2.png'
import firebase from 'firebase';


class RegisterSite extends Component {
    constructor(props) {
        super(props)
        this.onSignUpHandler = this.onSignUpHandler.bind(this)
        this.state = {
            message : this.props.location.state?this.props.location.state.message: '',
            name: '',
            username: '',
            email: '',
            phone: '',
            password: '',
            password2: '',
            error: false,
            errorMessage: '',
            emailVerification: false
        };
    }

    componentDidMount(){
        window.scrollTo(0, 0)
      }
    
      myChangeHandlerName = (event) => {
        this.setState({
          name: event.target.value
        })
      }
  
      myChangeHandlerEmail = (event) => {
        this.setState({
          email: event.target.value 
        })
      }
  
      myChangeHandlerPhone = (event) => {
        this.setState({
          phone: event.target.value
        })
      }
  
      myChangeHandlerUsername = (event) => {
        this.setState({
          username: event.target.value
        })
      }
  
      myChangeHandlerPassword = (event) => {
        this.setState({
          password: event.target.value
        })
      }
  
      myChangeHandlerPassword2 = (event) => {
        this.setState({
          password2: event.target.value
        })
      }

    onSignUpHandler = async () => {
        if (this.state.password === this.state.password2) {
            try {
            let currentUser = await firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password);
            await firebase.auth().currentUser.sendEmailVerification();
            alert ('Cadastro realizado com sucesso. Agora, confirme seu e-mail e efetue seu login.');
            window.location.href = '/login';
            } catch (error) {
            this.setState({
                error: true,
                errorMessage: "Erro ao cadastrar. Tente novamente"
            });
            alert('Erro na hora do cadastro, cheque seus dados e tente novamente.')
            }
        } else {
            this.setState({
            error: true,
            errorMessage: "Senhas não são iguais"
            });
            alert('Erro na hora do cadastro, cheque seus dados e tente novamente.')
        }
    };

    render(){
        return(
            <div className="loginBg p-5">
                <div className="loginCard m-auto mt-5 mb-5 p-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                                <img src={Logo} width="60%" alt="Logo PenseTroque"/>
                                <p className="mt-3">Preencha os dados</p>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-user-check"></i></span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Nome" aria-label="Nome" aria-describedby="basic-addon1" onChange = {this.myChangeHandlerName}/>
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon2"><i className="fas fa-envelope"></i></span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Email" aria-label="Email" aria-describedby="basic-addon2" onChange = {this.myChangeHandlerEmail}/>
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon3"><i className="fas fa-phone-alt"></i></span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Telefone" aria-label="Telefone" aria-describedby="basic-addon3" onChange = {this.myChangeHandlerPhone}/>
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon4"><i className="fas fa-key"></i></span>
                                    </div>
                                    <input type="password" className="form-control" placeholder="Senha" aria-label="Senha" aria-describedby="basic-addon4" onChange = {this.myChangeHandlerPassword}/>
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon5"><i className="fas fa-lock"></i></span>
                                    </div>
                                    <input type="password" className="form-control" placeholder="Confirme sua senha" aria-label="Confirme sua senha" aria-describedby="basic-addon5" onChange = {this.myChangeHandlerPassword2}/>
                                </div>
                                <div className="btn btn-primary btn-primary w-100 mt-3" onClick={this.onSignUpHandler}>Finalizar cadastro</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RegisterSite;