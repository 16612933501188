import React, { Component } from 'react';
import Header from '../../components/Header'
import './recover.css';
import TextField from '@material-ui/core/TextField';
import Info from '../../components/Info';
import { Link } from 'react-router-dom';


class Recover extends Component {

    componentDidMount(){
        window.scrollTo(0, 0)
    }

    render(){
        document.title = 'Recuperar veículo'
        return(
            <div className="overflow-hidden">
                <Header/>
                <div className="row ">
                    <div className="col-6 blueBgRegister bgDivSettings p-5">
                        <div className="row">
                            <div className="col-8 mt-5 ml-5">
                                <p className="questionRegister m-0">Já possui um veículo publicado?</p>
                                <p className="guideRegister mt-3">Digite nos campos abaixo seu e-mail ou telefone de cadastro. <br/> Você vai receber um código de segurança. <br/> Digite o código de segurança no local indicado e boas trocas!</p>
                                <div className="registerInputs">                                    
                                    <TextField id="standard-basic" className="mailInput mt-3" label="E-MAIL"/>
                                    <TextField id="standard-basic" className="whatsInput mt-3" label="WHATSAPP"/>    
                                </div>
                                <Link to='/securitycode'><button type="submit" className="submitRegister mt-5">enviar</button></Link>
                            </div>
                        </div>
                    </div>
                    <Info/>
                </div>
            </div>
        );
    }
}

export default Recover;