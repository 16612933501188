import React, { Component } from 'react';
import './contactdetails.css';
import Menu from '../../components/Menu';
import Contact from '../../components/Contact';
import ProductBox from '../../components/ProductBox';
import { Link } from 'react-router-dom';
import Carousel from '../../components/Carousel';
import Unavailable from '../../assets/img/indisponivel.png'

class ContactDetails extends Component {
    constructor(props) {
        super(props);
        const { my } = this.props.match.params;
        const { id } = this.props.match.params;
        const { ei } = this.props.match.params;
        this.state = {
        productId: id,
        myId: my,
        exchangerId: ei,
        exchanger: [],
        photo: ''
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0)
        let url = `https://api.pensetroque.com.br/${this.state.exchangerId}/exchangers`;
        const config = {
            method: "GET",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
        }
        fetch(url, config)
        .then((r)=> r.json())
        .then((json)=>{
            if(json !== null){
                this.setState({
                    exchanger: json
                })
                
                if(json.exchanger_photo_url !== null && json.exchanger_photo_url !== undefined){
                    this.setState({
                        photo: this.state.exchanger.exchanger_photo_url
                    }) 
                } else {
                    this.setState({
                        photo: 'trocador sem imagem'
                    })
                }
            } else {
                console.log("deu erro no consumo da api")
            }
        })
        // /
    }
    render(){
        document.title = 'Detalhes do Match'
        return(
            <div>
                <Menu/>
                <div className="row">
                    <div className="col-8 blueBgMatches titleSettings myVehicle">
                        <p className="detailsTitle mt-3">Detalhes do Veículo</p>
                    </div>
                    
                    <div className="goBack col-4 d-flex justify-content-left align-items-center myVehicle"><Link to='/matches' className="goBackLink"><i className="fas fa-caret-left iconGoBack"></i>Voltar</Link></div>
                </div>
                <div className="container">
                <div className="row justify-content-md-center">
                        <div className="col-md-5">
                            <div className="row">
                                <Carousel productId = {this.state.myId} carouselSize="carouselMedium"/>
                            </div>
                            <div className="row">                                
                                <ProductBox productId = {this.state.myId} collorbox ="-blue"/>
                            </div>
                        </div>
                        <div className="col-md-auto">
                            
                        </div>
                        <div className="col-md-5">
                            <div className="row">
                                <Carousel productId = {this.state.productId} carouselSize="carouselMedium"/>
                            </div>
                            <div className="row">
                                <ProductBox productId = {this.state.productId} collorbox ="-green"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 blueBgMatches titleSettings">
                        <p className="detailsTitle mt-3">Contatos do trocador</p>
                    </div>
                </div>
                <div className="container border-bottom">
                    <div className="row d-flex justify-content-center align-items-center mt-5 mb-5">

                                
                                {this.state.photo === 'trocador sem imagem' ? 
                                    <img src={Unavailable} alt="Foto de perfil do trocador indisponível" className="userProfileImageMatches"/>
                                    :
                                    <React.Fragment>
                                    {this.state.photo.substring(0, 4) === 'http' ? 
                                        <img src={this.state.photo} alt="Foto de perfil do trocador" className="userProfileImageMatches"/>
                                        :
                                        <img src={`https://api.pensetroque.com.br/file/${this.state.photo}`} alt="Foto de perfil do trocador" className="userProfileImageMatches"/>
                                    }
                                    </React.Fragment>
                                }


                        <div className="userProfileInfoMatches">
                            <p className="userNameMatches">{this.state.exchanger.exchanger_name}</p>
                            <p className="userMailMatches">{this.state.exchanger.exchanger_email}</p>
                            <p className="userPhoneMatches">{this.state.exchanger.exchanger_phone}</p>
                            <p className="userTimeMatches">{this.state.exchanger.time_contact_begin} às {this.state.exchanger.time_contact_end} - {this.state.exchanger.city}</p>
                        </div>
                    </div>
                </div>
                
                <Contact/>
            </div>
        );
    }
}

export default ContactDetails;