import React, { Component } from 'react';
import './matchdetails.css';
import Menu from '../../components/Menu';
import Contact from '../../components/Contact';
import ProductBox from '../../components/ProductBox';
import { Link } from 'react-router-dom';
import Carousel from '../../components/Carousel';
import Modal1 from 'react-awesome-modal';
import Modal2 from 'react-awesome-modal';

class MatchDetails extends Component {
    constructor(props) {
        super(props);
        const { my } = this.props.match.params; 
        const { id } = this.props.match.params;             
        const { ei } = this.props.match.params;
        const { lni } = this.props.match.params;
        const { ebi } = this.props.match.params;
        this.state = {
        productId: id,
        myId: my,
        exchangerId: ei,
        lastNegotiationId: lni,
        exchangerBasket: ebi
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0)
    }

    acceptNegotiation(){
        this.setState({
            visible1 : false
        });
        let basketId = localStorage.getItem("basketId");
        let myExchangerId = localStorage.getItem("exchangerId");
        let url_accept_negociation = 'https://apiconcessionaria.pensetroque.com.br/accept-negociation';
        const config = {
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "my_exchanger_id" : myExchangerId,
                "target_exchanger_id" : this.state.exchangerId,
                "last_negotiation_event_id": this.state.lastNegotiationId,
                "my_exchanger_basket_id": basketId,
                "target_exchanger_basket_id": this.state.exchangerBasket
            })
          }
        fetch(url_accept_negociation, config)
        .then((r)=> {
            if(r.status === 200){
                r.json()
            }else {
                r.json().then(json=>{   
                window.location.href= "/error"
                alert("erro na api: "+json.error)
                })
            }        
        })
        .then((json)=>{
            let state = this.state;
            state.list_accept_negociation = json;            
            this.setState({
                list_accept_negociation: json.result                
            })            
        }).catch(error=>{console.log("erro da api de troquetas (ACEITAR)", error)
                
        })

        // let url_wallet = `http://13.92.244.173:3301/add_basket_wallet/${basketId}`;
        // const config_wallet = {
        //     method: "POST",
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         "user_id": this.state.dealer 
        //     })
        // }
        
        // fetch(url_wallet, config_wallet)
        // .then((r)=> {
        //     if(r.status === 200){
        //         r.json()
        //     }else {
        //         r.json().then(json=>{   
        //         window.location.href= "/error"
        //         alert("erro na segunda api: "+json.error)
        //         })
        //     }        
        // })
    }

    rejectNegotiation(){
        this.setState({
            visible2 : false
        });
        let basketId = localStorage.getItem("basketId")
        let url_reject_negociation = 'https://apiconcessionaria.pensetroque.com.br/reject-negociation';
        const config = {
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "my_exchanger_id" : this.state.myId,
                "target_exchanger_id" : this.state.exchangerId,
                "last_negotiation_event_id": this.state.lastNegotiationId,
                "my_exchanger_basket_id": basketId,
                "target_exchanger_basket_id": this.state.exchangerBasket
            })
          }
        fetch(url_reject_negociation, config)
            .then((r)=> {
                if(r.status === 200){
                    r.json()
                }else {
                    r.json().then(json=>{
                    window.location.href= "/error"
                    alert("erro na api: "+json.error)
                    })
                    
    
                }        
        })
        .then((json)=>{
            let state = this.state;
            state.list_reject_negociation = json;            
            this.setState({
                list_reject_negociation: json.result                
            })            
        }).catch(error=>{console.log("erro da api de troquetas (RECUSAR)", error)
                
            })        


    }

    openModal1() {
        this.setState({
            visible1 : true
        });
    }

    closeModal1() {
        this.setState({
            visible1 : false
        });
    }

    openModal2() {
        this.setState({
            visible2 : true
        });
    }

    closeModal2() {
        this.setState({
            visible2 : false
        });
    }


    render(){
        document.title = 'Detalhes da Negociação'
        return(
            <div>
                <Menu/>
                <div className="row">
                    <div className="col-8 blueBgMatches titleSettings myVehicle">
                        <p className="detailsTitle mt-3">Detalhes do Veículo</p>
                    </div>
                    
                    <div className="goBack col-4 d-flex justify-content-left align-items-center myVehicle"><Link to='/matches' className="goBackLink"><i className="fas fa-caret-left iconGoBack"></i>Voltar</Link></div>
                </div>
                <div className="container">
                <div className="row justify-content-md-center">
                        <div className="col-md-5">
                            <div className="row">
                                <Carousel productId = {this.state.myId} carouselSize="carouselMedium"/>
                            </div>
                            <div className="row">                                
                                <ProductBox productId = {this.state.myId} collorbox ="-blue"/>
                            </div>
                        </div>
                        <div className="col-md-auto">
                            
                        </div>
                        <div className="col-md-5">
                            <div className="row">
                                <Carousel productId = {this.state.productId} carouselSize="carouselMedium"/>
                            </div>
                            <div className="row">
                                <ProductBox productId = {this.state.productId} collorbox ="-green"/>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                {/* <div className="row">
                    <div className="col-8 blueBgMatches"></div>
                    <div className="col-2 greenBgMatches d-flex justify-content-left align-items-center">
                        <p className="m-0 accept">Aceitar</p>
                    </div>
                    <div className="col-2 redBgMatches d-flex justify-content-left align-items-center">
                        <p className="m-0 remove">Remover</p>
                    </div>
                </div> */}

                <div className="row mt-3 blueBgMatches">
                    <div className="col-md-8">

                    </div>
                    <div className="purchase-decision-accept col-md-2"><button type="button" className="btn-accept"  onClick={() => this.openModal1()}>Aceitar</button>                            
                        <Modal1 
                            visible={this.state.visible1}
                            width="400"
                            height="300"
                            effect="fadeInUp"
                            onClickAway={() => this.closeModal1()}
                        >
                        <div>
                            <h3>Troqueta PenseTroque</h3>
                            <hr/>
                            <p>Para visualizar os dados do Trocador interessado neste veículo é necessário adquirir 01 troqueta do plano previamente contratado pela empresa.</p>
                            <p>Confirma a aquisição?</p>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center">
                                    <a href="javascript:void(0);" onClick={() => this.closeModal1()}><button type="button" className="btn-cancel buttonModalCancel">Cancelar</button></a>
                                    <Link to={`/contactdetails/${this.state.productId}/${this.state.myId}/${this.state.exchangerId}`}><a href="javascript:void(0);" onClick={() => this.acceptNegotiation()}><button type="button" className="btn-accept buttonModalAccept">Aceitar</button></a></Link>
                                </div> 
                            </div>                            
                        </div>
                    </Modal1>
                </div>
                <div className="purchase-decision-cancel col-md-2"><button type="button" className="btn-cancel"  onClick={() => this.openModal2()}>Remover</button>                            
                        <Modal2 
                            visible={this.state.visible2}
                            width="400"
                            height="300"
                            effect="fadeInUp"
                            onClickAway={() => this.closeModal2()}
                        >
                        <div>
                            <h3>Ofertas Atuais PenseTroque</h3>
                            <hr/>
                            <p>Deseja remover esta oferta?</p>
                            <div className="row">
                                    <div className="col-12 d-flex justify-content-center">
                                        <a href="javascript:void(0);" onClick={() => this.closeModal2()}><button type="button" className="btn-cancel buttonModalCancel">Cancelar</button></a>
                                        <Link to={`/matches`}><a href="javascript:void(0);" onClick={() => this.rejectNegotiation()}><button type="button" className="btn-accept buttonModalAccept">Confirmar</button></a></Link>
                                    </div> 
                                </div>                            
                            </div>
                            </Modal2>
                        </div>
                    </div>
                <Contact/>
            </div>
        );
    }
}

export default MatchDetails;