import React, { Component } from "react";
import "./registered.css";
import Menu from "../../components/Menu";
import Contact from "../../components/Contact";

class Registered extends Component {
  constructor() {
    super();
    this.state = {
      list: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let exchangerId = localStorage.getItem("exchangerId");
    let url = `https://api.pensetroque.com.br/${exchangerId}/exchangers`;
    const config = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    fetch(url, config)
      .then((r) => r.json())
      .then((json) => {
        if (json !== null) {
          console.log(json);
          this.setState({
            list: json,
          });
        } else {
          console.log("deu erro no consumo da api");
        }
      });
  }

  render() {
    console.log(this.state.list);
    let name = this.state.list.exchanger_name;
    document.title = "Registrado";
    return (
      <div>
        <Menu />
        <div className="row ">
          <div className="col-6 blueBgRegister bgDivSettings p-5">
            <div className="col-8 mt-5 ml-5">
              <p className="helloExchanger m-0">
                Olá, <span className="exchangerNameSpan">{name}!</span>
              </p>
              <p className="descriptionExchanger mt-3">
                Para que o PenseTroque trabalhe procurando os melhores Matches
                pra você, precisamos que cadastre alguns dados do seu veículo e
                o seu objetivo financeiro. <br />
                <br /> Para isso, instale nosso aplicativo, e cadastre o seu
                veículo lá. Quando tiver um veículo cadastrado, você pode
                consultar as sugestões de Matches aqui no nosso site. <br />
                <br /> Você pode clicar no link ao lado que corresponder ao seu
                sistema operacional, ou pesquisar por nosso aplicativo na Google
                Play ou na Apple Store.
              </p>
            </div>
          </div>
          <Contact />
        </div>
      </div>
    );
  }
}

export default Registered;
