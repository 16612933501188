import React, { Component } from 'react';
import Logo from '../../assets/img/logo_pense2.png';
import './blogpost.css';
import { Link } from 'react-router-dom';
import ImageBlog from '../../assets/img/blog_post1.jpg';
import PostImg from '../../assets/img/postimg.jpg'


class BlogPost extends Component { 
    render(){
        document.title = 'Blog PenseTroque - Troca volta com tudo no comércio de carros!'
        return(
            <div>
                
                <div className="header sticky-top shadow-lg d-md-flex justify-content-center">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <div className="mb-2 d-md-flex justify-content-center  mr-md-5">
                            <img className="imgSet mr-5 mr-md-0 p-2" src={Logo} alt="logo PenseTroque"/>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse nav" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                <a className="nav-link linkSet ml-3 mr-3" href="/">Home<span className="sr-only">(current)</span></a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/blog">Blog</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/team">Equipe</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/faq">FAQ</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/partners">Parceiros</a>
                                <a className="nav-link btnLogin btn  ml-3 mr-3 mb-3 mb-md-0" href='/login'>Login</a>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="container mt-5 mb-5">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-start pl-md-5 pr-md-5">
                            <img src={ImageBlog} width="50%" alt="PenseTroque"/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12 pl-md-5 pr-md-5 d-flex justify-content-start">
                            <h1 className="m-0 postTitle">Na pandemia, 'Troca' volta com tudo no comércio de carros!</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 pl-md-5 pr-md-5 d-flex justify-content-start">
                            <p className="m-0 postIntroduction">Plataforma de venda verificou aumento na procura por troca de veículos, uma boa estratégia para quem quer fazer um dinheiro extra para reforçar as finanças com o valor da diferença entre os preços dos veículos.</p>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 pl-md-5 pr-md-5 d-flex justify-content-start">
                            <p className="m-0 postText">
                                Ao mesmo tempo que a procura por troca aumenta houve também uma queda de 25% no envio de propostas comparado ao período pré-covid, essa queda, porém, é quase toda no envio de leads para pessoas físicas, pois o envio de proposta para lojas está perto do normal.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 pl-md-5 pr-md-3 mt-5">
                            <img src={PostImg} width="100%" alt="Carro"/>
                        </div>
                        <div className="col-md-6 pr-md-5 mt-5 d-flex flex-column">
                            <p className="m-0 postText">
                                Ao mesmo tempo que a procura por troca aumenta houve também uma queda de 25% no envio de propostas comparado ao período pré-covid, essa queda, porém, é quase toda no envio de leads para pessoas físicas, pois o envio de proposta para lojas está perto do normal.
                            </p>
                            <p className="m-0 postText">
                                A plataforma Pensetroque apresenta uma solução simples e objetiva para facilitar o processo de demanda por trocas de veículos, não só por conta da tecnologia MATCH MAKING mas também por suas parcerias com agentes financeiros, vistoriadores e lojistas.
                            </p>
                            
                        </div>
                    </div>
                </div>
                <div className="container mt-5 mb-5 d-flex justify-content-center pl-5 pr-5">
                    <div class="fb-comments" data-href="https://pensetroque.com.br/blogpost" data-numposts="5" data-width=""></div>
                </div>
                <footer>
                    <div className="col-12 footerBg">
                        <div className="container">
                            <div className="row">
                                <div className="col-4 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Sobre Nós</p>
                                    <p className="footerText">Pensou, deu match, trocou!</p>
                                    <Link to='/contact' className="redirectButton d-flex justify-content-center align-items-center"><p className="m-0">Entre em contato</p></Link>
                                </div>
                                <div className="col-2"></div>
                                <div className="col-3 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Navegação</p>
                                    <a href="/" className="footerText">Home</a>
                                    <a href='/blog' className="footerText">Blog</a>
                                    <a href='/team' className="footerText">Equipe</a>
                                    <a href='/faq' className="footerText">FAQ</a>
                                    <a href='/partners' className="footerText">Parceiros</a>
                                    <a href='/login' className="footerText">Login</a>
                                </div>
                                <div className="col-3 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Links úteis</p>
                                    <a href='/termos-de-uso' className="footerText">Termos de Uso</a>
                                    <a href='/privacidade' className="footerText">Privacidade</a>
                                    <a href='/contact' className="footerText">Contato</a>
                                </div>
                            </div>
                            <hr className="lineBorder d-none d-md-flex"/>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center mt-5 mt-md-0 mb-3">
                                    <div>
                                        <a href="https://www.facebook.com/pensetroqueveiculos"><i className="fab fa-facebook socialIcon mr-4"></i></a>
                                        <a href="https://www.instagram.com/pensetroque/"><i className="fab fa-instagram socialIcon mr-4"></i></a>
                                        <a href="https://www.linkedin.com/company/pensetroque-veiculos/"><i className="fab fa-linkedin socialIcon mr-4"></i></a>
                                        <a href="https://www.youtube.com/channel/UClCxpZNW7SzAOBwAmTTUnKg"><i className="fab fa-youtube socialIcon"></i></a>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center">
                                    <p className="copyText">&copy; 2020 - PenseTroque</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default BlogPost;