import React, { Component } from 'react';
import QRCode from '../../assets/img/googleplay.png';
import './modalapp.css'

class ModalApp extends Component {
    render(){
        return(
            <div>
                <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="download mt-3">Baixe nosso aplicativo</p>
                                <p className="downloadDescription">
                                    Para ter acesso à funções únicas, baixe o nosso aplicativo usando o QR Code abaixo (Disponível apenas em Android).
                                </p>
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <img src={QRCode} width="200px " alt="QR Code para acessar loja"/>
                                    </div>
                                </div> 
                                <div className="row">
                                  <div className="col-md-12 text-center mt-2">
                                  <p className="guideRegisterModal m-0 mb-3 downloadDescription">Ou instale o app clicando no link abaixo.</p>
                                  <i className="material-icons">
                                    <a href="https://play.google.com/store/apps/details?id=com.pensetroqueapp" className="materialIconColorBlue">
                                      android
                                    </a>
                                  </i>
                                  </div>
                              </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalApp;