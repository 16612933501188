import React, { createContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BASE_URL } from '../../services/api';
import { Api } from '../../services/api';

export const AuthContext = createContext({});

const Auth = (props) => {
  const [guest, setGuest] = useState(true);
  const [user, setUser] = useState({});

  async function setAuth(email, token) {
    const user = await getUserExchangerId(email);
    if (!user) {
      return false;
    }
    localStorage.setItem('exchangerId', user.exchanger_id);
    localStorage.setItem('token', token);
    localStorage.setItem('email', email);
    setUser(user);
    setGuest(false);
  }

  async function getUserExchangerId(email) {
    try {
      const url = `${BASE_URL}/login`;
      const config = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      };
      let api = await fetch(url, config);
      let response = await api.json();
      const userData = await Api.get(response.exchanger_id + '/exchangers');
      if (userData.status !== 200) {
        return false;
      }
      return userData.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  function logout() {
    localStorage.removeItem('exchangerId');
    localStorage.removeItem('token');
    setUser({});
    setGuest(true);
    window.location.href = '/login';
  }
  useEffect(() => {
    const verifyUser = async () => {
      const email = localStorage.getItem('email');
      const token = localStorage.getItem('token');
      if (email && token) {
        setGuest(false);
        const user = await getUserExchangerId(email);
        setUser(user);
      }
    };
    verifyUser();
  }, []);
  return (
    <AuthContext.Provider value={{ guest, user, setUser, setAuth, logout }}>
      {props.children}
    </AuthContext.Provider>
  );
};
export default Auth;
