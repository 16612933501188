import React, { Component } from 'react';
import './privacy.css';
import Header from '../../components/Header'
import Logo from '../../assets/img/logo_pense2.png';

class Privacy extends Component { 
    render(){
        document.title = 'Privacidade'
        return(
            <div>
                <div className="header sticky-top shadow-lg d-md-flex justify-content-center">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <div className="mb-2 d-md-flex justify-content-center  mr-md-5">
                            <img className="imgSet mr-5 mr-md-0 p-2" src={Logo} alt="logo PenseTroque"/>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse nav" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                <a className="nav-link linkSet ml-3 mr-3" href="/">Home<span className="sr-only">(current)</span></a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/blog">Blog</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/team">Equipe</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/faq">FAQ</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/partners">Parceiros</a>
                                <a className="nav-link btnLogin btn  ml-3 mr-3 mb-3 mb-md-0" href='/login'>Login</a>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="container divSettings">
                <p><strong>Pol&iacute;tica de Privacidade e Seguran&ccedil;a de Dados</strong></p>

<p>&nbsp;</p>

<p><strong>1)&nbsp; &nbsp; &nbsp; COMPROMETIMENTO</strong></p>

<p>Nos preocupamos muito com sua privacidade, assim, o objetivo desse documento &eacute; lhe mostrar como tratamos, armazenamos, usamos e compartilhamos os dados coletados e como &eacute; a sua participa&ccedil;&atilde;o nesse processo. Para que fique clara a nossa Pol&iacute;tica de Privacidade, iremos disponibiliz&aacute;-la em nosso site e plataforma digital (aplicativo), onde solicitaremos informa&ccedil;&otilde;es pessoais necess&aacute;rias.</p>

<p>Pedimos que voc&ecirc; leia esta Pol&iacute;tica de Privacidade por completo e com cuidado antes de usar nossos servi&ccedil;os ou nos fornecer qualquer informa&ccedil;&atilde;o.</p>

<p>&nbsp;</p>

<p><strong>2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong><strong>ESCOPO</strong></p>

<p>Esta Pol&iacute;tica de Privacidade e Seguran&ccedil;a de Dados apresenta as regras de prote&ccedil;&atilde;o de dados aplic&aacute;veis para o uso da <strong>PENSETROQUE</strong>, disponibilizada pela NEOQUANTUM BUSINESS DESIGN SERVI&Ccedil;OS DE INFORM&Aacute;TICA LTDA, devidamente inscrita sob o CNPJ N&ordm; 33.192.005/0001-12.</p>

<p>Nossa Pol&iacute;tica de Privacidade est&aacute; diretamente relacionada aos nossos Termos e Condi&ccedil;&otilde;es Gerais de Uso (&ldquo;Termos&rdquo;). As express&otilde;es aqui dispostas em mai&uacute;sculo est&atilde;o definidas nos Termos ou nesta Pol&iacute;tica de Privacidade.</p>

<p>&nbsp;</p>

<p><strong>3)&nbsp; &nbsp; &nbsp; &nbsp;DEFINI&Ccedil;&Otilde;ES</strong></p>

<p>Sem preju&iacute;zo de outras defini&ccedil;&otilde;es constantes nesta Pol&iacute;tica, os seguintes termos ter&atilde;o os seguintes significados:</p>

<ul>
	<li>Internauta: todo aquele que, de alguma forma, acessa a <strong>PENSETROQUE VE&Iacute;CULOS</strong>, sendo cliente ou n&atilde;o.</li>
	<li>Cliente: todo aquele que utiliza os produtos e/ou servi&ccedil;os oferecidos pelo aplicativo e site <strong>PENSETROQUE</strong>, pagos ou n&atilde;o.</li>
	<li>Usu&aacute;rio(s): &ldquo;voc&ecirc;, &ldquo;seu&rdquo;, &ldquo;seus&rdquo;, sua&rdquo;. Todo aquele que se cadastrar nestes aplicativos e sites e receber uma identifica&ccedil;&atilde;o individual e exclusiva.</li>
	<li>Dados pessoais: qualquer informa&ccedil;&atilde;o relativa a uma pessoa identificada ou identific&aacute;vel, incluindo seu nome completo ou nome comercial, endere&ccedil;o f&iacute;sico e eletr&ocirc;nico, n&uacute;mero de telefone, RG, CPF ou CNPJ e dados do ve&iacute;culo.</li>
	<li><strong>Tratamento:</strong> toda opera&ccedil;&atilde;o realizada com os Dados Pessoais, como as que se referem a coleta, produ&ccedil;&atilde;o, recep&ccedil;&atilde;o, classifica&ccedil;&atilde;o, utiliza&ccedil;&atilde;o, acesso, reprodu&ccedil;&atilde;o, transmiss&atilde;o, distribui&ccedil;&atilde;o, processamento, arquivamento, armazenamento, elimina&ccedil;&atilde;o, avalia&ccedil;&atilde;o ou controle da informa&ccedil;&atilde;o, modifica&ccedil;&atilde;o, comunica&ccedil;&atilde;o, transfer&ecirc;ncia, difus&atilde;o ou extra&ccedil;&atilde;o.</li>
	<li>Criptografia: nome dado ao processo de codifica&ccedil;&atilde;o de informa&ccedil;&otilde;es. As informa&ccedil;&otilde;es s&atilde;o codificadas (embaralhadas) na origem e decodificadas no destino, dificultando, dessa forma, que sejam decifradas durante o tr&aacute;fego na internet.</li>
	<li>Cookies: s&atilde;o pequenos arquivos alocados em seu computador. Existem alguns motivos pelos quais usamos cookies. Com os cookies, podemos entender melhor como os usu&aacute;rios interagem com os sites, ajudando-nos a focar em produtos e servi&ccedil;os que sejam mais populares. Os cookies permitem ajustar o site para oferecer os servi&ccedil;os que correspondam com seus interesses e necessidades. Portanto, t&ecirc;m como finalidade identificar o computador ou telefone celular, personalizar os acessos e obter dados (de acesso), como p&aacute;ginas navegadas ou links clicados. Um cookie &eacute; atribu&iacute;do individualmente a cada computador, n&atilde;o podendo ser usado para executar programas, tampouco infectar computadores com v&iacute;rus, trojans, etc. e podem ser lidos apenas pelo servidor que o enviou.</li>
	<li>Plataforma Digital: site ou aplicativo da <strong>PENSETROQUE</strong>.</li>
	<li><strong>Canais de Atendimento:</strong> telefone, e-mail, Facebook Messenger ou chat na Plataforma Digital, todos indicados na aba &ldquo;Fale Conosco&rdquo; da nossa Plataforma Digital;</li>
	<li><strong>PenseTroque: </strong>&ldquo;n&oacute;s&rdquo;, &ldquo;nosso&rdquo;,&rdquo;nossos&rdquo;, &rdquo;nossa&rdquo;, &ldquo;nossas&rdquo;.</li>
</ul>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p><strong>4)&nbsp; &nbsp; &nbsp; &nbsp; CONSENTIMENTO </strong></p>

<p>AO UTILIZAR A PLATAFORMA<strong> PENSETROQUE</strong>, VOC&Ecirc; DECLARA CONSENTIR COM ESTA POL&Iacute;TICA DE PRIVACIDADE, SENDO ESTA UMA MANIFESTA&Ccedil;&Atilde;O LIVRE, INFORMADA E INEQU&Iacute;VOCA PELA QUAL VOC&Ecirc; CONCORDA COM O TRATAMENTO DE SEUS DADOS PESSOAIS, COM A FINALIDADE DE VIABILIZA&Ccedil;&Atilde;O DA TROCA, APROXIMANDO OS USU&Aacute;RIOS PARA UMA POSSIVEL NEGOCIA&Ccedil;&Atilde;O DE VE&Iacute;CULO AUTOMOTOR POR MEIO DAS FUNCIONALIDADES DA NOSSA PLATAFORMA DIGITAL. DESTA FORMA, COLETAREMOS APENAS INFORMA&Ccedil;&Otilde;ES DE IDENTIFIA&Ccedil;&Atilde;O INDIVIDUAL NECESS&Aacute;RIAS &Agrave; VIABILIZA&Ccedil;&Atilde;O DE SEUS NEG&Oacute;CIOS E AO FORNECIMENTO DE PRODUTOS E/OU SERVI&Ccedil;OS SOLICITADOS POR CLIENTES/USU&Aacute;RIOS.</p>

<p>O envio de material informativo ou promocional ao correio eletr&ocirc;nico dos internautas cadastrados na plataforma digital se dar&aacute; por meio de autoriza&ccedil;&atilde;o pr&eacute;via e poder&aacute; ser cancelada a qualquer momento.</p>

<p>As mensagens enviadas em formato HTML podem conter c&oacute;digos que permitem personalizar mensagens de acordo com as prefer&ecirc;ncias individuais do cliente/usu&aacute;rio registrado na PENSETROQUE e elaborar relat&oacute;rios sobre a visualiza&ccedil;&atilde;o das mensagens, sobre o n&uacute;mero de vezes em que o e-mail foi aberto e o n&uacute;mero de cliques feitos na mensagem. Estas informa&ccedil;&otilde;es ser&atilde;o utilizadas de forma gen&eacute;rica e agregada para elaborar relat&oacute;rios sobre o envio de mensagens. Tais relat&oacute;rios poder&atilde;o ser repassados aos anunciantes, como indicadores estat&iacute;sticos da efetividade das campanhas, sem revelar informa&ccedil;&otilde;es pessoais dos clientes/Usu&aacute;rios.</p>

<p>Em nenhuma hip&oacute;tese a PENSETROQUE ir&aacute; divulgar esses dados de forma individualizada, ou seja, n&atilde;o haver&aacute; identifica&ccedil;&atilde;o do cliente/usu&aacute;rio pessoa f&iacute;sica ou de seus h&aacute;bitos de acesso e leitura de e-mails. Ainda que coletivamente, os dados s&oacute; podem ser utilizados para os fins previstos nesta Pol&iacute;tica de Privacidade.</p>

<p>OS COOKIES SER&Atilde;O CONSENTIDOS DE MANEIRA INDIVIDUAL E SER&Atilde;O UTILIZADOS APENAS PARA CONTROLAR A AUDI&Ecirc;NCIA E A NAVEGA&Ccedil;&Atilde;O EM NOSSA PLATAFORMA DIGITAL. Os cookies n&atilde;o ser&atilde;o usados para controlar ou utilizar os dados pessoais do usu&aacute;rio, exceto quando este desrespeitar alguma regra de seguran&ccedil;a ou exercer alguma atividade nociva ao funcionamento da plataforma PENSETROQUE, como, por exemplo, execu&ccedil;&otilde;es de &ldquo;hacker&rdquo;.</p>

<p>SE IDENTIFICADA A PR&Aacute;TICA DE SPAM HAVER&Aacute; O BLOQUEIO IMEDIATO DE FORNECIMENTO DO SERVI&Ccedil;O PARA USU&Aacute;RIOS, TANTO PARA PESSOA F&Iacute;SICA QUANTO PARA PESSOA JUR&Iacute;DICA.</p>

<p>SEU CONSENTIMENTO &Eacute; CONDI&Ccedil;&Atilde;O PARA O FORNECIMENTO DO NOSSO SERVI&Ccedil;O EM SUA INTEGRALIDADE.</p>

<p>PARA FAZER USO DA PLATAFORMA DIGITAL, VOC&Ecirc; DEVER&Aacute; TER NO M&Iacute;NIMO 18 ANOS DE IDADE, N&Atilde;O SENDO PERMITIDO EM NENHUMA HIP&Oacute;TESE O USO POR CRIAN&Ccedil;AS E ADOLESCENTES.</p>

<p>Em caso de altera&ccedil;&atilde;o da finalidade para Tratamento dos Dados que n&atilde;o seja compat&iacute;vel com o consentimento aqui proferido, e para os quais a legisla&ccedil;&atilde;o exige seu consentimento, informaremos aos Usu&aacute;rios o teor das mudan&ccedil;as de forma espec&iacute;fica e solicitaremos uma nova autoriza&ccedil;&atilde;o. Em caso de discord&acirc;ncia ou livre vontade, seu consentimento poder&aacute; ser revogado a qualquer momento mediante manifesta&ccedil;&atilde;o expressa atrav&eacute;s de contato conosco por meio dos nossos Canais de Atendimento, de forma gratuita.</p>

<p>Importante ressaltar que a <strong>PENSETROQUE</strong> atua apenas em territ&oacute;rio nacional.</p>

<p>&nbsp;</p>

<p><strong>5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong><strong>COMO COLETAMOS INFORMA&Ccedil;&Otilde;ES</strong></p>

<p>As informa&ccedil;&otilde;es s&atilde;o coletadas por meio da nossa plataforma digital e dos dados fornecidos voluntariamente. Dependendo da a&ccedil;&atilde;o realizada, ao se cadastrar, navegar, adquirir ou solicitar produtos e/ou servi&ccedil;os da <strong>PENSETROQUE</strong>, ser&atilde;o requisitadas informa&ccedil;&otilde;es sobre:</p>

<ul>
	<li>Identifica&ccedil;&atilde;o individual do cliente (tais como informa&ccedil;&otilde;es pessoais, de contato e de identifica&ccedil;&atilde;o);</li>
	<li>Ve&iacute;culo(s) ofertado(s) nos aplicativos e sites;</li>
	<li>Ve&iacute;culo(s) pesquisado(s) nos aplicativos e sites;</li>
	<li>Ve&iacute;culo(s) de propriedade do internauta;</li>
	<li>Aquisi&ccedil;&atilde;o e forma de pagamento do produto ou servi&ccedil;o solicitado nos aplicativos e sites;</li>
	<li>Prefer&ecirc;ncias do cliente;</li>
	<li>Opini&otilde;es do internauta;</li>
	<li>Acesso do internauta (exemplo: data e hor&aacute;rio de realiza&ccedil;&atilde;o do acesso);</li>
	<li>Perfil de acesso do internauta (exemplo: links clicados, p&aacute;ginas visitadas);</li>
	<li>N&uacute;mero do RENAVAM do ve&iacute;culo que se pretende trocar. Aqui a <strong>PENSETROQUE</strong> divulgar&aacute; apenas o status da documenta&ccedil;&atilde;o na plataforma digital. As informa&ccedil;&otilde;es detalhadas s&oacute; ser&atilde;o disponibilizadas ap&oacute;s a efetiva&ccedil;&atilde;o do neg&oacute;cio.</li>
</ul>

<p>&nbsp;</p>

<p>As permiss&otilde;es de acesso solicitadas aos Usu&aacute;rios trocadores, s&atilde;o as seguintes:</p>

<p>a) Internet;</p>

<p>b) C&acirc;mera (para tirar fotos dos ve&iacute;culos);</p>

<p>c) Acesso de leitura ao armazenamento, sem pode modificar ou deletar conte&uacute;do (carregar fotos de ve&iacute;culos e perfil);</p>

<p>d) Geolocaliza&ccedil;&atilde;o (a ser implementado futuramente para localiza&ccedil;&atilde;o de trocadores a um certo raio de dist&acirc;ncia).</p>

<p>Tamb&eacute;m podemos coletar informa&ccedil;&otilde;es de e sobre voc&ecirc; por outros meios, como por contato com nossa equipe de suporte ao cliente, de resultados de suas respostas a uma pesquisa e de intera&ccedil;&otilde;es com outras empresas (sujeito &agrave;s pol&iacute;ticas de privacidade dessas empresas e leis aplic&aacute;veis), que estejam dispon&iacute;veis publicamente.</p>

<p>Voc&ecirc; tamb&eacute;m pode escolher nos conceder acesso a outras informa&ccedil;&otilde;es pessoais armazenadas por terceiros, tais como sites de m&iacute;dias sociais (ex.: Facebook e Twitter). As informa&ccedil;&otilde;es que recebemos variam de um site para outro, sendo controladas por esses sites. Ao associar uma conta gerida por um terceiro &agrave; sua conta na <strong>PENSETROQUE</strong> e autorizar a <strong>PENSETROQUE</strong> a acessar essas informa&ccedil;&otilde;es, voc&ecirc; concorda que a <strong>PENSETROQUE</strong> pode coletar, armazenar e usar tais informa&ccedil;&otilde;es de acordo com esta Pol&iacute;tica de Privacidade.</p>

<p>N&Atilde;O H&Aacute; COMUNICA&Ccedil;&Atilde;O ENTRE OS USU&Aacute;RIOS DENTRO DA PLATAFORMA.</p>

<p>Em rela&ccedil;&atilde;o &agrave;s informa&ccedil;&otilde;es decorrentes de comunica&ccedil;&atilde;o entre os Usu&aacute;rios e a <strong>PENSETROQUE</strong>, esclarecemos que para fins de qualidade, treinamento e para sua pr&oacute;pria seguran&ccedil;a, a <strong>PENSETROQUE, sendo uma das interlocutoras,</strong> pode monitorar ou gravar as conversas telef&ocirc;nicas com voc&ecirc; ou qualquer outra pessoa agindo em seu nome.</p>

<p>Al&eacute;m do processo de cadastro e uso das nossas Funcionalidades, nossa Plataforma Digital tamb&eacute;m obt&eacute;m informa&ccedil;&otilde;es n&atilde;o-pessoais e de forma autom&aacute;tica que permitem a mensura&ccedil;&atilde;o de &iacute;ndices de audi&ecirc;ncia e de desempenho da Plataforma Digital com o objetivo de ampliar a qualidade da informa&ccedil;&atilde;o e dos servi&ccedil;os por n&oacute;s oferecidos.</p>

<p>ASSIM, QUANDO VOC&Ecirc; USA A <strong>PENSETROQUE</strong>, COLETAMOS INFORMA&Ccedil;&Otilde;ES ENVIADAS POR SEU COMPUTADOR, CELULAR OU OUTRO DISPOSITIVO DE ACESSO. AS INFORMA&Ccedil;&Otilde;ES ENVIADAS &Agrave; <strong>PENSETROQUE</strong> INCLUEM, SEM SE LIMITAR A: DADOS SOBRE AS P&Aacute;GINAS ACESSADAS, ENDERE&Ccedil;O IP, OS C&Oacute;DIGOS DOS PROVEDORES QUE FORNECERAM O ACESSO, DATA E HORA DAS VISITAS &Agrave; PLATAFORMA DIGITAL, SISTEMAS OPERACIONAIS E BROWSERS UTILIZADOS, ID OU IDENTIFICADOR DO DISPOSITIVO, TIPO DE DISPOSITIVO, INFORMA&Ccedil;&Atilde;O DE GEOLOCALIZA&Ccedil;&Atilde;O, INFORMA&Ccedil;&Atilde;O DO COMPUTADOR E CONEX&Atilde;O, INFORMA&Ccedil;&Atilde;O DA REDE M&Oacute;VEL, ESTAT&Iacute;STICAS SOBRE P&Aacute;GINAS VISITADAS, TR&Aacute;FEGO DE E PARA OS SITES, URL DE REFER&Ecirc;NCIA, DADOS DE PUBLICIDADE, DADOS DE LOG DA WEB PADR&Atilde;O E OUTRAS INFORMA&Ccedil;&Otilde;ES.</p>

<p>REFERIDAS INFORMA&Ccedil;&Otilde;ES S&Atilde;O CONHECIDAS COMO DADOS N&Aacute;UTICOS (OBTIDOS POR MEIO DE COOKIES, LOG FILES, SERVER LOGS E CLICKSTREAM DATA), E S&Atilde;O UTILIZADAS NO GERENCIAMENTO DO SISTEMA PARA MELHORAR O CONTE&Uacute;DO DA PLATAFORMA DIGITAL, REALIZAR PESQUISAS DE MERCADO E COMUNICAR INFORMA&Ccedil;&Otilde;ES AOS VISITANTES. TAIS DADOS NOS PERMITEM VERIFICAR, POR EXEMPLO, AS P&Aacute;GINAS OU SESS&Otilde;ES MAIS UTILIZADAS POR NOSSOS USU&Aacute;RIOS, IDENTIFICAR ARQUIVOS OU P&Aacute;GINAS DEFEITUOSAS, ENTRE OUTROS.</p>

<p>OS DADOS DESCRITOS ACIMA TAMB&Eacute;M SERVIR&Atilde;O PARA A GARANTIA DE PREVEN&Ccedil;&Atilde;O &Agrave; FRAUDE E &Agrave; SEGURAN&Ccedil;A DO USU&Aacute;RIO.</p>

<p>Os itens que ser&atilde;o exclu&iacute;dos por motivo de problema dos pacotes/bibliotecas de desenvolvimento s&atilde;o:</p>

<p>a) &ldquo;Modificar ou apagar conte&uacute;dos&rdquo;;</p>

<p>b) &ldquo;Ler status e identidade do telefone&rdquo;.</p>

<p>&nbsp;</p>

<p><strong>6)&nbsp; &nbsp; &nbsp; &nbsp; IDENTIFICA&Ccedil;&Atilde;O DO USU&Aacute;RIO</strong></p>

<p>Ao se cadastrar nos aplicativos e sites <strong>PENSETROQUE</strong> para aquisi&ccedil;&atilde;o de produtos ou servi&ccedil;os (pagos ou n&atilde;o) oferecidos nos aplicativos e sites, cada usu&aacute;rio recebe uma identifica&ccedil;&atilde;o &uacute;nica, identifica&ccedil;&atilde;o esta que passa a ser requerida e autenticada nos demais acessos aos aplicativos e sites. Essa identifica&ccedil;&atilde;o, para os fins de direito, serve como assinatura de concord&acirc;ncia com qualquer proposta realizada nesses aplicativos e sites.</p>

<p>A identifica&ccedil;&atilde;o de usu&aacute;rio &eacute; exclusiva, intransfer&iacute;vel e criptografada (em todas as p&aacute;ginas de coleta de dados) para ser transmitida ao servidor da <strong>PENSETROQUE</strong>, sendo a senha armazenada em banco de dados na sua forma criptografada, de maneira irrevers&iacute;vel.</p>

<p>A <strong>PENSETROQUE</strong> poder&aacute; confirmar os dados pessoais informados pelo cliente/usu&aacute;rio/internauta, consultando &oacute;rg&atilde;os p&uacute;blicos, empresas especializadas, cadastros restritivos ou centrais de risco. As informa&ccedil;&otilde;es que a <strong>PENSETROQUE</strong> obtiver destas entidades ser&atilde;o tratadas de forma confidencial.</p>

<p>Sem preju&iacute;zo do disposto anteriormente, o cliente/usu&aacute;rio garante e responde pela veracidade, exatid&atilde;o, vig&ecirc;ncia e autenticidade dos dados pessoais, e se compromete a mant&ecirc;-los devidamente atualizados. Por consequ&ecirc;ncia, a <strong>PENSETROQUE</strong> n&atilde;o possui qualquer responsabilidade no caso de inser&ccedil;&atilde;o de dados falsos ou inexatid&atilde;o dos dados pessoais introduzidos por outros clientes/usu&aacute;rios/internautas.</p>

<p>&nbsp;</p>

<p><strong>7)&nbsp; &nbsp; &nbsp; &nbsp; SEGURAN&Ccedil;A DAS INFORMA&Ccedil;&Otilde;ES CONFIDENCIAIS</strong></p>

<p>O acesso &agrave;s informa&ccedil;&otilde;es pessoais coletadas e armazenadas pela <strong>PENSETROQUE</strong> &eacute; restrito aos profissionais autorizados ao uso direto dessas informa&ccedil;&otilde;es, e necess&aacute;rio &agrave; presta&ccedil;&atilde;o de seus servi&ccedil;os, sendo limitado o uso para outras tarefas. &Eacute; exigido, tamb&eacute;m, de toda organiza&ccedil;&atilde;o ou indiv&iacute;duo contratado para a presta&ccedil;&atilde;o de servi&ccedil;os de apoio, que sejam cumpridos nossos padr&otilde;es de privacidade, as Pol&iacute;ticas de Seguran&ccedil;a da Informa&ccedil;&atilde;o e o C&oacute;digo de &Eacute;tica adotados pela PENSETROQUE, ent&atilde;o vigentes.</p>

<p>A <strong>PENSETROQUE</strong> trabalha exaustivamente para assegurar que as informa&ccedil;&otilde;es divulgadas para os clientes sejam verdadeiras e &iacute;ntegras, contando com controles apurados de monitoramento das informa&ccedil;&otilde;es fornecidas. Sua participa&ccedil;&atilde;o no processo &eacute; revisar as informa&ccedil;&otilde;es, valores e informativos, comunicando-nos qualquer discrep&acirc;ncia nas informa&ccedil;&otilde;es fornecidas.</p>

<p>Apesar de toda a medida de seguran&ccedil;a avan&ccedil;ada comprovadamente aplicada, a PENSETROQUE n&atilde;o responder&aacute; por preju&iacute;zos que possam advir do vazamento das informa&ccedil;&otilde;es pessoais por viola&ccedil;&atilde;o ou quebra das barreiras de seguran&ccedil;a de internet por culpa exclusiva de terceiros como &quot;hackers&quot; ou &quot;crackers&quot;, nos moldes do artigo 42 a 45 da LGPD.</p>

<p>Al&eacute;m dos casos acima citados, havendo a necessidade ou interesse em repassar a terceiros dados de identifica&ccedil;&atilde;o individual dos clientes/usu&aacute;rios/internautas, a <strong>PENSETROQUE</strong> lhes solicitar&aacute; autoriza&ccedil;&atilde;o pr&eacute;via. Informa&ccedil;&otilde;es que n&atilde;o sejam de identifica&ccedil;&atilde;o individual (como demogr&aacute;ficas, por exemplo), poder&atilde;o ser repassadas a anunciantes, fornecedores, patrocinadores e parceiros, com o objetivo de melhorar a qualidade dos produtos e servi&ccedil;os oferecidos pela <strong>PENSETROQUE</strong>.</p>

<p>Aos terceiros que, porventura receberem da <strong>PENSETROQUE</strong> informa&ccedil;&otilde;es de qualquer natureza, sobre os internautas que acessam seus aplicativos e sites, cabe igualmente, a responsabilidade de zelar pelo sigilo e seguran&ccedil;a de referidas informa&ccedil;&otilde;es.</p>

<p>A <strong>PENSETROQUE </strong>se empenha expressivamente para prover seguran&ccedil;a e sigilo das informa&ccedil;&otilde;es que capta. Contudo, para que as medidas adotadas tenham efic&aacute;cia, faz-se necess&aacute;rio que cada cliente/usu&aacute;rio/internauta tamb&eacute;m tenha atitude respons&aacute;vel, sendo cuidadoso com os dados de sua identifica&ccedil;&atilde;o individual sempre que acessar a internet, informando-os somente em opera&ccedil;&otilde;es em que exista a prote&ccedil;&atilde;o de dados, &nbsp;&nbsp;nunca divulgando sua identifica&ccedil;&atilde;o de usu&aacute;rio e sempre desconectando a conta da <strong>PENSETROQUE VE&Iacute;CULOS</strong> t&atilde;o logo deixe de acess&aacute;-la, principalmente se dividir o computador com outra(s) pessoa(s).</p>

<p>A <strong>PENSETROQUE</strong> assegura que as informa&ccedil;&otilde;es (textos, imagens, sons e/ou aplicativos) contidas nos seus sites est&atilde;o de acordo com a legisla&ccedil;&atilde;o e normativos que regulam os direitos autorais, marcas e patentes, n&atilde;o sendo permitidas modifica&ccedil;&otilde;es, c&oacute;pias, reprodu&ccedil;&otilde;es ou quaisquer outras formas de utiliza&ccedil;&atilde;o para fins comerciais sem o consentimento pr&eacute;vio e expresso da <strong>PENSETROQUE</strong>.</p>

<p>A <strong>PENSETROQUE</strong> n&atilde;o se responsabiliza por eventuais danos e/ou problemas decorrentes da demora, interrup&ccedil;&atilde;o ou bloqueio nas transmiss&otilde;es de dados ocorridos na Internet.</p>

<p>&nbsp;</p>

<p><strong>8)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; EXTENS&Atilde;O DOS EFEITOS</strong></p>

<p>Os termos da Pol&iacute;tica de Privacidade e Seguran&ccedil;a de Dados aqui expostos aplicar-se-&atilde;o exclusivamente &agrave;s informa&ccedil;&otilde;es pessoais, conforme acima definido, que venham a ser disponibilizadas &agrave; <strong>PENSETROQUE</strong>, pelo cliente/usu&aacute;rio/internauta para a utiliza&ccedil;&atilde;o de seus produtos e servi&ccedil;os. Por consequ&ecirc;ncia, a Pol&iacute;tica de Privacidade e Seguran&ccedil;a de Dados aqui exposta n&atilde;o ser&aacute; aplic&aacute;vel a qualquer outro servi&ccedil;o que n&atilde;o os disponibilizados pela <strong>PENSETROQUE</strong>, inclu&iacute;dos aqueles sites que estejam de alguma forma vinculados ao site da <strong>PENSETROQUE</strong>, atrav&eacute;s de links ou quaisquer outros recursos tecnol&oacute;gicos, e, ainda, a quaisquer outros sites que, de qualquer forma, venham a ser conhecidos ou utilizados atrav&eacute;s da <strong>PENSETROQUE</strong>.</p>

<p><br />
Nesse sentido, a <strong>PENSETROQUE </strong>alerta aos usu&aacute;rios que os referidos sites podem conter pol&iacute;tica de privacidade diversa da adotada pela <strong>PENSETROQUE</strong> ou podem at&eacute; mesmo n&atilde;o adotar qualquer pol&iacute;tica nesse sentido, n&atilde;o se responsabilizando, a <strong>PENSETROQUE</strong>, por qualquer viola&ccedil;&atilde;o aos direitos de privacidade dos usu&aacute;rios que venham a ser violados pelos referidos sites.</p>

<p><strong>9)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DOS DADOS DE PAGAMENTO</strong></p>

<p>As transa&ccedil;&otilde;es efetuadas para escolha da forma e efetiva&ccedil;&atilde;o do pagamento, ser&atilde;o feitas por empresa especializada em intermedia&ccedil;&atilde;o de pagamentos que possui regramento de seguran&ccedil;a pr&oacute;prio com r&iacute;gidos padr&otilde;es de sigilo e integridade, bem como controles de acesso f&iacute;sico e l&oacute;gico. Portanto, estes dados s&atilde;o informados diretamente para a institui&ccedil;&atilde;o financeira intermediadora de pagamentos e n&atilde;o s&atilde;o armazenados ou utilizados pela PENSETROQUE para qualquer outro fim. A INTERMEDIADORA FINANCEIRA SER&Aacute; RESPONS&Aacute;VEL POR CONTROLAR E OPERAR OS DADOS DE PAGAMENTO.</p>

<p>OS DADOS QUE SER&Atilde;O COLETADOS PARA FINS DE PAGAMENTO S&Atilde;O: N&Uacute;MERO DE CART&Atilde;O DE CR&Eacute;DITO OU D&Eacute;BITO, PREFER&Ecirc;NCIAS E PADR&Otilde;ES DE ACESSO (INCLUINDO TODO ENDERE&Ccedil;O OU N&Uacute;MERO DE IDENTIFICA&Ccedil;&Atilde;O DE UM TERMINAL UTILIZADO PARA CONEX&Atilde;O A UMA REDE DE COMPUTADORES) COM A FINALIDADE DE SE PREVENIR FRAUDES, NOS MOLDES DO ART. 6&ordm; V, VII E VIII DA LGPD.</p>

<p>Todas as informa&ccedil;&otilde;es necess&aacute;rias &agrave; realiza&ccedil;&atilde;o do pagamento s&atilde;o criptografadas. O &iacute;cone &quot;Cadeado Fechado&quot;, localizado na barra de status (parte inferior do monitor), &eacute; o indicativo de que as informa&ccedil;&otilde;es transacionadas ser&atilde;o criptografadas.</p>

<h3>&nbsp;</h3>

<p><strong>10)&nbsp;&nbsp;&nbsp; COMO UTILIZAMOS E TRATAMOS OS DADOS</strong></p>

<p>As informa&ccedil;&otilde;es capturadas por meio da PENSETROQUE s&atilde;o utilizadas com a finalidade de:</p>

<ul>
	<li>Apura&ccedil;&atilde;o estat&iacute;stica geral;</li>
	<li>Maior e melhor interatividade dos aplicativos e sites ao usu&aacute;rio;</li>
	<li>Aperfei&ccedil;oar a usabilidade e a experi&ecirc;ncia interativa na utiliza&ccedil;&atilde;o dos aplicativos e sites;</li>
	<li>Possibilidade de ofertas e/ou informa&ccedil;&otilde;es mais assertivas, relevantes &agrave;s necessidades e/ou interesses do cliente/usu&aacute;rio/internauta;</li>
	<li>Possibilidade de maior efici&ecirc;ncia em rela&ccedil;&atilde;o &agrave; frequ&ecirc;ncia e continuidade de comunica&ccedil;&atilde;o com o usu&aacute;rio;</li>
	<li>Maior e melhor experi&ecirc;ncia do consumidor no nossos aplicativos e sites e/ou em sua navega&ccedil;&atilde;o pela internet;</li>
	<li>Responder &agrave;s d&uacute;vidas e solicita&ccedil;&otilde;es dos usu&aacute;rios;</li>
	<li>Realizar pesquisas de comunica&ccedil;&atilde;o e marketing de relacionamento;</li>
	<li>Divulgar altera&ccedil;&otilde;es, inova&ccedil;&otilde;es ou promo&ccedil;&otilde;es sobre os produtos e servi&ccedil;os da PENSETROQUE.</li>
	<li>Permitir aos internautas navegar ou realizar as opera&ccedil;&otilde;es disponibilizadas nos aplicativos e sites;</li>
	<li>Viabilizar o fornecimento de produtos ou servi&ccedil;os solicitados nos aplicativos e sites;</li>
	<li>Identificar o perfil, desejos ou necessidades dos internautas, a fim de aprimorar os produtos e/ou servi&ccedil;os oferecidos pela empresa;</li>
	<li>Enviar informativos sobre produtos ou servi&ccedil;os aos seus clientes.</li>
</ul>

<p>&nbsp;</p>

<p><strong>11)&nbsp; &nbsp; &nbsp;DIRETRIZES DO TRATAMENTO DE DADOS</strong></p>

<p>O Tratamento de Dados Pessoais somente ser&aacute; realizado nas seguintes hip&oacute;teses:</p>

<ul>
	<li>mediante fornecimento de consentimento pelo titular dos dados, por meio do aceite da presente Pol&iacute;tica de Privacidade; e</li>
	<li>para o cumprimento de obriga&ccedil;&atilde;o legal ou regulat&oacute;ria pelo controlador.</li>
</ul>

<p>Durante o tratamento de Dados Pessoais e comunica&ccedil;&otilde;es privadas, a <strong>PENSETROQUE </strong>observar&aacute; as seguintes diretrizes:</p>

<ul>
	<li>controle estrito sobre o acesso aos Dados Pessoais mediante a defini&ccedil;&atilde;o de responsabilidades e privil&eacute;gios de acesso exclusivo;</li>
	<li>previs&atilde;o de instrumentos de autentica&ccedil;&atilde;o de acesso aos registros, usando sistema de autentica&ccedil;&atilde;o dupla para assegurar a individualiza&ccedil;&atilde;o do respons&aacute;vel pelo tratamento dos registros;</li>
	<li>elabora&ccedil;&atilde;o de invent&aacute;rio detalhado dos acessos aos registros de acesso &agrave; Plataforma Digital, contendo o momento, a dura&ccedil;&atilde;o, a identidade do respons&aacute;vel pelo acesso e o arquivo acessado;</li>
	<li>utiliza&ccedil;&atilde;o de solu&ccedil;&otilde;es de organiza&ccedil;&atilde;o e gest&atilde;o dos registros por meio de t&eacute;cnicas que garantam a inviolabilidade dos dados, como encripta&ccedil;&atilde;o ou medidas de prote&ccedil;&atilde;o equivalentes; e</li>
	<li>manuten&ccedil;&atilde;o dos dados em formato interoper&aacute;vel e estruturado, a fim de facilitar o acesso determinado pela legisla&ccedil;&atilde;o ou por autoridade oficial.&nbsp;</li>
</ul>

<p>Considerando nosso compromisso com a transpar&ecirc;ncia e o livre acesso, armazenamos seus Dados em formato que favorece o exerc&iacute;cio do seu direito de acesso. Sempre que desejarem mais informa&ccedil;&otilde;es sobre o Tratamento dos seus Dados, os Usu&aacute;rios poder&atilde;o solicit&aacute;-los e os disponibilizaremos com as seguintes caracter&iacute;sticas:</p>

<ul>
	<li>finalidade espec&iacute;fica do Tratamento;</li>
	<li>forma e dura&ccedil;&atilde;o do Tratamento, observados os segredos comercial e industrial;</li>
	<li>informa&ccedil;&otilde;es acerca do uso compartilhado de dados pela <strong>PENSETROQUE</strong> e a finalidade;</li>
	<li>responsabilidades dos agentes que realizar&atilde;o o tratamento;</li>
	<li>direitos dos Usu&aacute;rios; e</li>
	<li>informa&ccedil;&otilde;es de contato da <strong>PENSETROQUE</strong>.</li>
</ul>

<p>Al&eacute;m de aprofundamento sobre a forma de Tratamento, os Usu&aacute;rios tamb&eacute;m podem requisitar, de forma expressa e gratuita:</p>

<ul>
	<li>confirma&ccedil;&atilde;o de exist&ecirc;ncia de tratamento;</li>
	<li>acesso aos Dados Pessoais;</li>
	<li>corre&ccedil;&atilde;o de dados incompletos, inexatos ou desatualizados;</li>
	<li>anonimiza&ccedil;&atilde;o, bloqueio ou elimina&ccedil;&atilde;o de Dados Pessoais que entendam desnecess&aacute;rios, excessivos ou tratados em desconformidade com o disposto na legisla&ccedil;&atilde;o;</li>
	<li>portabilidade dos Dados Pessoais a outro fornecedor de servi&ccedil;o ou produto, mediante requisi&ccedil;&atilde;o expressa e observados os segredos comercial e industrial, n&atilde;o estando aqui inclu&iacute;dos dados que j&aacute; tenham sido anonimizados;</li>
	<li>elimina&ccedil;&atilde;o dos Dados Pessoais tratados com o consentimento dos Usu&aacute;rios, exceto nas hip&oacute;teses que aqui disp&otilde;e o contr&aacute;rio;</li>
	<li>informa&ccedil;&atilde;o das entidades p&uacute;blicas e privadas com as quais a <strong>PENSETROQUE </strong>realizou uso compartilhado de dados;</li>
	<li>informa&ccedil;&atilde;o sobre a possibilidade de n&atilde;o fornecer consentimento e sobre as consequ&ecirc;ncias da negativa; e</li>
	<li>revoga&ccedil;&atilde;o do consentimento.</li>
</ul>

<p>Em caso de impossibilidade de ado&ccedil;&atilde;o imediata das provid&ecirc;ncias solicitadas pelos Usu&aacute;rios, a <strong>PENSETROQUE</strong> tomar&aacute; uma das seguintes medidas:</p>

<ul>
	<li>comunicar&aacute; que n&atilde;o &eacute; de fato o agente de tratamento dos dados, indicando, sempre que poss&iacute;vel, o agente aplic&aacute;vel; ou</li>
	<li>indicar&aacute; as raz&otilde;es de fato ou de direito que impedem a ado&ccedil;&atilde;o imediata da provid&ecirc;ncia.</li>
</ul>

<p>Nas hip&oacute;teses de solicita&ccedil;&atilde;o de elimina&ccedil;&atilde;o, anonimiza&ccedil;&atilde;o ou o bloqueio dos dados pelos Usu&aacute;rios, informaremos de maneira imediata aos agentes de tratamento com os quais tenhamos realizado uso compartilhado de dados da necessidade de corre&ccedil;&atilde;o, a fim de que repitam procedimento aplic&aacute;vel em seus sistemas.</p>

<p>A <strong>PENSETROQUE</strong> providenciar&aacute; a confirma&ccedil;&atilde;o de exist&ecirc;ncia ou o acesso a dados pessoais mencionados acima, tamb&eacute;m quando solicitado pelos Usu&aacute;rios, por meio eletr&ocirc;nico, seguro e id&ocirc;neo para esse fim ou sob forma impressa, a crit&eacute;rio dos Usu&aacute;rios, em uma das seguintes formas:</p>

<ul>
	<li>em formato simplificado, imediatamente; ou</li>
	<li>por meio de declara&ccedil;&atilde;o clara e completa, que indique a origem dos dados, a inexist&ecirc;ncia de registro, os crit&eacute;rios utilizados e a finalidade do Tratamento, observados os segredos comercial e industrial, fornecida no prazo de at&eacute; 15 (quinze) dias, contado da data do requerimento dos Usu&aacute;rios.</li>
</ul>

<p><a name="3znysh7"></a>Os Usu&aacute;rios tamb&eacute;m poder&atilde;o solicitar revis&atilde;o, por pessoa natural, de decis&otilde;es tomadas unicamente com base em Tratamento automatizado de Dados Pessoais que afetem seus interesses, inclusive de decis&otilde;es destinadas a definir o seu perfil pessoal, profissional, de consumo e de cr&eacute;dito e/ou elementos de sua personalidade. Caso deseje entender melhor sobre os crit&eacute;rios e procedimentos utilizados para a decis&atilde;o automatizada, os Usu&aacute;rios poder&atilde;o solicitar mais informa&ccedil;&otilde;es sobre o assunto, observados os segredos comercial e industrial da <strong>PENSETROQUE</strong>.</p>

<p>&nbsp;</p>

<p><strong>12)&nbsp;&nbsp;&nbsp; </strong><strong>SEGURAN&Ccedil;A</strong></p>

<p>Sempre utilizamos a melhor tecnologia do mercado e uma equipe preparada para garantir que os dados coletados sejam acessados somente por pessoas autorizadas, garantindo o uso correto das informa&ccedil;&otilde;es.</p>

<p>DADOS COLETADOS PELA PLATAFORMA QUE PORVENTURA FOREM CONSIDERADOS &ldquo;SENS&Iacute;VEIS&rdquo; SER&Atilde;O TRATADOS COM O OBJETIVO ESPEC&Iacute;FICO DE PREVEN&Ccedil;&Atilde;O E COMBATE &Agrave; FRAUDE E SEGURAN&Ccedil;A DO USU&Aacute;RIO. ASSIM, DENTRE OUTROS, EST&Atilde;O ABARCADAS SITUA&Ccedil;&Otilde;ES DE TRATAMENTO DE DADOS PARA ACESSO A LOCAIS EM QUE SEJA NECESS&Aacute;RIO A CONFIRMA&Ccedil;&Atilde;O DE TRANSA&Ccedil;&Otilde;ES BANC&Aacute;RIAS E PROCESSOS DE IDENTIFICA&Ccedil;&Atilde;O COM A PLATAFORMA DE INTERMEDIA&Ccedil;&Atilde;O DE PAGAMENTOS, A ESTES N&Atilde;O SE LIMITANDO.</p>

<p><strong>N&Atilde;O</strong><strong> COLHEMOS DADOS SENS&Iacute;VEIS RELACIONADOS &Agrave; SA&Uacute;DE DO USU&Aacute;RIO, ORIGEM RACIAL OU &Eacute;TNICA, CONVIC&Ccedil;&Atilde;O RELIGIOSA, OPINI&Atilde;O POL&Iacute;TICA, FILIA&Ccedil;&Atilde;O A SINDICATO OU A ORGANIZA&Ccedil;&Atilde;O DE CAR&Aacute;TER RELIGIOSO, FILOS&Oacute;FICO OU POL&Iacute;TICO, DADOS REFERENTES &Agrave; SA&Uacute;DE OU &Agrave; VIDA SEXUAL, BEM COMO DADOS GEN&Eacute;TICOS. </strong></p>

<p>&nbsp;</p>

<p><strong>13)&nbsp; &nbsp; &nbsp; &nbsp;COMO USAMOS SEUS DADOS?</strong></p>

<p>Podemos usar seus Dados para:</p>

<ul>
	<li>Executar as Funcionalidades da <strong>PENSETROQUE</strong> e o suporte ao cliente;</li>
	<li>Verificar sua identidade, mesmo durante a cria&ccedil;&atilde;o da conta e processos de redefini&ccedil;&atilde;o de senha;</li>
	<li>Cobrar taxas previamente notificadas e solucionar problemas;</li>
	<li>Gerenciar riscos ou detectar, evitar e/ou remediar fraudes ou outras atividades potencialmente proibidas ou ilegais;</li>
	<li>Detectar, evitar ou remediar viola&ccedil;&otilde;es de pol&iacute;ticas ou contratos aplic&aacute;veis;</li>
	<li>Aprimorar as Funcionalidades da <strong>PENSETROQUE</strong> por meio da personaliza&ccedil;&atilde;o de sua experi&ecirc;ncia de usu&aacute;rio;</li>
	<li>Medir o desempenho das Funcionalidades da <strong>PENSETROQUE</strong> e aprimorar seu conte&uacute;do e layout;</li>
	<li>Gerir e proteger nossa infraestrutura de tecnologia da informa&ccedil;&atilde;o; e</li>
	<li>Fornecer publicidade e marketing direcionados, notifica&ccedil;&otilde;es de atualiza&ccedil;&atilde;o de servi&ccedil;os e enviar ofertas promocionais com base nas suas prefer&ecirc;ncias e no seu perfil de consumo.</li>
</ul>

<p>Poderemos contat&aacute;-lo, por meio dos nossos Canais de Atendimento, para enviar notifica&ccedil;&otilde;es sobre sua conta, para resolver problemas com sua conta, resolver uma disputa, coletar taxas ou montantes devidos, pedir sua opini&atilde;o por meio de pesquisas ou question&aacute;rios, ou outros servi&ccedil;os necess&aacute;rios a correta e eficiente manuten&ccedil;&atilde;o da sua conta. Al&eacute;m disso, podemos entrar em contato para oferecer cupons, descontos e promo&ccedil;&otilde;es e inform&aacute;-lo sobre as Funcionalidades da <strong>PENSETROQUE</strong> e de nossos Parceiros. Finalmente, podemos entrar em contato se necess&aacute;rio para refor&ccedil;armos nossas pol&iacute;ticas, leis aplic&aacute;veis ou qualquer outro contrato que tivermos com voc&ecirc;.</p>

<p><strong>N&Atilde;O COMPARTILHAMOS SEUS DADOS PESSOAIS COM TERCEIROS PARA FINS DIFERENTES DOS AQUI EXPOSTOS SEM O SEU CONSENTIMENTO EXPL&Iacute;CITO. </strong></p>

<p>Respeitamos suas prefer&ecirc;ncias de comunica&ccedil;&atilde;o, assim, sempre que aplic&aacute;vel e permitido por lei, voc&ecirc; poder&aacute; se recusar a receber determinadas comunica&ccedil;&otilde;es.</p>

<p>&nbsp;</p>

<p><strong>14)&nbsp; &nbsp; &nbsp; &nbsp;COMO ARMAZENAMOS SEUS DADOS?</strong></p>

<p>Independentemente do tipo de Dado, a <strong>PENSETROQUE</strong> empenhar&aacute; seus maiores esfor&ccedil;os para proteg&ecirc;-lo, por meio de medidas f&iacute;sicas, t&eacute;cnicas e administrativas de seguran&ccedil;a para reduzir riscos de perda, mau uso e acesso n&atilde;o autorizado, divulga&ccedil;&atilde;o e altera&ccedil;&atilde;o. Usamos instrumentos t&eacute;cnicos de seguran&ccedil;a, como firewalls e criptografia de dados, aplicamos controles de acesso f&iacute;sico aos nossos data centers, al&eacute;m de controles de autoriza&ccedil;&atilde;o de acesso &agrave; informa&ccedil;&atilde;o.</p>

<p>&nbsp;</p>

<p><strong>15)&nbsp; &nbsp; &nbsp; &nbsp;COM QUEM COMPARTILHAMOS SEUS DADOS?</strong></p>

<p>Nenhum Dado Pessoal que voc&ecirc; venha a nos fornecer ser&aacute; repassado, disponibilizado, vendido, alugado ou distribu&iacute;do a terceiros, salvo nas hip&oacute;teses aqui previstas ou por exig&ecirc;ncia legal, em que os Dados Pessoais ser&atilde;o compartilhados nas formas abaixo descritas.</p>

<p>Podemos compartilhar seus Dados com:</p>

<ol>
	<li>Autoridades administrativas, judiciais, policiais, oficiais do governo ou outros terceiros para atender a uma intima&ccedil;&atilde;o, decis&atilde;o judicial, outro procedimento de tais autoridades, ou requisito legal aplic&aacute;vel &agrave; <strong>PENSETROQUE</strong>; quando precisamos atender &agrave; lei ou &agrave;s regras de associa&ccedil;&atilde;o a institui&ccedil;&otilde;es financeiras; ou quando acreditarmos, a nosso crit&eacute;rio, que a divulga&ccedil;&atilde;o de tal Dado Pessoal &eacute; necess&aacute;ria para impedir danos f&iacute;sicos ou perda financeira, para reportar atividade ilegal suspeita ou investigar viola&ccedil;&otilde;es dos nossos Termos e Condi&ccedil;&otilde;es Gerais de Uso.</li>
	<li>Outros terceiros n&atilde;o afiliados para os seguintes prop&oacute;sitos:</li>
</ol>

<ul>
	<li>Gest&atilde;o de riscos e preven&ccedil;&atilde;o de fraude: para ajudar a prevenir fraudes ou avaliar e gerenciar riscos;</li>
	<li>Canais de Atendimento ao Cliente: para fins de atendimento ao cliente, incluindo oferecer suporte as suas contas ou resolver disputas (por exemplo, problemas nas transa&ccedil;&otilde;es);</li>
	<li>Prestadores de Servi&ccedil;os: para permitir que prestadores de servi&ccedil;o sob contrato conosco suportem nossas opera&ccedil;&otilde;es comerciais, tais como preven&ccedil;&atilde;o de fraudes, cobran&ccedil;a, marketing, atendimento ao cliente e servi&ccedil;os de tecnologia. Nossos contratos determinam que esses fornecedores de servi&ccedil;o s&oacute; usem suas informa&ccedil;&otilde;es em rela&ccedil;&atilde;o aos servi&ccedil;os que realizam para n&oacute;s, e n&atilde;o em benef&iacute;cio pr&oacute;prio;</li>
</ul>

<ol>
	<li>Outros terceiros com seu consentimento ou orienta&ccedil;&atilde;o para tanto.</li>
</ol>

<p>N&oacute;s nos reservamos no direito, ainda, de revelar dados estat&iacute;sticos gerais anonimizados, de &iacute;ndices de audi&ecirc;ncia e h&aacute;bitos de navega&ccedil;&atilde;o da totalidade de usu&aacute;rios desta Plataforma Digital a fim de descrever nossos servi&ccedil;os a Parceiros, imprensa e outros terceiros. <strong>RESSALTAMOS QUE EM NENHUMA HIP&Oacute;TESE SER&Atilde;O DIVULGADOS DADOS QUE PERMITAM A IDENTIFICA&Ccedil;&Atilde;O DOS USU&Aacute;RIOS CADASTRADOS NEM DE SEUS H&Aacute;BITOS DE ACESSO E NAVEGA&Ccedil;&Atilde;O DENTRO DESTA PLATAFORMA DIGITAL.</strong></p>

<p>&nbsp;</p>

<p><strong>COMO COMPARTILHAMOS DADOS COM OUTROS USU&Aacute;RIOS DA PENSETROQUE? </strong></p>

<p><strong>N&Atilde;O &Eacute; PERMITIDO AO RECEPTOR USAR ESSAS INFORMA&Ccedil;&Otilde;ES PARA PROP&Oacute;SITOS N&Atilde;O RELACIONADOS &Agrave; PLATAFORMA DIGITAL</strong>. A <strong>PENSETROQUE </strong>n&atilde;o se responsabiliza por quaisquer a&ccedil;&otilde;es por parte desses terceiros, incluindo suas pr&aacute;ticas de prote&ccedil;&atilde;o de informa&ccedil;&otilde;es.</p>

<p>AS INFORMA&Ccedil;&Otilde;ES DO &ldquo;LEAD&rdquo; QUALIFICADO SOMENTE SER&Atilde;O DISPONIBILIZADAS AP&Oacute;S A EFETIVA CONCLUS&Atilde;O DE PAGAMENTO.</p>

<p>&nbsp;</p>

<p><strong>16)&nbsp; &nbsp; &nbsp; QUANDO E COMO TERMINA O TRATAMENTO DE DADOS? </strong></p>

<p>N&oacute;s encerraremos o Tratamento de Dados Pessoais nas seguintes hip&oacute;teses:</p>

<ul>
	<li>verifica&ccedil;&atilde;o de que a finalidade foi alcan&ccedil;ada ou de que os dados deixaram de ser necess&aacute;rios ou pertinentes ao alcance da finalidade espec&iacute;fica da <strong>PENSETROQUE</strong>;</li>
	<li>fim do per&iacute;odo de tratamento;</li>
	<li>comunica&ccedil;&atilde;o dos Usu&aacute;rios, inclusive no exerc&iacute;cio de seu direito de revoga&ccedil;&atilde;o do consentimento, resguardado o interesse p&uacute;blico; ou</li>
	<li>determina&ccedil;&atilde;o de autoridade nacional.</li>
</ul>

<p>A <strong>PENSETROQUE</strong> se compromete a reter a menor quantidade poss&iacute;vel de Dados, os quais, ap&oacute;s o t&eacute;rmino do tratamento e t&atilde;o logo atingida a finalidade de seu uso, ser&atilde;o, em princ&iacute;pio, eliminados, podendo ser conservados para:</p>

<ul>
	<li>cumprimento de obriga&ccedil;&atilde;o legal ou regulat&oacute;ria;</li>
	<li>estudo por &oacute;rg&atilde;o de pesquisa, garantida, sempre que poss&iacute;vel, a anonimiza&ccedil;&atilde;o dos Dados Pessoais;</li>
	<li>transfer&ecirc;ncia a terceiro, desde que respeitados os requisitos de tratamento de Dados dispostos na legisla&ccedil;&atilde;o; e</li>
	<li>uso exclusivo da <strong>PENSETROQUE</strong>, vedado seu acesso por terceiro, e desde que anonimizados os Dados.&nbsp;</li>
</ul>

<p>Manteremos os registros de acesso a nossa Plataforma Digital, sob sigilo, em ambiente controlado e de seguran&ccedil;a, pelo prazo de 6 (seis) meses, ou por prazo superior espec&iacute;fico determinado por autoridade policial ou administrativa ou pelo Minist&eacute;rio P&uacute;blico.</p>

<p>Os Usu&aacute;rios poder&atilde;o solicitar &agrave; <strong>PENSETROQUE</strong> a exclus&atilde;o definitiva dos Dados Pessoais que tiverem fornecidos, a seu requerimento, ao t&eacute;rmino da rela&ccedil;&atilde;o entre as partes, ressalvadas as hip&oacute;teses legais de guarda obrigat&oacute;ria de registros.</p>

<p><strong>17)&nbsp;&nbsp;&nbsp; ALTERA&Ccedil;&Otilde;ES NA POL&Iacute;TICA</strong></p>

<p>A <strong>PENSETROQUE</strong> poder&aacute; alterar esta Pol&iacute;tica de Privacidade e Seguran&ccedil;a de Dados a qualquer momento, em virtude de altera&ccedil;&otilde;es na legisla&ccedil;&atilde;o ou nos servi&ccedil;os, em decorr&ecirc;ncia da utiliza&ccedil;&atilde;o de novas ferramentas tecnol&oacute;gicas ou, ainda, sempre que, a exclusivo crit&eacute;rio da <strong>PENSETROQUE</strong>, tais altera&ccedil;&otilde;es se fa&ccedil;am necess&aacute;rias, raz&atilde;o pela qual recomendamos a leitura peri&oacute;dica desta Pol&iacute;tica de Privacidade e Seguran&ccedil;a de Dados.</p>

<p>A utiliza&ccedil;&atilde;o dos servi&ccedil;os disponibilizados pela <strong>PENSETROQUE</strong> por qualquer cliente/usu&aacute;rio implicar&aacute; em expressa aceita&ccedil;&atilde;o quanto aos termos e condi&ccedil;&otilde;es da Pol&iacute;tica de Privacidade e Seguran&ccedil;a de Dados vigente na data de sua utiliza&ccedil;&atilde;o.</p>

<p><strong>18)&nbsp;&nbsp;&nbsp; D&Uacute;VIDAS E SUGEST&Otilde;ES</strong></p>

<p>Caso haja qualquer d&uacute;vida ou sugest&atilde;o sobre estes Termos da Pol&iacute;tica de Privacidade e Seguran&ccedil;a de Dados ou caso se perceba que esta Pol&iacute;tica de Privacidade e Seguran&ccedil;a de Dados n&atilde;o esteja sendo cumprida, a n&atilde;o conformidade detectada dever&aacute; ser apontada ao respons&aacute;vel por meio do e-mail, contato@pensetroque.com.br ou em nossa Central de Relacionamento.</p>

<p>&nbsp;</p>

<p><strong>19)&nbsp;&nbsp;&nbsp; TERMOS DE USO</strong></p>

<p>O cliente/usu&aacute;rio expressamente aceita os termos e condi&ccedil;&otilde;es elencados nos Termos de Uso, os quais ficam aqui incorporados por refer&ecirc;ncia.</p>

<p>&nbsp;</p>

<p><strong>20)&nbsp;&nbsp;&nbsp; LEI APLIC&Aacute;VEL E RESOLU&Ccedil;&Atilde;O DE CONFLITOS</strong></p>

<p>Toda e qualquer controv&eacute;rsia oriunda dos termos expostos na presente Pol&iacute;tica de Privacidade e Seguran&ccedil;a de Dados ser&atilde;o solucionados de acordo com a lei brasileira, sendo competente a c&acirc;mara arbitral da cidade de S&atilde;o Paulo/SP a ser indicada pela PENSETROQUE, para resolu&ccedil;&atilde;o do conflito em quest&atilde;o, com exclus&atilde;o de qualquer outro por mais privilegiado que seja.</p>

                </div>
            </div>
        );
    }
}

export default Privacy;