import React, {Component} from "react";
import './carousel.css';
import Unavailable from '../../assets/img/indisponivel.png'

class Carousel extends Component{

    constructor(props){
        super(props);
        this.state = {
            list: [],
            id: '',
            basket: ''         
        };
    }

    componentDidMount(){
        
           let url = `https://apiconcessionaria.pensetroque.com.br/dealership_product/${this.props.productId}`;
           const config = {
               method: "GET",
               headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json'
               },
             }
           fetch(url, config)
           .then((r)=> r.json())
           .then((json)=>{
               let listPhotos = JSON.parse(json.result.photos)
               let listPhotosSlice = listPhotos.slice(0, 5)
   
               if(listPhotosSlice !== null && listPhotosSlice.length !== 0){
                   this.setState({
                       list: listPhotosSlice
                   }) 
                //    let url_photo = `http://13.92.244.173:3333/file/${this.state.list}`
                //    const config_photo = {
                //         method: "GET",
                //         headers: {
                //             'Accept': 'application/json',
                //             'Content-Type': 'application/json'
                //         },
                //      }
                //      fetch(url, config)
                //     .then((r)=> r.json())
                //     .then((json)=>{

                //     }
               }else {
                   this.setState({
                       list: ["produto sem imagem"]
                   }) 
               }
                 
           }).catch(error=>console.log("Deu erro aqui",error))
       
      }

       
    
    render(){
        return(
            <div>
             
                        <div className="carouselPosition">
                        {this.props.match === "true" ?
                            <React.Fragment>
                                <span className="ribbon1"><span>Match</span></span>
                            </React.Fragment>
                            :
                            <React.Fragment>
        
                            </React.Fragment>
                        }
                        
                        <div id={`carouselExampleIndicators${this.props.productId}`} className="carousel slide" data-ride="carousel">
                            
                            <ol className="carousel-indicators ">
                            {this.state.list.map((url, index)=>{
                            return (
                                <li data-target={`#carouselExampleIndicators${this.props.productId}`} data-slide-to={index} key={index}></li>
                            )
                        })}
                                
                            </ol>
                            <div className="carousel-inner">
                            {this.state.list.map((url, index)=>{
                                
                            return (
                                <React.Fragment>
                                    {url === "produto sem imagem" ?
                                        <div className={`carousel-item ${(index===0?"active":"")}`} key={index}>
                                            <img src={Unavailable} alt="Imagem não disponível" className={`d-block img-fluid ${this.props.carouselSize}`}/>
                                        </div>
                                        :
                                        <React.Fragment>
                                            {url.substring(0, 4) === 'http' ?
                                                <div className={`carousel-item ${(index===0?"active":"")}`} key={index}>
                                                    <img src={url} alt="Imagem do veículo" className={`d-block img-fluid ${this.props.carouselSize}`}/>
                                                </div>
                                                :
                                                <div className={`carousel-item ${(index===0?"active":"")}`} key={index}>
                                                    <img src={`https://api.pensetroque.com.br/file/${url}`} alt="Imagem do veículo" className={`d-block img-fluid ${this.props.carouselSize}`}/>
                                                </div>
                                            }
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                                        
                                    
                            )
                        })}
                             
                                
                        
                            </div>
                            <a className="carousel-control-prev" href={`#carouselExampleIndicators${this.props.productId}`} role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon indicatorColor" aria-hidden="true"><i className="fas fa-angle-left angleSettings"></i></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href={`#carouselExampleIndicators${this.props.productId}`} role="button" data-slide="next">
                                <span className="carousel-control-next-icon indicatorColor" aria-hidden="true"><i className="fas fa-angle-right angleSettings"></i></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
            </div>
        )
    }


}

export default Carousel;
