import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase';

var config = {
  apiKey: 'AIzaSyDwTnKvR7WurMe88LryoUsapgywMWtucAY',
  authDomain: 'pense-troque-dev-999e8.firebaseapp.com',
  databaseURL: 'https://pense-troque-dev-999e8.firebaseio.com',
  projectId: 'pense-troque-dev-999e8',
  storageBucket: 'pense-troque-dev-999e8.appspot.com',
  messagingSenderId: '84160177470',
  appId: '1:84160177470:web:77808335e2af038f13786f',
  measurementId: 'G-ZW5WRFP96T',
};

firebase.initializeApp(config);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
