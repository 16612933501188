import React from 'react';
import { useLocation } from 'react-router-dom';

import { Api } from '../../services/paymentApi';
import Header from '../../components/Header/buyHeader';
import Footer from '../../components/Footer';

import PaymentSuccessSVG from '../../assets/img/payment-success.svg';
import DownloadPlayStore from '../../assets/img/disponivel-google-play-badge.png';
import DownloadAppleStore from '../../assets/img/disponivel-na-app-store-botao.png';
import { useEffect } from 'react';
import { useState } from 'react';
// import { Container } from './styles';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function PaymentSuccess() {
  const query = useQuery();
  const transactionId = query.get('transactionId');
  const typePayment = query.get('typePayment') ?? 'cartao';
  console.log(typePayment);
  const messageSuccess = {
    cartao: 'Compra realizada com cartão de crédito/Debito',
    boleto: 'Compra realizada com Boleto',
  };
  const [order, setOrder] = useState({
    order_id: null,
    purchase_product_id: null,
    payment_status_id: null,
    payment_method_id: null,
    gateway_id: null,
    exchanger_id: null,
    transaction_id: null,
    quantity: null,
    order_value: null,
    amount_paid: null,
    info_paid_card: null,
    info_paid_card_brand: null,
    info_paid_card_name: null,
    info_paid_customer_name: null,
    info_paid_customer_document: null,
    createdAt: null,
    updatedAt: null,
    PaymentStatus: {
      payment_status_name: null,
    },
    PurchaseProduct: {
      purchase_product_name: null,
    },
  });
  useEffect(() => {
    const getOrder = async () => {
      const response = await Api.get('/order/' + transactionId);
      let orderM = response.data;

      orderM.createdAt = new Date().toLocaleString('pt-BR', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      });

      orderM.updatedAt = new Date().toLocaleString('pt-BR', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      });
      orderM.amount_paid = String(orderM.amount_paid);
      console.log(typeof orderM.amount_paid);

      console.log(orderM);
      // orderM.amount_paid = new Intl.NumberFormat('pt-BR', {
      //   style: 'currency',
      //   currency: 'BRL',
      // }).format(orderM.amount_paid);
      setOrder(orderM);
    };
    getOrder();
  }, [transactionId]);

  return (
    <>
      <Header />
      <section className="container">
        <div className="row my-3">
          <div className="col-12 text-center mb-2">
            <h1 className="titulos">Compra realizada com sucesso!</h1>
          </div>
          {typePayment === 'boleto' ? (
            <div className="col-12 text-center mb-2">
              <p>
                Para realizar seu pagamento, use o botão abaixo para baixar seu
                boleto!
              </p>
              <a
                className="btn  btn-success"
                href={`https://api.digitalbankstecnologia.com.br/api/public/print/boleto?boleto=${transactionId}`}
              >
                Baixar Boleto
              </a>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="row mt-5">
          <div className="col-md-6">
            <div className="payment-details">
              <div className="card mb-3">
                <div className="card-header d-flex justify-content-between">
                  <div>Realizada em: {order.createdAt}</div>
                  <div>Status: {order.PaymentStatus.payment_status_name}</div>
                </div>
                <div className="card-body">
                  <h5 className="card-title">{`${order.quantity} ${order.PurchaseProduct.purchase_product_name}`}</h5>
                  <p className="card-text">{messageSuccess[typePayment]}</p>
                </div>
                <div className="card-footer">
                  Total:
                  {' ' +
                    new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(
                      String(order.amount_paid).slice(
                        0,
                        String(order.amount_paid).length - 2
                      )
                    )}
                </div>
              </div>
            </div>
            <p>
              Tudo certo com a criação do seu pedido, você pode acompanhar o
              status da sua compra pela página de compras. Assim que for
              reconhecido o pagamento suas troquetas serão creditadas.
            </p>

            <p>
              Para usar suas troquetas basta acessar o App Pense Troque®! Se
              você ainda não baixou, faça o download abaixo:
            </p>
            <div className="row">
              <div className="col-6">
                <img
                  className="img-fluid"
                  src={DownloadAppleStore}
                  alt="Download na Apple Store"
                />
              </div>
              <div className="col-6">
                <img
                  className="img-fluid"
                  src={DownloadPlayStore}
                  alt="Download na Apple Store"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 d-none d-md-block d-xl-block">
            <img className="img-fluid" src={PaymentSuccessSVG} alt="" />
          </div>
        </div>
      </section>
      <Footer className="fixed-bottom" />
    </>
  );
}

export default PaymentSuccess;
