import React, { Component } from 'react';
import Header from '../../components/Header'
import './securitycode.css';
import TextField from '@material-ui/core/TextField';
import Info from '../../components/Info';
import { Link } from 'react-router-dom';

class Confirmation extends Component {

    componentDidMount(){
        window.scrollTo(0, 0)
    }

    render(){
        document.title = 'Código de segurança'
        return(
            <div className="overflow-hidden">
                <Header/>
                <div className="row">
                    <div className="col-6 blueBgRegister bgDivSettings p-5">
                        <div className="row">
                            <div className="col-8 mt-5 ml-5">
                                <p className="questionRegister m-0">Recebeu seu código de segurança?</p>
                                <p className="guideRegister mt-3">Digite o código de segurança no campo abaixo e boas trocas!</p>
                                <div className="registerInputs">                                    
                                    <TextField id="standard-basic" className="mailInput mt-3" label="CÓDIGO DE CONFIRMAÇÃO"/>
                                </div>
                                <Link to='/registered'><button type="submit" className="submitRegister mt-4">confirmar</button></Link>
                                <p className="guideRegister mt-5">Não recebeu o código de segurança?</p>
                                <button type="submit" className="submitRegister mt-2">Reenviar código</button>
                            </div>
                        </div>
                    </div>
                    <Info/>
                </div>
            </div>
        );
    }
}

export default Confirmation;
