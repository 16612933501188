import React, { Component } from 'react';
import Logo from '../../assets/img/logo_pense2.png';
import './faq.css';
import { Link } from 'react-router-dom';



class FAQ extends Component { 
    render(){
        document.title = 'Perguntas mais frequentes'
        return(
            <div>
                <div className="header sticky-top shadow-lg d-md-flex justify-content-center">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <div className="mb-2 d-md-flex justify-content-center  mr-md-5">
                            <img className="imgSet mr-5 mr-md-0 p-2" src={Logo} alt="logo PenseTroque"/>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse nav" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                <a className="nav-link linkSet ml-3 mr-3" href="/">Home<span className="sr-only">(current)</span></a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/blog">Blog</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/team">Equipe</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/faq">FAQ</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/partners">Parceiros</a>
                                <a className="nav-link btnLogin btn  ml-3 mr-3 mb-3 mb-md-0" href='/login'>Login</a>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="container mt-5 mb-5">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                           <h1 className="faqTitle">Perguntas Mais Frequentes</h1> 
                        </div>
                    </div>
                    <div className="row pl-5 mt-5">
                        <div className="col-12 d-flex flex-column">
                            <p className="questionTitle">
                                O que é a PenseTroque?
                            </p>
                            <p className="questionAnswer">
                                É uma plataforma de trocas e negociações digitais inteligentes que tem como principal objetivo tornar a experiência do usuário prática, simples, eficiente e eficaz, mas como? <br/><br/> 
                            Colocando frente a frente somente os usuários que tenham atendido de forma bilateral seus objetivos de troca e negociação, considerando também  os aspectos financeiros do negócio, e esta entrega acontece através do "Deu Match", que é o resultado da aplicação do algoritmo às ofertas de veículos e usuários, promovendo o casamento perfeito do encontro das pretensões de ambos.
                            </p>
                        </div>
                    </div>
                    <div className="row pl-5 pr-5 mt-3">
                        <div className="col-12 d-flex flex-column">
                            <p className="questionTitle">
                                Como funciona a PenseTroque?
                            </p>
                            <p className="questionAnswer">
                                Primeiro, você cadastra-se como Comprador ou Negociador, neste caso cadastra também o seu item de troca ou venda. Depois você tem algumas opções de escolha: "troca com troco", troca por item de maior valor ou trocar por um item que custa o mesmo que o seu ou de maior valor. Após estes primeiros passos, você vê a lista de itens que combinam com os seus critérios e cria uma lista de favoritos. Utilizando nossos créditos, você visualiza as informações de contato do outro trocador e inicia as negociações. Se você preferir comprar, basta criar uma conta e procurar seu próximo veículo. Simples assim!

                            </p>
                        </div>
                    </div>
                    <div className="row pl-5 pr-5 mt-3">
                        <div className="col-12 d-flex flex-column">
                            <p className="questionTitle">
                                Como funcionam os filtros?
                            </p>
                            <p className="questionAnswer">
                                Os Filtros para busca são as escolhas que o usuário faz para encontrar as ofertas que mais lhe interessam na transação de compra ou troca (objetos pretendidos). Quando os filtros não são informados, o usuário está aberto para receber qualquer oferta.
                            </p>
                        </div>
                    </div>
                    <div className="row pl-5 pr-5 mt-3">
                        <div className="col-12 d-flex flex-column">
                            <p className="questionTitle">
                                O que é o "Match"?
                            </p>
                            <p className="questionAnswer">
                                O "Match" é um conceito que sinaliza quando duas pessoas possuem o mesmo interesse, ou seja, "combinou"! Quando você tem um "match", significa que a pessoa se interessou pelo seu produto, e vice-versa, portanto, as chances da negociação serem concluídas é grande.
                            </p>
                        </div>
                    </div>
                    <div className="row pl-5 pr-5 mt-3">
                        <div className="col-12 d-flex flex-column">
                            <p className="questionTitle">
                                Como funciona a lista de favoritos?
                            </p>
                            <p className="questionAnswer">
                                A lista de favoritos é aquela onde você encontra somente os itens para troca ou compra que você mais gostou dentre as ofertas que apareceram para você de acordo com seus filtros.
                            </p>
                        </div>
                    </div>
                    <div className="row pl-5 pr-5 mt-3">
                        <div className="col-12 d-flex flex-column">
                            <p className="questionTitle">
                                O que é uma oferta de troca?
                            </p>
                            <p className="questionAnswer">
                                A Oferta de Troca é o processo em que o usuário "Trocador A" envia uma proposta de negociação para o usuário "Trocador B". As ofertas de trocas somente podem ser enviadas para os veículos que deram Match. As informações de contato, entretanto, somente serão visualizadas após o aceite da negociação pelo "Trocador B".
                            </p>
                        </div>
                    </div>
                    <div className="row pl-5 pr-5 mt-3">
                        <div className="col-12 d-flex flex-column">
                            <p className="questionTitle">
                                O que é uma oferta de compra?
                            </p>
                            <p className="questionAnswer">
                                A Oferta de Compra é o processo em que um usuário comprador envia uma proposta de negociação indicando um valor específico para o usuário que disponibilizou seu veículo para venda ou troca. As informações de contato somente serão visualizadas após o aceite da oferta de match ter sido aceito pela contraparte.
                            </p>
                        </div>
                    </div>
                </div>
                <footer>
                    <div className="col-12 footerBg">
                        <div className="container">
                            <div className="row">
                                <div className="col-4 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Sobre Nós</p>
                                    <p className="footerText">Pensou, deu match, trocou!</p>
                                    <Link to='/contact' className="redirectButton d-flex justify-content-center align-items-center"><p className="m-0">Entre em contato</p></Link>
                                </div>
                                <div className="col-2"></div>
                                <div className="col-3 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Navegação</p>
                                    <a href="/" className="footerText">Home</a>
                                    <a href='/blog' className="footerText">Blog</a>
                                    <a href='/team' className="footerText">Equipe</a>
                                    <a href='/faq' className="footerText">FAQ</a>
                                    <a href='/partners' className="footerText">Parceiros</a>
                                    <a href='/login' className="footerText">Login</a>
                                </div>
                                <div className="col-3 d-none d-md-flex flex-column mt-5">
                                    <p className="footerSectionTitle">Links úteis</p>
                                    <a href='/termos-de-uso' className="footerText">Termos de Uso</a>
                                    <a href='/privacidade' className="footerText">Privacidade</a>
                                    <a href='/contact' className="footerText">Contato</a>
                                </div>
                            </div>
                            <hr className="lineBorder d-none d-md-flex"/>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center mt-5 mt-md-0 mb-3">
                                    <div>
                                        <a href="https://www.facebook.com/pensetroqueveiculos"><i className="fab fa-facebook socialIcon mr-4"></i></a>
                                        <a href="https://www.instagram.com/pensetroque/"><i className="fab fa-instagram socialIcon mr-4"></i></a>
                                        <a href="https://www.linkedin.com/company/pensetroque-veiculos/"><i className="fab fa-linkedin socialIcon mr-4"></i></a>
                                        <a href="https://www.youtube.com/channel/UClCxpZNW7SzAOBwAmTTUnKg"><i className="fab fa-youtube socialIcon"></i></a>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center">
                                    <p className="copyText">&copy; 2020 - PenseTroque</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default FAQ;