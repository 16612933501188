import React, { useState, useContext } from 'react';

import Header from '../../components/Header/buyHeader';
import Footer from '../../components/Footer';
import ScrollTopArrow from '../../components/ScrollTopArrow';
import { useHistory } from 'react-router-dom';

import { CartContext } from '../../states/Cart';

import TroquetasApresentacao from '../../assets/img/troquetas-apresentacao.svg';
import TroquetasGasto from '../../assets/img/select-troqueta.svg';

import './styles.css';

function BuyTroquetas() {
  const valueTroqueta = 11.0;
  const history = useHistory();
  const { cart, setCart } = useContext(CartContext);

  const [qty, setQty] = useState(1);

  function goToCart() {
    setCart({
      userId: '',
      value: qty * valueTroqueta,
      qtd: qty,
      vaucher: '',
    });
    history.push('/confirmacao-dados');
  }

  return (
    <>
      <Header />
      <section className="container">
        <div className="row my-3">
          <div className="col-12 text-center mb-2">
            <h1 className="titulos">Compre suas troquetas</h1>
          </div>
          <div className="col-md-6 mt-3">
            <h4>Quantidade de Troquetas</h4>
            <div className="d-flex flex-wrap">
              <button
                className="btn btn-primary mx-1"
                onClick={() => setQty(qty + 1)}
              >
                +1
              </button>
              <button
                className="btn btn-primary mx-1"
                onClick={() => setQty(qty + 5)}
              >
                +5
              </button>
              <button
                className="btn btn-primary mx-1"
                onClick={() => setQty(qty + 10)}
              >
                +10
              </button>

              <button className="btn btn-danger mx-1" onClick={() => setQty(1)}>
                Limpar
              </button>
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <h4>Total</h4>
            <div className="price">
              <p className="troquetas-text">{qty} Troquetas</p>
              <p>R${qty * valueTroqueta},00</p>
            </div>
            <div className="actions">
              <div className="d-flex ">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Tenho um cupom!"
                />
                <button className="btn btn-info mx-1">Aplicar</button>
              </div>
              <button onClick={goToCart} className="btn btn-success text-end">
                Finalizar Compra
              </button>
            </div>
          </div>
        </div>
        <div className="dropdown-divider my-5"></div>
        <section className="row my-3">
          <div className="col-md-6">
            <h4>O que são as troquetas?</h4>
            <p>
              Troqueta é a moeda virtual utilizada dentro do aplicativo. Com ela
              você pode solicitar contato com trocador da oferta escolhida. Caso
              sua solicitação de contato seja rejeitada, a troqueta volta para a
              sua conta.
            </p>
          </div>
          <div className="col-md-6 d-none d-md-block d-xl-block">
            <img
              src={TroquetasApresentacao}
              className="img-fluid"
              alt="Troquetas são usadas para fazer Matchs"
            />
          </div>
        </section>
        <ScrollTopArrow />
        <section className="row my-3 flex-wrap-reverse">
          <div className="col-md-6 d-none d-md-block d-xl-block">
            <img
              src={TroquetasGasto}
              className="img-fluid"
              alt="Troquetas são usadas para fazer Matchs"
            />
          </div>
          <div className="col-md-6">
            <h4>Como posso gastar minhas troquetas?</h4>
            <p>
              A troqueta é consumida ao solicitar as informações de contato de
              um match. Com as troquetas você também pode comprar um histórico
              veicular online para seu veículo ou para o veículo em negociação
            </p>
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                1 Match
                <span className="badge badge-primary badge-pill">1</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                1 Histórico Veicular
                <span className="badge badge-primary badge-pill">5</span>
              </li>
            </ul>
          </div>
        </section>
      </section>
      <Footer />
    </>
  );
}

export default BuyTroquetas;
