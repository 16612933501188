import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import { AuthContext } from '../../states/Auth';

import Logo from '../../assets/img/logo_pensetroque_circle.png';
import './buyHeader.css';
import { useContext } from 'react';
function BuyHeader() {
  const { user, guest, logout } = useContext(AuthContext);

  useEffect(() => {}, []);

  return (
    <header id="buyHeader">
      <nav className="navbar navbar-expand-lg container navbar-dark">
        <a className="navbar-brand" href="/painel">
          <img src={Logo} className="img-fluid" alt="Logo - Pense & troque" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav">
            <li className="nav-item active">
              <Link className="nav-link" to={'/comprar-troquetas'}>
                Comprar Troquetas <span className="sr-only">(current)</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={'/minhas-compras'}>
                Compras
              </Link>
            </li>
            <li className="nav-item dropdown">
              {!guest ? (
                <a
                  className="nav-link dropdown-toggle"
                  id="navbarDropdownMenuLink"
                  role="button"
                  href="#"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Olá {user.exchanger_name}
                </a>
              ) : (
                <Link to="/login" className="nav-link">
                  Login
                </Link>
              )}
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a className="dropdown-item" onClick={logout}>
                  Sair
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default BuyHeader;
