import React, { useContext } from 'react';

import { Route, Redirect, useLocation } from 'react-router-dom';
import { AuthContext } from './states/Auth';

// const isAuth = () => localStorage.getItem('token') !== null;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const { guest } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        !guest ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                message: 'Usuário não autorizado',
                referrer: location.pathname,
              },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
