import React, { Component, useContext } from 'react';
import './loginsite.css';
import Logo from '../../assets/img/logo_pense2.png';
import firebase from 'firebase';
import GoogleSignin from 'react-google-login';
import { BASE_URL } from '../../services/api';
import { Link, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from '../../states/Auth';

let signOut = async () => {
  try {
    firebase.auth().signOut();
  } catch (error) {
    console.error(error);
  }
};
class LoginSite extends Component {
  static contextType = AuthContext;

  constructor() {
    super();

    this.state = {
      email: 'vinicius.developer@outlook.com',
      password: '123456',
      error: false,
      errorMessage: '',
      isLoading: false,
    };
  }

  myChangeHandlerEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  myChangeHandlerPassword = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  goHome() {
    return this.props.history.push('/comprar-troquetas');
  }

  getUserExchangerId = async (email) => {
    await signOut();
    try {
      const url = `${BASE_URL}/login`;
      const config = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      };
      let api = await fetch(url, config);
      let response = await api.json();

      let exchangerId = response.exchanger_id;
      let basketId = response.exchanger_basket_id;
      let productId = response.product_id;
      localStorage.setItem('basketId', basketId);
      localStorage.setItem('productId', productId);
      localStorage.setItem('exchangerId', exchangerId);
      if (typeof this.props.location != 'undefined') {
        return (window.location.href = this.props.location.state.referrer);
      }
      return (window.location.href = '/comprar-troquetas');
      // if (productId) {{
      //   window.location.href = '/matches';
      // } else {
      //   window.location.href = '/registered';
      // }
    } catch (err) {
      console.log(err);
    }
  };

  emailLogin = async () => {
    if (this.state.email && this.state.password) {
      let email = this.state.email;
      let password = this.state.password;
      try {
        let currentUser = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password);
        if (firebase.auth().currentUser.emailVerified) {
          let token = await firebase.auth().currentUser.getIdToken();

          const response = this.context.setAuth(this.state.email, token);
          if (!response) {
            return toast.error(
              'Ops 😥 Houve um erro ao tentar realizar seu login, recarregue a página ou nos mande uma mensagem!',
              { position: 'bottom-right', autoClose: 5000 }
            );
          }
          this.goHome();
          // localStorage.setItem('token', token);
          // this.getUserExchangerId(this.state.email);
        } else {
          alert('Email não verificado');
        }
      } catch (error) {
        this.setState({
          error: true,
          errorMessage: 'Usuário ou senha incorreto(s)',
        });
        alert(this.state.errorMessage);
        window.location.reload();
      }
    } else {
      alert('Usuário ou senha incorretos');
    }
  };

  googleLogin = async () => {
    await this.googleSignOut();
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        var token = result.credential.accessToken;
        var user = result.user;
        if (user) {
          const response = this.context.setAuth(this.state.email, token);
          if (!response) {
            return toast.error(
              'Ops 😥 Houve um erro ao tentar realizar seu login, recarregue a página ou nos mande uma mensagem!',
              { position: 'bottom-right', autoClose: 5000 }
            );
          }
          this.goHome();
          // let name = user.displayName;
          // let email = user.email;
          // localStorage.setItem('name', name);
          // localStorage.setItem('email', email);
          // localStorage.setItem('user', user);
          // localStorage.setItem('token', token);
          // this.getUserExchangerId(email);
        } else {
          alert('Problema no cadastro');
        }
      })
      .catch(function (error) {
        alert('Erro');
      })
      .then();
  };

  googleSignOut = async () => {
    try {
      await GoogleSignin.revokeAccess();
      await GoogleSignin.signOut();
    } catch (error) {}
  };

  render() {
    return (
      <div className="loginBg p-5">
        <ToastContainer />
        <div className="loginCard m-auto mt-5 mb-5 p-3">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                <img src={Logo} width="60%" alt="Logo PenseTroque" />
                <p className="mt-3">Entre com suas credenciais:</p>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fas fa-envelope"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    aria-label="Email"
                    aria-describedby="basic-addon1"
                    onChange={this.myChangeHandlerEmail}
                  />
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fas fa-key"></i>
                    </span>
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Senha"
                    aria-label="Senha"
                    aria-describedby="basic-addon2"
                    onChange={this.myChangeHandlerPassword}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-primary btn-primary w-100 mt-3"
                  onClick={this.emailLogin}
                >
                  Acessar
                </button>
                <p className="m-0">ou</p>
                <button
                  type="button"
                  className="btn btn-danger w-100 mb-3"
                  onClick={this.googleLogin}
                >
                  Entre com o Google
                </button>
                <hr />
                <p>Ainda não tem cadastro?</p>
                <Link to="/register" className="btn btn-success w-100 mb-5">
                  Registre-se
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginSite;
