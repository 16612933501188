import React, { Component } from 'react';
import './loginbutton.css';

class LoginButton extends Component {

    render(){
        return(
            
            <div className="buttonSettings blueButtonBg d-flex justify-content-center">
                <p className="buttonContent text-center">{this.props.iconMedia}<span className="barSettings">{this.props.bar}</span>{this.props.mediaName}</p>
            </div>
            
        );
    }
}

export default LoginButton;