const errors = {
  0: {
    type: 'Not valid Brazilian tax id',
    message: 'Há algo errado com seu CPF, verifique e tente novamente!',
  },
};

module.exports = (message) => {
  console.log(message);
  let errorResponse;

  for (const error in errors) {
    if (message.indexOf(errors[error].type) >= 0) {
      errorResponse = errors[error];
      break;
    }
  }

  if (!errorResponse) {
    return '😅 ops... parece que tivemos um problema com o servidor, tente novamente em alguns minutos ou contacte o suporte!';
  }
  return errorResponse.message;
};
