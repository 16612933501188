import React, { Component } from 'react';
import './terms.css';
import Header from '../../components/Header'
import Logo from '../../assets/img/logo_pense2.png';

class TermsOfService extends Component { 
    render(){
        document.title = 'Termos de Serviço'
        return(
            <div>
                <div className="header sticky-top shadow-lg d-md-flex justify-content-center">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <div className="mb-2 d-md-flex justify-content-center  mr-md-5">
                            <img className="imgSet mr-5 mr-md-0 p-2" src={Logo} alt="logo PenseTroque"/>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse nav" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                <a className="nav-link linkSet ml-3 mr-3" href="/">Home<span className="sr-only">(current)</span></a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/blog">Blog</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/team">Equipe</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/faq">FAQ</a>
                                <a className="nav-link linkSet ml-3 mr-3" href="/partners">Parceiros</a>
                                <a className="nav-link btnLogin btn  ml-3 mr-3 mb-3 mb-md-0" href='/login'>Login</a>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="container divSettings">
                    <h1><strong>&nbsp;TERMOS E CONDI&Ccedil;&Otilde;ES GERAIS DE USO-PF</strong></h1>

                    <p>&nbsp;</p>

                    <p>Estes Termos e Condi&ccedil;&otilde;es Gerais de Uso apresentam as &ldquo;Condi&ccedil;&otilde;es Gerais&rdquo; aplic&aacute;veis para o uso da plataforma online disponibilizada pela <strong>NEOQUANTUM BUSINESS DESIGN SERVI&Ccedil;OS DE INFORM&Aacute;TICA LTDA</strong>, inscrita no CNPJ sob n&ordm; 33.192.005/0001-12 (<strong>&ldquo;PENSETROQUE&rdquo;</strong>, &ldquo;n&oacute;s&rdquo;, &ldquo;nosso&rdquo;, &ldquo;nossos&rdquo;, &ldquo;nossa&rdquo;, &ldquo;nossas&rdquo;), aos usu&aacute;rios interessados em usar a solu&ccedil;&atilde;o de troca de ve&iacute;culos por meio de &ldquo;leads&rdquo; qualificados (<strong>&ldquo;Usu&aacute;rio&rdquo;</strong>, &ldquo;titular&rdquo;, &ldquo;voc&ecirc;&rdquo;, &ldquo;seu&rdquo;, &ldquo;seus&rdquo;, &ldquo;sua&rdquo;, &ldquo;suas&rdquo;).&nbsp;</p>

                    <p>A <strong>NEOQUANTUM</strong>, com sede na Rua Jos&eacute; Estev&atilde;o de Magalh&atilde;es, 166, Vila Campestre, S&atilde;o Paulo, SP, &eacute; &uacute;nica e exclusiva propriet&aacute;ria dos dom&iacute;nios:&nbsp;</p>

                    <p>www.pensetroque.com.br&nbsp; &nbsp;-&nbsp; www.pensetroque.com&nbsp; -&nbsp; www.penseetroque.com.br</p>

                    <p>www.thinkandswap.com&nbsp; -&nbsp; www.swipeandswap.app&nbsp; &nbsp;-&nbsp; www.pensarycambiar.com</p>

                    <p>www.thinkswap.club .</p>

                    <p>&nbsp;</p>

                    <ul>
                        <li><strong>DA ACEITA&Ccedil;&Atilde;O DAS CONDI&Ccedil;&Otilde;ES GERAIS E ABRANG&Ecirc;NCIA</strong>

                        <ul>
                            <li>A utiliza&ccedil;&atilde;o da PENSETROQUE sujeita o Usu&aacute;rio &agrave; observ&acirc;ncia e respeito &agrave;s Condi&ccedil;&otilde;es Gerais aqui apresentadas. Trata-se de um aplicativo que poder&aacute; ser baixado na Apple Store e Google Play que se destinar&aacute; ao trocador Pessoa F&iacute;sica.&nbsp;</li>
                            <li><strong>AO MARCAR A OP&Ccedil;&Atilde;O &ldquo;CONCORDO COM OS TERMOS E CONDI&Ccedil;&Otilde;ES GERAIS DE USO E COM A POL&Iacute;TICA DE PRIVACIDADE&rdquo;, DE FORMA ESPECIFICADA, CONSTANTE DO FORMUL&Aacute;RIO FORNECIDO PELA PENSE TROQUE POR MEIO DA PLATAFORMA DIGITAL, VOC&Ecirc; DECLARA QUE LEU, COMPREENDEU E ACEITA, SEM RESERVAS, TODAS AS SUAS CL&Aacute;USULAS E CONDI&Ccedil;&Otilde;ES.</strong></li>
                        </ul>
                        </li>
                    </ul>

                    <p><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.3</strong>&nbsp; O Usu&aacute;rio declara:&nbsp;</p>

                    <p>(a) ser pessoa f&iacute;sica maior de 18 (dezoito) anos; e</p>

                    <p>(b) possuir capacidade jur&iacute;dica;</p>

                    <p>(c) n&atilde;o possuir impedimentos para celebrar e cumprir estas Condi&ccedil;&otilde;es Gerais;</p>

                    <p>&nbsp;</p>

                    <ul>
                        <li><strong>DEFINI&Ccedil;&Otilde;ES</strong></li>
                    </ul>

                    <p>Para os fins aqui referidos, os termos abaixo ter&atilde;o os seguintes significados:</p>

                    <ul>
                        <li><strong>Conta PENSETROQUE</strong>: conta gr&aacute;fica eletr&ocirc;nica na qual a <strong>PENSETROQUE</strong>, por meio do seu algoritmo, cruza os dados da oferta do ve&iacute;culo, objeto da troca, com a contrapartida do interessado, considerando-se o componente financeiro de ambos. Assim, para trocador e interessado terem acesso &agrave; informa&ccedil;&atilde;o, dever&atilde;o pagar um valor que ser&aacute; convertido em &ldquo;troqueta&rdquo;. Ap&oacute;s o pagamento, a negocia&ccedil;&atilde;o ocorrer&aacute; fora da plataforma.&nbsp;</li>
                        <li><strong>Plataforma Digital</strong>: plataforma online desenvolvida e disponibilizada pela<strong> PENSETROQUE</strong> aos Usu&aacute;rios, via aplicativo ou site, para acesso e uso das suas Funcionalidades.</li>
                        <li>&nbsp;</li>
                        <li><strong>Usu&aacute;rio</strong>: pessoa f&iacute;sica cadastrada na <strong>PENSETROQUE</strong> interessada em colocar ve&iacute;culos automotores para troca.</li>
                        <li>&nbsp;</li>
                        <li><strong>Troqueta</strong>: Moeda utilizada dentro da plataforma <strong>PENSETROQUE</strong>.</li>
                    </ul>

                    <p>&nbsp;</p>

                    <p><strong>3)&nbsp; OBJETO</strong></p>

                    <p>3.1 A <strong>PENSETROQUE</strong> &eacute; uma plataforma online que aproxima Usu&aacute;rios que tenham interesses rec&iacute;procos na troca de ve&iacute;culos automotores.&nbsp;</p>

                    <p>3.2 O Usu&aacute;rio reconhece que a utiliza&ccedil;&atilde;o da <strong>PENSETROQUE</strong> n&atilde;o gera rela&ccedil;&atilde;o de trabalho, v&iacute;nculo empregat&iacute;cio, associa&ccedil;&atilde;o ou sociedade entre Usu&aacute;rios ou Parceiros, nem tampouco representa transa&ccedil;&atilde;o comercial ou venda de produtos. O Usu&aacute;rio reconhece, ainda, que a<strong> PENSETROQUE</strong> n&atilde;o &eacute; parte da cadeia de fornecimento ou de consumo de produtos ou servi&ccedil;os de concession&aacute;rias ou afins e n&atilde;o pode ser considerada fornecedora, prestadora, revendedora, adquirente, ou usu&aacute;ria dos produtos ou servi&ccedil;os prestados por estes Estabelecimentos, nem tampouco pode ser considerada agente, corretora ou garantidora, uma vez que atua somente como intermediadora na rela&ccedil;&atilde;o entre Usu&aacute;rios que tenham interesses na troca de ve&iacute;culos.&nbsp;</p>

                    <p>3.3 Para acesso &agrave; <strong>PENSETROQUE</strong> voc&ecirc; dever&aacute; possuir dispositivo m&oacute;vel conectado &agrave; Internet, provedor de conex&atilde;o &agrave; Internet e aplica&ccedil;&atilde;o m&oacute;vel dispon&iacute;vel para download gratuito na App Store e no Google play.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p><strong>4) CADASTRAMENTO&nbsp;</strong></p>

                    <p>4.1 O cadastro do Usu&aacute;rio na <strong>PENSETROQUE</strong> ser&aacute; realizado atrav&eacute;s do correto e integral preenchimento do formul&aacute;rio disponibilizado na Plataforma Digital.&nbsp;</p>

                    <p>4.2 Para o cadastro, ser&aacute; necess&aacute;rio que voc&ecirc; forne&ccedil;a os dados especificados na nossa Pol&iacute;tica de Privacidade. Ao se inscrever, o Usu&aacute;rio concorda em (a) fornecer informa&ccedil;&atilde;o precisa, atual e completa a seu respeito, tal como lhe for solicitado pelo nosso formul&aacute;rio de cadastro; e em (b) corrigir e atualizar as suas informa&ccedil;&otilde;es para mant&ecirc;-las precisas, atuais e completas. O Usu&aacute;rio responsabiliza-se civil e criminalmente pela exatid&atilde;o das informa&ccedil;&otilde;es fornecidas nos formul&aacute;rios enviados &agrave; <strong>PENSETROQUE</strong>.</p>

                    <p>4.3 A atualiza&ccedil;&atilde;o e altera&ccedil;&atilde;o dos dados cadastrais do Usu&aacute;rio poder&aacute; ser feita a qualquer tempo por meio de sua Conta <strong>PENSETROQUE</strong>.</p>

                    <p>4.4 O Usu&aacute;rio compreende e aceita que tais dados de identifica&ccedil;&atilde;o por ele fornecidos sejam tratados segundo as normas descritas na nossa Pol&iacute;tica de Privacidade, que se encontra acess&iacute;vel atrav&eacute;s do link &ldquo;Pol&iacute;tica de Privacidade&rdquo;.</p>

                    <p>4.5 O Usu&aacute;rio tamb&eacute;m est&aacute; ciente e concorda que lhe seja solicitado, no processo de registro, a utiliza&ccedil;&atilde;o de um e-mail de cadastro (login) e uma senha, pelos quais ser&aacute; respons&aacute;vel pela guarda e confidencialidade, concordando em n&atilde;o transferir ou ceder, seja a t&iacute;tulo gratuito ou oneroso.&nbsp;</p>

                    <p>4.6 Voc&ecirc; dever&aacute; informar imediatamente &agrave; <strong>PENSETROQUE</strong> caso seu login e senha sejam extraviados. O Usu&aacute;rio <strong>&Eacute; INTEIRAMENTE RESPONS&Aacute;VEL POR TODA E QUALQUER ATIVIDADE (INCLUINDO O USO DAS FUNCIONALIDADES) QUE SEJAM REALIZADAS POR MEIO DA SUA CONTA PESSOAL</strong>. Para obter mais informa&ccedil;&atilde;o sobre quest&otilde;es relativas &agrave; seguran&ccedil;a dos dados, consulte nossa Pol&iacute;tica de Privacidade.</p>

                    <p>4.7 O Usu&aacute;rio ter&aacute; uma &uacute;nica Conta <strong>PENSETROQUE</strong>. A <strong>PENSETROQUE</strong> reserva-se no direito de unificar as contas e/ou cancelar logins do Usu&aacute;rio, caso tenha sido atribu&iacute;do a Usu&aacute;rio que j&aacute; tenha uma outra conta. A <strong>PENSETROQUE</strong> n&atilde;o aceitar&aacute; cadastramento que informe o mesmo endere&ccedil;o de e-mail ou o mesmo CPF de Usu&aacute;rio j&aacute; cadastrado, ou que informe outros dados que indiquem que o Usu&aacute;rio j&aacute; est&aacute; cadastrado junto &agrave; <strong>PENSETROQUE</strong>.</p>

                    <p>4.8 O Usu&aacute;rio autoriza expressamente que o seu cadastramento junto &agrave; <strong>PENSETROQUE</strong> seja mantido, bem como autoriza que esta forne&ccedil;a as informa&ccedil;&otilde;es constantes do referido cadastro aos seus Parceiros, a outras sociedades de seu grupo econ&ocirc;mico e/ou a autoridades p&uacute;blicas competentes as quais as solicitem legitimamente. Em nenhuma hip&oacute;tese, contudo, a <strong>PENSETROQUE</strong> fornecer&aacute; a seus Parceiros ou a outras empresas informa&ccedil;&otilde;es referentes &agrave;s transa&ccedil;&otilde;es feitas por seus Usu&aacute;rios ou seus dados banc&aacute;rios.</p>

                    <p>&nbsp;</p>

                    <p><strong>5) DO USO DAS FUNCIONALIDADES</strong></p>

                    <p>5.1 A partir do cadastro, voc&ecirc; poder&aacute; come&ccedil;ar a utilizar nossas Funcionalidades.&nbsp;</p>

                    <p>5.2 Voc&ecirc; &eacute; o &uacute;nico que possui responsabilidade por sua publica&ccedil;&atilde;o. A palavra &quot;publica&ccedil;&atilde;o&quot; &eacute; utilizada como refer&ecirc;ncia a qualquer informa&ccedil;&atilde;o ou conte&uacute;do publicado em nossa Plataforma ou, de outra forma, fornecido a n&oacute;s. &Eacute; fundamental a concord&acirc;ncia que voc&ecirc; toma em n&atilde;o infringir, pessoalmente ou por meio do seu conte&uacute;do, os direitos de terceiros.</p>

                    <ul>
                        <li>A sua publica&ccedil;&atilde;o deve possuir os mesmos padr&otilde;es de conformidade que est&atilde;o inseridos em nossos Termos de Uso.</li>
                    </ul>

                    <p>&nbsp;</p>

                    <ul>
                        <li>Voc&ecirc; autoriza expressamente que a <strong>PENSETROQUE</strong> utilize sua publica&ccedil;&atilde;o para operar, promover e assegurar o benef&iacute;cio &agrave;s pessoas interessadas.</li>
                    </ul>

                    <p>&nbsp;</p>

                    <ul>
                        <li>N&atilde;o &eacute; de nossa responsabilidade o conte&uacute;do que os Usu&aacute;rios da Plataforma postam. Ao deparar-se com conte&uacute;do que viole os nossos Termo de Uso, voc&ecirc; pode nos enviar um comunicado sobre o conte&uacute;do indevido, por meio da aba &ldquo;Fale Conosco&rdquo;.</li>
                    </ul>

                    <p>&nbsp;</p>

                    <ul>
                        <li>Ao fazer a compra de Troquetas, voc&ecirc; ser&aacute; informado passo a passo como utiliz&aacute;-las dentro da plataforma <strong>PENSETROQUE</strong>.</li>
                    </ul>

                    <p>&nbsp;</p>

                    <p><strong>6)&nbsp; DIREITOS, DEVERES E LIMITES DE RESPONSABILIDADE DA PENSE TROQUE</strong></p>

                    <p>&nbsp;</p>

                    <p>6.1 Com a utiliza&ccedil;&atilde;o de nossa Plataforma, o Usu&aacute;rio aceita as limita&ccedil;&otilde;es de responsabilidade e isen&ccedil;&atilde;o constantes neste Termo de Uso.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>6.2&nbsp; Em caso de infra&ccedil;&atilde;o ou n&atilde;o cumprimento das normas deste termo, poderemos suspender ou encerrar sua conta.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>6.3 Nossa Plataforma sofre aperfei&ccedil;oamentos continuamente, significando que podemos alterar partes operacionais e funcionais, com o objetivo de melhorar o desempenho. Em decorr&ecirc;ncia disto, os servi&ccedil;os poder&atilde;o ser interrompidos temporariamente com ou sem aviso pr&eacute;vio, sem qualquer responsabilidade perante aos usu&aacute;rios ou terceiros.</p>

                    <p>&nbsp;</p>

                    <p>6.4&nbsp; &nbsp;&Eacute; poss&iacute;vel que ocorra mudan&ccedil;a destes Termos de Uso. Quando isso ocorrer, ser&aacute; enviada uma notifica&ccedil;&atilde;o, dentro do aplicativo, informando a vers&atilde;o mais recente. Ao continuar a usar a Plataforma, mesmo ap&oacute;s todas as altera&ccedil;&otilde;es, voc&ecirc; estar&aacute; concordando com os Termos de Uso at&eacute; ent&atilde;o vigentes.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>6.5&nbsp; &nbsp;Nossa Plataforma encontra-se acess&iacute;vel para qualquer pessoa que possua, no m&iacute;nimo, 18 anos de idade. O Usu&aacute;rio dever&aacute; ter capacidade legal para efetuar a troca do ve&iacute;culo em quest&atilde;o.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>6.6&nbsp; &nbsp; Os Usu&aacute;rios obrigam-se a entrar em contato uns com os outros para finalizar ou negar a troca sempre que tenham recebido solicita&ccedil;&otilde;es em suas ofertas.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>6.7&nbsp; N&atilde;o &eacute; poss&iacute;vel o controle das intera&ccedil;&otilde;es que ocorrem fora da plataforma <strong>PENSETROQUE</strong>, sendo assim, n&atilde;o possu&iacute;mos responsabilidade de nenhuma natureza por encontros offline entre usu&aacute;rios da rede.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>6.8&nbsp; &nbsp;Em virtude de a <strong>PENSETROQUE</strong> possibilitar o encontro entre usu&aacute;rios, e por n&atilde;o participar das transa&ccedil;&otilde;es que se realizam entre eles, a responsabilidade por todas as obriga&ccedil;&otilde;es, sejam elas fiscais, trabalhistas, consumeristas ou de qualquer outra natureza, decorrentes das transa&ccedil;&otilde;es originadas no espa&ccedil;o virtual do site ser&atilde;o exclusivamente dos Usu&aacute;rios.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>6.9&nbsp; &nbsp;Os Usu&aacute;rios n&atilde;o poder&atilde;o manipular as caracter&iacute;sticas dos ve&iacute;culos publicados, interferir nas transa&ccedil;&otilde;es entre outros Usu&aacute;rios, anunciar atividades proibidas pelas pol&iacute;ticas da<strong> PENSETROQUE</strong> e/ou pela lei, agredir, caluniar, injuriar e/ou difamar outros Usu&aacute;rios. Este tipo de comportamento poder&aacute; ser sancionado com a suspens&atilde;o ou cancelamento da oferta, ou com a suspens&atilde;o ou cancelamento do seu cadastro como Usu&aacute;rio do PENSETROQUE, sem preju&iacute;zo das a&ccedil;&otilde;es legais que possam ocorrer pela configura&ccedil;&atilde;o de delitos ou contraven&ccedil;&otilde;es ou os preju&iacute;zos civis que possam causar aos demais Usu&aacute;rios. A <strong>PENSETROQUE </strong>possui o direito de suspender, modificar ou finalizar sua conta ou acesso &agrave; Plataforma se, a nosso exclusivo crit&eacute;rio, determinarmos que voc&ecirc; violou estes Termos, inclusive quaisquer das pol&iacute;ticas ou diretrizes que integram o presente, segundo o melhor interesse da <strong>PENSETROQUE</strong> ou para proteger a nossa marca ou Plataforma.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>6.10 A <strong>PENSETROQUE</strong> n&atilde;o controla o conte&uacute;do de outros membros. Quando tomarmos conhecimento sobre a exist&ecirc;ncia de conte&uacute;dos inadequados em nossa Plataforma, investigaremos e tomaremos as medidas cab&iacute;veis, por&eacute;m, n&atilde;o temos a obriga&ccedil;&atilde;o de monitorar ou assumir responsabilidade pelo conte&uacute;do gerado por outros membros.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>6.11&nbsp; A <strong>PENSETROQUE</strong> n&atilde;o se constitui como parte em nenhum acordo online ou offline que tenha se originado em sua Plataforma. A <strong>PENSETROQUE</strong> n&atilde;o conduz ou exige a verifica&ccedil;&atilde;o de antecedentes criminais dos membros, nem procura verificar a verossimilhan&ccedil;a ou assertividade das declara&ccedil;&otilde;es apresentadas pelos Usu&aacute;rios. A <strong>PENSETROQUE</strong> n&atilde;o apresenta declara&ccedil;&otilde;es ou garantias relacionadas &agrave; conduta ou &agrave;s publica&ccedil;&otilde;es de qualquer Usu&aacute;rio ou das intera&ccedil;&otilde;es de outros Usu&aacute;rios com Voc&ecirc;.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>6.12&nbsp; &nbsp;A <strong>PENSETROQUE</strong> atua exclusivamente como Operadora de dados de Usu&aacute;rios para fins de intermedia&ccedil;&atilde;o de neg&oacute;cios. Referidos dados s&atilde;o obtidos de forma volunt&aacute;ria e espont&acirc;nea ou por acesso p&uacute;blico.</p>

                    <p>&nbsp;</p>

                    <p>6.13&nbsp; Nos casos em que um ou mais usu&aacute;rios ou algum terceiro inicie qualquer tipo de reclama&ccedil;&atilde;o ou a&ccedil;&atilde;o legal contra outro ou outros usu&aacute;rios, todos e cada um dos usu&aacute;rios envolvidos nas reclama&ccedil;&otilde;es ou a&ccedil;&otilde;es eximem de toda responsabilidade a <strong>PENSETROQUE</strong> e a seus diretores, gerentes, empregados, agentes, oper&aacute;rios, representantes e procuradores. A <strong>PENSETROQUE </strong>resguarda-se de toda e qualquer responsabilidade por fatos resultantes da intera&ccedil;&atilde;o entre os usu&aacute;rios, dentro ou fora do mundo virtual, devendo cada Usu&aacute;rio zelar e responsabilizar-se por sua seguran&ccedil;a e pela das pessoas que com ele interagem. Estes Termos de Uso n&atilde;o geram nenhum contrato de sociedade, de mandato, franquia ou rela&ccedil;&atilde;o de trabalho entre a <strong>PENSETROQUE</strong> e o Usu&aacute;rio. O Usu&aacute;rio manifesta ci&ecirc;ncia de que a <strong>PENSETROQUE</strong> n&atilde;o &eacute; parte de nenhuma transa&ccedil;&atilde;o, nem possui controle algum sobre a qualidade, seguran&ccedil;a ou legalidade das publica&ccedil;&otilde;es, sobre a sua veracidade ou exatid&atilde;o, e sobre a capacidade dos Usu&aacute;rios para negociar.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>6.14&nbsp; A <strong>PENSETROQUE</strong> n&atilde;o pode assegurar o &ecirc;xito de qualquer transa&ccedil;&atilde;o, tampouco verificar a identidade ou os dados pessoais dos Usu&aacute;rios.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>6.15 A <strong>PENSETROQUE</strong> n&atilde;o garante a veracidade da publica&ccedil;&atilde;o de terceiros que apare&ccedil;a em seu site e n&atilde;o ser&aacute; respons&aacute;vel pela correspond&ecirc;ncia ou contratos que o Usu&aacute;rio realize com terceiros.</p>

                    <p>&nbsp;</p>

                    <p>6.16&nbsp; O Usu&aacute;rio indenizar&aacute; a <strong>PENSETROQUE</strong>, suas filiais, empresas controladas ou controladoras, diretores, administradores, colaboradores, representantes e empregados por qualquer demanda promovida por outros Usu&aacute;rios ou terceiros decorrentes de suas atividades no site ou por seu descumprimento dos Termos e Condi&ccedil;&otilde;es Gerais de Uso e demais pol&iacute;ticas da <strong>PENSETROQUE</strong>, ou pela viola&ccedil;&atilde;o de qualquer lei ou direitos de terceiros, incluindo honor&aacute;rios de advogados. Voc&ecirc; concorda em nos notificar prontamente sobre quaisquer reivindica&ccedil;&otilde;es de terceiros, cooperar com todas as partes da <strong>PENSETROQUE </strong>na defesa das referidas reivindica&ccedil;&otilde;es e pagar todas as taxas, custas e despesas associadas &agrave; defesa das reivindica&ccedil;&otilde;es (inclusive, entre outras, honor&aacute;rios advocat&iacute;cios). Voc&ecirc; concorda em n&atilde;o resolver nenhuma reivindica&ccedil;&atilde;o sem o nosso consentimento pr&eacute;vio e por escrito.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>6.17&nbsp; Este Termo de Uso aplica-se ao uso da Plataforma <strong>PENSETROQUE</strong>. Qualquer pessoa, doravante denominada Usu&aacute;rio, que pretenda utilizar os servi&ccedil;os da <strong>PENSETROQUE</strong> dever&aacute; aceitar integralmente os Termos de Uso, e todas as demais pol&iacute;ticas e princ&iacute;pios que o regem.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>6.18&nbsp; A <strong>PENSETROQUE</strong> n&atilde;o se responsabiliza pela exist&ecirc;ncia, quantidade, qualidade, estado, integridade ou legitimidade dos produtos e/ou atividades oferecidos, adquiridos, alienados ou trocados pelos usu&aacute;rios, assim como pela capacidade para contratar dos Usu&aacute;rios ou pela veracidade dos Dados Pessoais por eles inseridos em seus cadastros.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p><strong>7)&nbsp; PRIVACIDADE</strong></p>

                    <p>A <strong>PENSETROQUE</strong> busca informa&ccedil;&otilde;es de registro e outras informa&ccedil;&otilde;es sobre voc&ecirc; pela Plataforma. Consulte a nossa Pol&iacute;tica de Privacidade para obter detalhes sobre como coletamos, utilizamos e divulgamos essas informa&ccedil;&otilde;es. Essas pol&iacute;ticas n&atilde;o regem o uso das informa&ccedil;&otilde;es que voc&ecirc; fornece a terceiros, como outros membros da Plataforma <strong>PENSETROQUE</strong>.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p><strong>8)&nbsp; UTILIZA&Ccedil;&Atilde;O DA PLATAFORMA</strong></p>

                    <p>8.1 Nossas pol&iacute;ticas, diretrizes e leis aplic&aacute;veis</p>

                    <p>Quando voc&ecirc; utiliza a nossa Plataforma, exigimos que voc&ecirc; siga os Termos de Uso. Por conseguinte, voc&ecirc; concorda e est&aacute; sujeito a cumprir todas as leis, normas e regulamentos aplic&aacute;veis, n&atilde;o violando ou infringindo os direitos de terceiros. Caso n&atilde;o ocorra o cumprimento, podemos suspender, modificar ou encerrar sua conta ou acesso &agrave; Plataforma.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>9)&nbsp; <strong>DIREITOS, DEVERES E CONDUTA DOS USU&Aacute;RIOS</strong></p>

                    <p>9.1&nbsp; O Usu&aacute;rio, por estes Termos e Condi&ccedil;&otilde;es Gerais de Uso, declara estar ciente que a Plataforma Digital n&atilde;o poder&aacute; ser utilizada de nenhuma forma que n&atilde;o esteja expressamente prevista e autorizada nestas Condi&ccedil;&otilde;es Gerais e concorda que &eacute; o &uacute;nico respons&aacute;vel, inclusive perante a terceiros, por qualquer perda, dano ou preju&iacute;zo que a Plataforma Digital ou terceiro possa vir a sofrer em decorr&ecirc;ncia do seu descumprimento das Condi&ccedil;&otilde;es Gerais e se compromete a utilizar a Plataforma Digital somente para os fins permitidos por lei e n&atilde;o recorrer e evitar que terceiros apliquem t&eacute;cnicas de pirataria inform&aacute;tica (&quot;hacking&quot;) e/ou qualquer t&eacute;cnica de tecnologia reversa.&nbsp;</p>

                    <p>9.2 O Usu&aacute;rio concorda expressamente que &eacute; integralmente respons&aacute;vel pela utiliza&ccedil;&atilde;o e/ou acesso &agrave; <strong>PENSETROQUE</strong>. Neste sentido, &eacute; responsabilidade exclusiva do Usu&aacute;rio controlar o preenchimento dos dados solicitados, inclusive da Senha Expressa, estando a<strong> PENSETROQUE</strong> isenta de qualquer atribui&ccedil;&atilde;o de verifica&ccedil;&atilde;o de correspond&ecirc;ncia das informa&ccedil;&otilde;es preenchidas pelo Usu&aacute;rio na Plataforma Digital.</p>

                    <p>9.3 O Usu&aacute;rio &eacute; respons&aacute;vel por manter as informa&ccedil;&otilde;es da sua Conta<strong> PENSETROQUE</strong> atualizadas, bem como pelas declara&ccedil;&otilde;es incorretas ou falsas que tenha prestado e que venham a causar preju&iacute;zos a si mesmos, terceiros ou &agrave; pr&oacute;pria <strong>PENSETROQUE</strong>.</p>

                    <p>&nbsp;</p>

                    <p>&nbsp;</p>

                    <p><strong>10) PROPRIEDADE INTELECTUAL</strong>&nbsp;</p>

                    <p>10.1 A <strong>PENSETROQUE</strong> &eacute; a &uacute;nica titular de todos os direitos sobre (i) a cria&ccedil;&atilde;o, desenvolvimento e funcionalidades da Plataforma Digital e quaisquer outros direitos de propriedade intelectual a ela relacionada; (ii) as marcas e/ou logotipos da Pense Troque e quaisquer outros direitos de propriedade industrial a ele relacionados; e (iii) todos os direitos autorais relacionados a todo e qualquer conte&uacute;do disponibilizado pela Pense Troque; nos termos das leis 9.609/98, 9.279/96 e 9.610/98 e demais legisla&ccedil;&otilde;es aplic&aacute;veis respectivamente.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>10.2&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;A <strong>PENSETROQUE</strong> respeita a propriedade intelectual de terceiros e espera que seus Usu&aacute;rios fa&ccedil;am o mesmo. Podemos, em circunst&acirc;ncias apropriadas e ao nosso crit&eacute;rio, remover ou desativar o acesso a material que viole os direitos de propriedade intelectual de terceiros. Podemos tamb&eacute;m restringir ou encerrar o acesso &agrave; nossa Plataforma &agrave;queles que entendermos tratar-se de infratores reincidentes. Toda informa&ccedil;&atilde;o ou dado pessoal prestado pelo Usu&aacute;rio da<strong> PENSETROQUE</strong> &eacute; armazenada em servidores ou meios magn&eacute;ticos de alta seguran&ccedil;a. A <strong>PENSETROQUE</strong> tomar&aacute; todas as medidas poss&iacute;veis para manter a confidencialidade e a seguran&ccedil;a descritas nesta cl&aacute;usula, por&eacute;m n&atilde;o responder&aacute; por preju&iacute;zo que possa ser derivado da viola&ccedil;&atilde;o dessas medidas por parte de terceiros que utilizem as redes p&uacute;blicas ou a internet, subvertendo os sistemas de seguran&ccedil;a para acessar as informa&ccedil;&otilde;es de Usu&aacute;rios.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>10.3&nbsp; &Eacute; vedado ao USU&Aacute;RIO: modificar, copiar, distribuir, transmitir, exibir, realizar, reproduzir, publicar, disponibilizar, licenciar ou criar obras derivadas a partir das informa&ccedil;&otilde;es coletadas no &quot;site&quot; PENSETROQUE, bem como transferir ou vender tais informa&ccedil;&otilde;es, software, produtos ou servi&ccedil;os, sob pena de viola&ccedil;&atilde;o do presente termo e infra&ccedil;&atilde;o legal.</p>

                    <p>&nbsp;</p>

                    <p>10.4&nbsp; &Eacute; vedado tamb&eacute;m aos USU&Aacute;RIOS:</p>

                    <ul>
                        <li>Fazer a distribui&ccedil;&atilde;o de c&oacute;pias do &quot;site&quot;<strong> PENSETROQUE</strong>;&nbsp;</li>
                        <li>Utilizar de qualquer forma trechos, t&eacute;cnica de engenharia reversa no desenvolvimento ou cria&ccedil;&atilde;o de outros trabalhos a fim de se analisar sua constitui&ccedil;&atilde;o;&nbsp;</li>
                        <li>Divulgar conte&uacute;do ou arquivos sem autoriza&ccedil;&atilde;o;&nbsp;</li>
                        <li>Liberar acesso a terceiros de forma il&iacute;cita.&nbsp;</li>
                    </ul>

                    <p>&nbsp;</p>

                    <p><strong>11) CANCELAMENTO DA CONTA PENSETROQUE</strong></p>

                    <p>11.1 O Usu&aacute;rio dever&aacute; efetuar o resgate dos valores de sua &ldquo;troqueta&rdquo; antes de efetuar o cancelamento da Conta <strong>PENSETROQUE</strong> por meio dos mecanismos dispon&iacute;veis na Plataforma Digital. Em hip&oacute;tese alguma far&aacute; a<strong> PENSETROQUE</strong> pagamento direto ao Usu&aacute;rio, em dinheiro, do valor equivalente aos cr&eacute;ditos porventura remanescentes em sua conta.</p>

                    <p>11.2 Caso o cancelamento da Conta <strong>PENSETROQUE</strong> seja feito pela <strong>PENSETROQUE</strong> por culpa do Usu&aacute;rio, a<strong> PENSETROQUE</strong> poder&aacute; bloquear o acesso do Usu&aacute;rio &agrave; sua conta e ressarcir-se de todos os custos, despesas, encargos operacionais e eventuais indeniza&ccedil;&otilde;es mediante a dedu&ccedil;&atilde;o dos cr&eacute;ditos de &ldquo;troquetas&rdquo; do Usu&aacute;rio que poder&atilde;o ser arredondas para mais ou para menos. Caso haja excedente, a <strong>PENSETROQUE</strong> proceder&aacute; com a libera&ccedil;&atilde;o dos valores mediante transfer&ecirc;ncia ou dep&oacute;sito banc&aacute;rio na conta indicada pelo Usu&aacute;rio. Caso haja insufici&ecirc;ncia de cr&eacute;ditos, o Usu&aacute;rio dever&aacute; efetuar imediatamente o pagamento da quantia faltante &agrave; <strong>PENSETROQUE</strong>.</p>

                    <p>11.3 O cancelamento da Conta PENSETROQUE, por qualquer motivo, n&atilde;o prejudicar&aacute; o direito da PENSETROQUE de reivindicar as quantias porventura devidas pelo Usu&aacute;rio, decorrentes da utiliza&ccedil;&atilde;o das Funcionalidades, nem o de reivindicar indeniza&ccedil;&atilde;o porventura devida pelo Usu&aacute;rio.</p>

                    <p>&nbsp;</p>

                    <p><strong>12) DISPOSI&Ccedil;&Otilde;ES GERAIS</strong></p>

                    <p>12.1 A<strong> PENSETROQUE</strong> disponibilizar&aacute; suporte geral, t&eacute;cnico e operacional &agrave; Plataforma Digital, que permita ao Usu&aacute;rio solucionar todas as demandas referentes &agrave; informa&ccedil;&atilde;o, d&uacute;vida, reclama&ccedil;&atilde;o, suspens&atilde;o e/ou cancelamento da Conta PENSE TROQUE. O suporte ser&aacute; feito por meio de atendimento por e-mail, Facebook Messenger ou chat na Plataforma Digital, todos indicados na aba &ldquo;Fale Conosco&rdquo; da nossa Plataforma Digital.</p>

                    <p>12.2 O Usu&aacute;rio dever&aacute; direcionar todos os questionamentos, suporte, aux&iacute;lio ou outras necessidades de comunica&ccedil;&atilde;o para o servi&ccedil;o de atendimento da <strong>PENSETROQUE</strong>.</p>

                    <p>12.3 O Usu&aacute;rio expressamente aceita que a <strong>PENSETROQUE</strong> e/ou qualquer de seus parceiros enviem mensagens de e-mail de car&aacute;ter informativo, referentes &agrave;s comunica&ccedil;&otilde;es espec&iacute;ficas que dizem respeito &agrave; Plataforma Digital, bem como outras mensagens de e-mail, tais como novidades da <strong>PENSETROQUE</strong>. Caso o Usu&aacute;rio n&atilde;o deseje mais receber tais mensagens, poder&aacute; solicitar o encerramento por meio de qualquer um de nossos canais de atendimento.</p>

                    <p>12.4&nbsp; A toler&acirc;ncia de uma parte relativamente ao descumprimento de qualquer das obriga&ccedil;&otilde;es da outra n&atilde;o ser&aacute; considerada nova&ccedil;&atilde;o ou ren&uacute;ncia a qualquer direito previsto nestes Termos e Condi&ccedil;&otilde;es de Uso, constituindo mera liberalidade, que n&atilde;o impedir&aacute; a parte tolerante de exigir da outra seu cumprimento, a qualquer tempo.</p>

                    <p>12.5&nbsp; &nbsp;A ades&atilde;o ao presente Termo n&atilde;o pode ser cedida ou transferida por voc&ecirc;, exceto com o consentimento pr&eacute;vio por escrito da <strong>PENSETROQUE</strong>, mas pode ser cedido ou transferido por n&oacute;s para qualquer afiliada ou subsidi&aacute;ria ou em conex&atilde;o com uma fus&atilde;o, aquisi&ccedil;&atilde;o, reorganiza&ccedil;&atilde;o societ&aacute;ria ou venda de todos ou substancialmente todos os ativos da <strong>PENSETROQUE</strong> ou transa&ccedil;&atilde;o semelhante.&nbsp;</p>

                    <p>12.6&nbsp; &nbsp;Se uma das partes deixar de exercer ou aplicar qualquer direito ou disposi&ccedil;&atilde;o deste Termo, isso n&atilde;o significar&aacute; a ren&uacute;ncia do referido direito ou cl&aacute;usula, tampouco ren&uacute;ncia ao direito de agir com respeito a viola&ccedil;&otilde;es subsequentes ou similares.&nbsp;</p>

                    <p>12.7&nbsp; Estes Termos e Condi&ccedil;&otilde;es Gerais de Uso obrigam as partes e seus sucessores a qualquer t&iacute;tulo.</p>

                    <p>12.8&nbsp; &nbsp;Os presentes Termos e Condi&ccedil;&otilde;es Gerais de Uso ser&atilde;o interpretados e regidos de acordo com as leis da Rep&uacute;blica Federativa do Brasil.</p>

                    <p>&nbsp;</p>

                    <p>12.9&nbsp; Este contrato foi escrito em portugu&ecirc;s. Posteriormente ser&aacute; traduzido para outros idiomas (espanhol e ingl&ecirc;s). Se houver qualquer incompatibilidade entre a vers&atilde;o em portugu&ecirc;s e uma vers&atilde;o traduzida, a vers&atilde;o em portugu&ecirc;s prevalecer&aacute;.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>12.10&nbsp; Qualquer reivindica&ccedil;&atilde;o n&atilde;o sujeita &agrave; arbitragem deve ser impetrada no prazo de um ano, a contar da data em que a parte que invocou a reivindica&ccedil;&atilde;o conheceu ou deveria conhecer o ato, omiss&atilde;o ou inadimplemento que deu origem &agrave; reivindica&ccedil;&atilde;o, ou segundo o menor prazo permitido pela lei aplic&aacute;vel.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>12.11&nbsp; Toda e qualquer controv&eacute;rsia oriunda do presente Termos de Uso e Condi&ccedil;&otilde;es Gerais&nbsp; ser&atilde;o solucionadas de acordo com a lei brasileira, sendo competente a c&acirc;mara arbitral da cidade de S&atilde;o Paulo/SP a ser indicada pela <strong>PENSETROQUE</strong>, para resolu&ccedil;&atilde;o do conflito em quest&atilde;o, com exclus&atilde;o de qualquer outro por mais privilegiado que seja. As reivindica&ccedil;&otilde;es dever&atilde;o ser apresentadas individualmente.&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>12.12&nbsp; &nbsp;Caso Voc&ecirc; tenha alguma d&uacute;vida sobre este Termo de Uso entre em contato conosco pelo site https://www.pensetroque.com.br/, pela aba &ldquo;fale conosco&rdquo;.</p>

                </div>
                

                </div>
        );
    }
}

export default TermsOfService;